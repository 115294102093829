import * as React from 'react';

function SvgDownload(props) {
  return (
    <svg
      viewBox="0 0 20 24"
      width="1em"
      height="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.774 15.164a.774.774 0 00-.774.774v2.28C2 19.202 2.809 20 3.806 20h12.388c.997 0 1.806-.798 1.806-1.782v-2.28a.774.774 0 10-1.548 0v2.28a.257.257 0 01-.258.255H3.806a.257.257 0 01-.258-.255v-2.28a.773.773 0 00-.774-.774zm10.837-5.233l-2.837 2.518V4H9.226v8.449L6.389 9.931l-1.036 1.135 4.129 3.665a.779.779 0 001.036 0l4.129-3.665-1.036-1.135z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgDownload;
