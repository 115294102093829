import * as React from 'react';

function SvgSettings(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.522 19.122l.36-.745c.261-.54.856-.865 1.483-.809l.833.073c.437.039.875-.103 1.16-.418a7.897 7.897 0 001.576-2.602c.139-.383.052-.803-.195-1.135l-.512-.689a1.325 1.325 0 010-1.595l.512-.688c.247-.333.334-.752.195-1.136a7.915 7.915 0 00-1.465-2.477c-.347-.4-.897-.576-1.444-.527l-.66.058c-.627.055-1.222-.27-1.483-.81l-.36-.745a1.326 1.326 0 00-.955-.738 8.867 8.867 0 00-3.133 0 1.326 1.326 0 00-.955.738l-.36.745c-.261.54-.856.865-1.484.81l-.833-.074c-.437-.038-.876.104-1.161.418a7.917 7.917 0 00-1.575 2.601c-.139.384-.052.803.195 1.135l.513.69a1.325 1.325 0 010 1.595l-.513.69c-.247.332-.334.752-.195 1.135.351.964.89 1.845 1.575 2.601.285.315.724.457 1.161.418l.833-.073c.628-.056 1.223.269 1.484.809l.36.745c.18.375.526.662.955.739a8.864 8.864 0 003.133-.001c.429-.077.775-.364.955-.738zM4.868 8.111a6.205 6.205 0 00-.959 1.585l.378.509a2.98 2.98 0 010 3.589l-.378.509a6.21 6.21 0 00.959 1.586l.595-.053c1.411-.125 2.751.607 3.338 1.822l.26.539a6.898 6.898 0 001.879 0l.26-.539c.587-1.215 1.927-1.947 3.338-1.822l.594.052a6.194 6.194 0 00.959-1.586l-.378-.508a2.984 2.984 0 010-3.589l.378-.508a6.21 6.21 0 00-.959-1.586l-.594.053c-1.411.124-2.751-.607-3.338-1.822l-.26-.539a6.912 6.912 0 00-1.879 0l-.26.539c-.587 1.215-1.927 1.946-3.338 1.822l-.595-.053z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 10a2 2 0 10-.001 3.999A2 2 0 0010 10zm-4 2a4 4 0 118 0 4 4 0 01-8 0z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgSettings;
