import * as React from 'react';

function SvgMedal(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.992 8.086l-2.16-3.6A.997.997 0 005.975 4H2.501a.5.5 0 00-.41.787l3.477 4.968a6.471 6.471 0 013.424-1.669zM17.499 4h-3.473a1 1 0 00-.858.486l-2.16 3.6a6.461 6.461 0 013.423 1.669l3.478-4.968a.5.5 0 00-.41-.787zM10 9a5.5 5.5 0 100 11 5.5 5.5 0 000-11zm2.891 4.915l-1.185 1.155.28 1.631a.36.36 0 01-.52.379L10 16.309l-1.466.771a.36.36 0 01-.52-.379l.28-1.631-1.185-1.155a.359.359 0 01.198-.612l1.639-.239.732-1.485a.351.351 0 01.322-.2c.128 0 .256.066.322.2l.732 1.485 1.639.239a.359.359 0 01.198.612z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgMedal;
