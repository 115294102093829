import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import cn from 'classnames';
import './breadcrumb.scss';

function Breadcrumb({ titles = [], children, ...props }) {
  return (
    <div className="breadcrumb-area">
      <div className="breadcrumb-title">
        {titles.map((title, index) => (
          <Breadcrumb.Title
            title={title}
            isLastElement={titles.length - 1 === index}
            key={index}
          />
        ))}
      </div>
      {children}
    </div>
  );
}

Breadcrumb.Title = React.memo(function ({ title, isLastElement }) {
  const className = useMemo(
    () =>
      cn(
        'section',
        {
          active: isLastElement,
        },
        title.onClick && 'pointer',
      ),
    [isLastElement, title.onClick],
  );

  if (title.onClick) {
    return (
      <>
        <div className={className} onClick={title.onClick}>
          {title.text}
        </div>

        {!isLastElement && (
          <Icon name="chevron right" className="divider" />
        )}
      </>
    );
  }

  if (title.href) {
    return (
      <>
        <Link className={className} to={title.href}>
          {title.text}
        </Link>

        {!isLastElement && (
          <Icon name="chevron right" className="divider" />
        )}
      </>
    );
  }

  return (
    <>
      <div className={className}>
        {title.text}
        {title.span && <span>({title.span})</span>}
      </div>
      {!isLastElement && (
        <Icon name="chevron right" className="divider" />
      )}
    </>
  );
});

Breadcrumb.Button = React.memo(function ({ children }) {
  return <div className="breadcrumb-button">{children}</div>;
});

export default Breadcrumb;
