import * as React from 'react';

function SvgLightning(props) {
  return (
    <svg
      viewBox="0 0 20 24"
      width="1em"
      height="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.337 12.933l-.918 5.507 5.325-7.372H9.663l.919-5.509-5.325 7.374h5.08zm2.738-10.991C13.236.97 11.976.444 11.4 1.243L2.678 13.319a.932.932 0 00.756 1.477h4.702l-1.21 7.262c-.162.972 1.098 1.497 1.674.7l8.722-12.076a.933.933 0 00-.756-1.479h-4.701l1.21-7.261z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgLightning;
