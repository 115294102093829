import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { _fetchJson } from '../../../libs/fetch';
import { Button } from '../../Button';
import ContentModal from './ContentModal';
import MainModal from './MainModal';
import { Trans } from 'react-i18next';
import styles from './onboardingArea.module.scss';

function OnboardingArea({ isOpen, setOpen }) {
  const { user } = useSelector((state) => state.auth);
  const [activeModal, setActiveModal] = useState(-1);
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation('study');

  const titles = useMemo(
    () => [
      {
        key: 'priviahub101',
        name: 'PriviaHub 101',
        desc: <Trans i18nKey="study:study_privia_desc"></Trans>,
        image: '/img/onboarding/ph101.svg',
        tabs: [{ key: 'index', name: 'Index' }],
      },
      {
        key: 'vpn101',
        name: <Trans i18nKey="study:study_vpn_desc"></Trans>,
        desc: <Trans i18nKey="study:study_vpn_desc"></Trans>,
        image: '/img/onboarding/vpn101.svg',
        tabs: [
          {
            key: 'vpn-info',
            name: <Trans i18nKey="study:vpn_information"></Trans>,
          },
          {
            key: 'vpn-download',
            name: <Trans i18nKey="study:vpn_download"></Trans>,
          },
        ],
      },
      {
        key: 'machine101',
        name: <Trans i18nKey="study:machines_101"></Trans>,
        desc: <Trans i18nKey="study:study_machine_desc"></Trans>,
        image: '/img/onboarding/machines101.svg',
        tabs: [
          {
            key: 'machine-info',
            name: <Trans i18nKey="study:vpn_information"></Trans>,
          },
          {
            key: 'machine-use',
            name: <Trans i18nKey="study:machine_use"></Trans>,
          },
          {
            key: 'machine-action',
            name: <Trans i18nKey="study:machine_actions"></Trans>,
          },
          {
            key: 'machine-tasks',
            name: <Trans i18nKey="study:machine_steps"></Trans>,
          },
        ],
      },
      {
        key: 'scenarious101',
        name: <Trans i18nKey="study:scenarious_101"></Trans>,
        desc: <Trans i18nKey="study:study_scenarious_desc"></Trans>,
        image: '/img/onboarding/scenarios101.svg',
        tabs: [{ key: 'index' }],
      },
      {
        key: 'other101',
        name: <Trans i18nKey="study:study_other_title"></Trans>,
        desc: <Trans i18nKey="study:study_scenarious_desc"></Trans>,
        image: '/img/onboarding/other101.svg',
        tabs: [
          {
            key: 'scoreboard',
            name: <Trans i18nKey="study:scoretable"></Trans>,
          },
          {
            key: 'other-information',
            name: <Trans i18nKey="study:study_other_title"></Trans>,
          },
        ],
      },
    ],
    [],
  );

  const [data, setData] = useState({
    priviahub101: {
      tab_count: 1,
      success_count: 0,
      complation_rate: 0,
      tab_success: [
        {
          tab: 'index',
          success: false,
        },
      ],
    },
    vpn101: {
      tab_count: 2,
      success_count: 0,
      complation_rate: 0,
      tab_success: [
        {
          tab: 'vpn-info',
          success: false,
        },

        {
          tab: 'vpn-download',
          success: false,
        },
      ],
    },
    machine101: {
      tab_count: 4,
      success_count: 0,
      complation_rate: 0,
      tab_success: [
        {
          tab: 'machine-info',
          success: false,
        },

        {
          tab: 'machine-use',
          success: false,
        },

        {
          tab: 'machine-action',
          success: false,
        },

        {
          tab: 'machine-tasks',
          success: false,
        },
      ],
    },
    scenarious101: {
      tab_count: 1,
      success_count: 0,
      complation_rate: 0,
      tab_success: [
        {
          tab: 'index',
          success: false,
        },
      ],
    },
    other101: {
      tab_count: 2,
      success_count: 0,
      complation_rate: 0,
      tab_success: [
        {
          tab: 'scoreboard',
          success: false,
        },

        {
          tab: 'other-information',
          success: false,
        },
      ],
    },
  });

  useEffect(() => {
    _fetchJson(`/onboarding`)
      .then((res) => {
        if (res.success) {
          delete res.success;
          setData(res);
        }
      })
      .catch((err) => null);
  }, []);

  const handleComplete = (onboarding, tab, finish) => {
    const temp = { ...data };
    const tempTab = temp[onboarding].tab_success.find(
      (e) => e.tab === tab,
    )?.success;
    const tabIndex = temp[onboarding].tab_success.findIndex(
      (e) => e.tab === tab,
    );
    if (tempTab && finish) {
      setActiveModal(-1);
      setOpen(true);
    } else if (tempTab) {
      setActiveTab(activeTab + 1);
      return;
    } else {
      _fetchJson(`/onboarding/view_tab`, {
        method: 'post',
        body: JSON.stringify({ title: onboarding, tab_title: tab }),
      })
        .then(() => {
          temp[onboarding]['tab_success'][tabIndex]['success'] = true;
          temp[onboarding]['success_count'] =
            Number(temp[onboarding]['success_count']) + 1;
          temp[onboarding]['complation_rate'] =
            (temp[onboarding]['success_count'] /
              temp[onboarding]['tab_count']) *
            100;
          setData({ ...data, temp });
          if (finish) {
            setActiveModal(-1);
            setActiveTab(0);
            setOpen(true);
          } else {
            setActiveTab(activeTab + 1);
          }
        })
        .catch((err) => null);
    }
  };

  return (
    <>
      <MainModal
        isOpen={isOpen}
        setOpen={setOpen}
        setActiveModal={setActiveModal}
        data={data}
        titles={titles}
      />

      <ContentModal
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        data={data[titles[0].key]}
        isOpen={activeModal === 0}
        setOpen={setActiveModal}
        index={0}
        breadcrumb={[
          {
            text: t('education'),
            onClick: () => {
              setActiveTab(0);
              setActiveModal(-1);
              setOpen(true);
            },
          },
          { text: titles[0].name },
        ]}
        tabs={titles[0].tabs}
      >
        <>
          <div className={styles['img-area']}>
            {user.theme === 'light' || user.theme === 'default' ? (
              <img
                className="w50"
                src="/img/logo2.png"
                alt="logo"
              ></img>
            ) : (
              <img
                className="w50"
                src="/img/logo.png"
                alt="logo"
              ></img>
            )}
          </div>
          <p>{t('privia1')}</p>
          <p>{t('privia2')}</p>
          <p>{t('privia4')}</p>
          <div className="button-area t-center">
            <Button
              onClick={() =>
                handleComplete(
                  titles[0].key,
                  titles[0].tabs[0].key,
                  true,
                )
              }
              color="primary-bordering"
            >
              {t('education_end')}
            </Button>
          </div>
        </>
      </ContentModal>

      <ContentModal
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        data={data[titles[1].key]}
        isOpen={activeModal === 1}
        setOpen={setActiveModal}
        index={1}
        breadcrumb={[
          {
            text: t('education'),
            onClick: () => {
              setActiveTab(0);
              setActiveModal(-1);
              setOpen(true);
            },
          },
          { text: titles[1].name },
        ]}
        tabs={titles[1].tabs}
      >
        <>
          <p>{t('privia5')}</p>
          <div className={styles['features-area']}>
            <div className={styles['item']}>
              <div className={styles['item-name']}>
                {t('study_machine_title')}
              </div>
              <div className={styles['item-desc']}>{t('vpn3')}</div>
            </div>
            <div className={styles['item']}>
              <div className={styles['item-name']}>{t('vpn4')}:</div>
              <div className={styles['item-desc']}>{t('vpn5')}</div>
            </div>
            <div className={styles['item']}>
              <div className={styles['item-name']}>{t('vpn6')}</div>
              <div className={styles['item-desc']}>{t('vpn7')}</div>
            </div>
            <div className={styles['item']}>
              <div className={styles['item-name']}>{t('vpn8')}</div>
              <div className={styles['item-desc']}>{t('vpn9')}</div>
            </div>
            <div className={styles['item']}>
              <div className={styles['item-name']}>{t('vpn10')}</div>
              <div className={styles['item-desc']}>{t('vpn11')}</div>
            </div>
          </div>
          <div className="button-area t-center tab-item">
            <Button
              onClick={() =>
                handleComplete(titles[1].key, titles[1].tabs[0].key)
              }
              color="primary-bordering"
            >
              {t('next_step')}
            </Button>
          </div>
        </>
        <>
          <p>{t('vpn12')}</p>
          <div className={styles['img-area']}>
            <img
              alt="VPN Download"
              src="/img/onboarding/vpn101/vpn-download.png"
            />
          </div>
          <p>{t('vpn13')}</p>
          <h3 className={styles.title}>{t('vpn14')}</h3>
          <p>{t('vpn15')}</p>
          <p>{t('vpn16')}</p>
          <p>{t('vpn17')}</p>
          <div className={styles['img-area']}>
            <img
              alt="Connect VPN"
              src="/img/onboarding/vpn101/vpn-connect.png"
            />
          </div>
          <div className="button-area t-center">
            <Button
              onClick={() =>
                handleComplete(
                  titles[1].key,
                  titles[1].tabs[1].key,
                  true,
                )
              }
              color="primary-bordering"
            >
              {t('education_end')}
            </Button>
          </div>
        </>
      </ContentModal>

      <ContentModal
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        data={data[titles[2].key]}
        isOpen={activeModal === 2}
        setOpen={setActiveModal}
        index={2}
        breadcrumb={[
          {
            text: t('education'),
            onClick: () => {
              setActiveTab(0);
              setActiveModal(-1);
              setOpen(true);
            },
          },
          { text: titles[2].name },
        ]}
        tabs={titles[2].tabs}
      >
        <>
          <p>{t('machine1')}. </p>
          <div className={styles['img-area']}>
            <img
              alt="Machine General"
              src="/img/onboarding/machines101/machine-general.png"
            />
          </div>
          <p>{t('machine2')}</p>
          <p>{t('machine3')}</p>
          <div className={styles['img-area']}>
            <img
              alt="Machine Info"
              src="/img/onboarding/machines101/machine-info.png"
            />
          </div>
          <p>{t('machine4')}</p>
          <div className={styles.list}>
            <ul>
              <li>
                <b>a. {t('machine5')}</b>
                <ol>
                  <li>Offensive</li>
                  <li>SOC</li>
                  <li>Forensic</li>
                  <li>Source Code</li>
                </ol>
              </li>
              <li>
                <b>b. {t('machine6')}</b>
                <ol>
                  <li>Web</li>
                  <li>System</li>
                  <li>Network</li>
                  <li>Malware</li>
                  <li>{t('machine7')}</li>
                </ol>
              </li>
              <li>
                <b>c. {t('machine8')}</b>
                <ol>
                  <li>
                    Windows
                    <ul>
                      <li>i. Windows Server 2003</li>
                      <li>ii. Windows Server 2008</li>
                      <li>iii. Windows Server 2012 &amp; R2</li>
                      <li>iv. Windows Server 2016</li>
                      <li>v. Windows Server 2019</li>
                      <li>vi. Windows XP</li>
                      <li>vii. Windows 7</li>
                      <li>viii. Windows 8</li>
                      <li>ix. Windows 10</li>
                    </ul>
                  </li>
                  <li>
                    Linux
                    <ul>
                      <li>
                        i. Ubuntu
                        <ol>
                          <li>Ubuntu 12.04</li>
                          <li>Ubuntu 14.04</li>
                          <li>Ubuntu 16.04</li>
                          <li>Ubuntu 18.04</li>
                          <li>Ubuntu 20.04</li>
                        </ol>
                      </li>
                      <li>
                        ii. Debian
                        <ol>
                          <li>Debian 6 (squeeze)</li>
                          <li>Debian 7 (wheezy)</li>
                          <li>Debian 8 (jessie)</li>
                          <li>Debian 9 (stretch)</li>
                          <li>Debian 10 (buster)</li>
                        </ol>
                      </li>
                      <li>
                        iii. Centos
                        <ol>
                          <li>CentOS 6</li>
                          <li>CentOS 7</li>
                          <li>CentOS 8</li>
                        </ol>
                      </li>
                    </ul>
                  </li>
                  <li>FreeBSD</li>
                  <li>Kali Linux</li>
                  <li>Black Box</li>
                </ol>
              </li>
              <li>
                <b>d. {t('machine9')}</b>
                <ol>
                  <li>
                    <span className={styles['easy']}>
                      {t('easy')}
                    </span>
                    <ul>
                      <li>{t('easy_1')}</li>
                      <li>{t('easy_2')}</li>
                      <li>{t('easy_3')}</li>
                    </ul>
                  </li>
                  <li>
                    <span className={styles['medium']}>
                      {t('medium')}
                    </span>
                    <ul>
                      <li>{t('medium_1')}</li>
                      <li>{t('medium_2')}</li>
                      <li>{t('medium_3')}</li>
                    </ul>
                  </li>
                  <li>
                    <span className={styles['hard']}>
                      {t('hard')}
                    </span>
                    <ul>
                      <li>{t('hard_1')}</li>
                      <li>{t('hard_2')}</li>
                      <li>{t('hard_3')}</li>
                    </ul>
                  </li>
                  <li>
                    <span className={styles['brainstorm']}>
                      {t('expert')}
                    </span>
                    <ul>
                      <li>{t('expert_1')}</li>
                      <li>{t('expert_2')}</li>
                      <li>{t('expert_1')}</li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li>{t('machine14')}</li>
            </ul>
          </div>
          <div className="button-area t-center tab-item">
            <Button
              onClick={() =>
                handleComplete(titles[2].key, titles[2].tabs[0].key)
              }
              color="primary-bordering"
            >
              {t('next_step')}
            </Button>
          </div>
        </>
        <>
          <p>{t('machine15')}</p>
          <div className={styles['img-area']}>
            <img
              alt="Machine Action"
              className="w70"
              src="/img/onboarding/machines101/machine-action.png"
            />
          </div>
          <p dangerouslySetInnerHTML={{ __html: t('machine16') }}></p>
          <div className="button-area t-center tab-item">
            <Button
              onClick={() =>
                handleComplete(titles[2].key, titles[2].tabs[1].key)
              }
              color="primary-bordering"
            >
              {t('next_step')}
            </Button>
          </div>
        </>
        <>
          <p dangerouslySetInnerHTML={{ __html: t('machine17') }}></p>
          <div className="button-area t-center tab-item">
            <Button
              onClick={() =>
                handleComplete(titles[2].key, titles[2].tabs[2].key)
              }
              color="primary-bordering"
            >
              {t('next_step')}
            </Button>
          </div>
        </>
        <>
          <p dangerouslySetInnerHTML={{ __html: t('machine18') }}></p>
          <div className={styles['img-area']}>
            <img
              alt="Machine Tasks"
              src="/img/onboarding/machines101/machine-task.png"
            />
          </div>
          <p>{t('machine19')}</p>
          <div className="button-area t-center">
            <Button
              onClick={() =>
                handleComplete(
                  titles[2].key,
                  titles[2].tabs[3].key,
                  true,
                )
              }
              color="primary-bordering"
            >
              {t('education_end')}
            </Button>
          </div>
        </>
      </ContentModal>

      <ContentModal
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        data={data[titles[3].key]}
        isOpen={activeModal === 3}
        setOpen={setActiveModal}
        index={3}
        breadcrumb={[
          {
            text: t('education'),
            onClick: () => {
              setActiveTab(0);
              setActiveModal(-1);
              setOpen(true);
            },
          },
          { text: titles[3].name },
        ]}
        tabs={titles[3].tabs}
      >
        <>
          <p
            dangerouslySetInnerHTML={{ __html: t('scenarious') }}
          ></p>
          <div className={styles['img-area']}>
            <img
              alt="Scenarios"
              src="/img/onboarding/scenarios101/scenarios.png"
            />
          </div>
          <div className="button-area t-center">
            <Button
              onClick={() =>
                handleComplete(
                  titles[3].key,
                  titles[3].tabs[0].key,
                  true,
                )
              }
              color="primary-bordering"
            >
              {t('education_end')}
            </Button>
          </div>
        </>
      </ContentModal>

      <ContentModal
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        data={data[titles[4].key]}
        isOpen={activeModal === 4}
        setOpen={setActiveModal}
        index={4}
        breadcrumb={[
          {
            text: t('education'),
            onClick: () => {
              setActiveTab(0);
              setActiveModal(-1);
              setOpen(true);
            },
          },
          { text: titles[4].name },
        ]}
        tabs={titles[4].tabs}
      >
        <>
          <p>{t('other1')}</p>
          <div className={styles['img-area']}>
            <img
              alt="Scoreboard"
              src="/img/onboarding/other101/scoreboard.png"
            />
          </div>
          <p>{t('other2')}</p>
          <div
            className="button-area t-center"
            data-tab="other-information"
          >
            <Button
              onClick={() =>
                handleComplete(titles[4].key, titles[4].tabs[0].key)
              }
              color="primary-bordering"
            >
              {t('next_step')}
            </Button>
          </div>
        </>
        <>
          <p dangerouslySetInnerHTML={{ __html: t('other3') }}></p>
          <div className={styles['img-area']}>
            <img
              alt="Contact Modal"
              src="/img/onboarding/other101/contact.png"
            />
          </div>
          <div className="button-area t-center">
            <Button
              onClick={() =>
                handleComplete(
                  titles[4].key,
                  titles[4].tabs[1].key,
                  true,
                )
              }
              color="primary-bordering"
            >
              {t('education_end')}
            </Button>
          </div>
        </>
      </ContentModal>
    </>
  );
}

export default OnboardingArea;
