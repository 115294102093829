import React from 'react';
import { Modal, Button, Header, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Spinner from '../Spinner';

function DeleteModal({
  title,
  desc,
  icon = 'trash alternate',
  loading = false,
  text,
  cancelText,
  iconColor = null,
  onSuccess = () => null,
  ...props
}) {
  const { t } = useTranslation('global');
  return (
    <Modal basic size="small" {...props}>
      {loading ? (
        <Spinner type="button" />
      ) : (
        <>
          <Header icon>
            <Icon name={icon} color={iconColor} />
            {title}
          </Header>
          <Modal.Content>
            <p>{desc}</p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              basic
              color="red"
              inverted
              onClick={() => props.onClose()}
            >
              <Icon name="remove" /> {cancelText || t('modal.cancel')}
            </Button>
            <Button color="green" inverted onClick={onSuccess}>
              <Icon name="checkmark" /> {text || t('global.delete')}
            </Button>
          </Modal.Actions>
        </>
      )}
    </Modal>
  );
}

export default React.memo(DeleteModal);
