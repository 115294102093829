import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Accordion, Icon } from 'semantic-ui-react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import Modal from '../Modal';
import { Button } from '../Button';
import AddMachineModal from '../Modal/AddMachineModal';
import { _fetchJson } from '../../libs/fetch';
import * as SvgIcon from '../icons';
import { toast } from 'react-toastify';
import IsAdmin from '../Administrator/IsAdmin';
import cn from 'classnames';
import './machineNav.scss';

function Nav({ machine, challenge }) {
  const { user } = useSelector((state) => state.auth);
  const panelSettings = useSelector((state) => state.panelSettings);
  const { video_id, documents, scenario, thrust_point } = useSelector(
    (state) => state.machineResource,
  );
  const history = useHistory();
  const { name } = useParams();
  const { t } = useTranslation([
    'sidebar',
    'global',
    'menu',
    'general',
  ]);
  const [userList, setUserList] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [machineList, setMachineList] = useState([]);
  const [ctfList, setCtfList] = useState([]);
  const [quizList, setQuizList] = useState([]);
  const [categoryBasedList, setCategoryBasedList] = useState([]);
  const [educationList, setEducationList] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedMachine, setSelectedMachine] = useState('');
  const [selectedCtf, setSelectedCtf] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedCategoryType, setSelectedCategoryType] = useState(
    '',
  );
  const [selectedEducation, setSelectedEducation] = useState('');
  const [selectedQuiz, setSelectedQuiz] = useState('');
  const [selectedTeam, setSelectedTeam] = useState('');
  const [modal, setModal] = useState('');
  const [newMachine, setNewMachine] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  // const [accordionActive, setAccordionActive] = useState('');

  const accordionMap = useMemo(
    () => ({
      admin: [
        '/users',
        '/ranks',
        '/feedbacks',
        '/commands',
        '/file-management',
        '/mitre',
        '/wikis',
        '/contributors',
      ],
      general: ['/meta-tags', '/panel-settings', '/notifications'],
      infra: [
        '/proxmox-settings',
        '/firewall-settings',
        '/smtp-settings',
        '/telegram-settings',
        '/proxmox-system-machines',
      ],
      machines: [
        '/machines',
        '/operating-systems',
        '/sub-operating-systems',
        '/vlanids',
        '/categories',
        '/sub-categories',
        '/linked-machines',
        '/appointments',
      ],
      education: ['/videos', '/playlists'],
      quiz: ['/quizzes', '/quiz-questions', '/quiz-categories'],
      reports: ['/reports'],
    }),
    [],
  );

  const accordions = [
    'admin',
    'infra',
    'general',
    'machines',
    'education',
    'quiz',
    'reports',
  ];

  const openModalHandler = (modal) => {
    if (modal === 'user') {
      _fetchJson('/report_users')
        .then((res) => res.status && setUserList(res.users))
        .catch((err) => toast.error(err.statusText));
    }
    if (modal === 'machine') {
      _fetchJson('/reports/machines/list')
        .then((res) => res.success && setMachineList(res.machines))
        .catch((err) => toast.error(err.statusText));
    }
    if (modal === 'ctf') {
      _fetchJson('/reports/challenges/list')
        .then((res) => res.success && setCtfList(res.challenges))
        .catch((err) => toast.error(err.statusText));
    }
    if (modal === 'quiz') {
      _fetchJson('/quizzes')
        .then((res) => res.success && setQuizList(res.quizzes))
        .catch((err) => toast.error(err.statusText));
    }
    if (modal === 'machines') {
      _fetchJson('/reports/categories/machine/list')
        .then((res) => {
          setCategoryBasedList(res.categories);
        })
        .catch((err) => toast.error(err.statusText));
    }
    if (modal === 'challenges') {
      _fetchJson('/reports/categories/ctf/list')
        .then((res) => {
          setCategoryBasedList(res.ctf_categories);
        })
        .catch((err) => toast.error(err.statusText));
    }
    if (modal === 'team') {
      _fetchJson('/teams')
        .then((res) => setTeamList(res.teams))
        .catch((err) => toast.error(err.statusText));
    }
    if (modal === 'education') {
      _fetchJson('/reports/traning_videos/list')
        .then(
          (res) =>
            res.success && setEducationList(res.video_categories),
        )
        .catch((err) => toast.error(err.statusText));
    }

    setModal(modal);
  };

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  };

  const findAccordion = () => {
    let found = false;
    let index = -1;
    for (let key of Object.keys(accordionMap)) {
      for (let val of accordionMap[key]) {
        if (window.location.pathname.startsWith(val)) {
          found = true;
          index = accordions.indexOf(key);
          break;
        }
      }
      if (found) break;
    }

    setActiveIndex(index);
  };

  useEffect(() => {
    findAccordion();
    const unlisten = history.listen(() => {
      findAccordion();
    });

    return () => unlisten();
  }, []);

  return (
    <>
      {machine ? (
        <nav className="type-2">
          <NavLink
            exact
            activeClassName="active-machine"
            to={`/machines/${name}`}
            className="nav-link machine-info"
          >
            <div className="icon-area">
              <SvgIcon.Machine></SvgIcon.Machine>
            </div>
            <span>
              {t('menu:navbar_machine.machine_information')}
            </span>
          </NavLink>
          {!thrust_point && (
            <NavLink
              activeClassName="active-machine"
              to={`/machines/${name}/task`}
              className="nav-link machine-task"
            >
              <div className="icon-area">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.5601 11.05L8.3101 16.79L7.2501 17.85C7.20361 17.8969 7.14831 17.9341 7.08738 17.9594C7.02645 17.9848 6.9611 17.9979 6.8951 17.9979C6.82909 17.9979 6.76374 17.9848 6.70281 17.9594C6.64188 17.9341 6.58658 17.8969 6.5401 17.85L1.1501 12.46C1.10323 12.4135 1.06604 12.3582 1.04065 12.2973C1.01527 12.2364 1.0022 12.171 1.0022 12.105C1.0022 12.039 1.01527 11.9736 1.04065 11.9127C1.06604 11.8518 1.10323 11.7965 1.1501 11.75L1.8501 11.05C1.89658 11.0031 1.95188 10.9659 2.01281 10.9405C2.07374 10.9152 2.13909 10.9021 2.2051 10.9021C2.2711 10.9021 2.33646 10.9152 2.39738 10.9405C2.45831 10.9659 2.51361 11.0031 2.5601 11.05ZM22.8501 6.53999L22.1501 5.82999C22.0549 5.73775 21.9276 5.68616 21.7951 5.68616C21.6626 5.68616 21.5352 5.73775 21.4401 5.82999L11.8901 15.38L10.8101 14.3L17.8101 7.29999C17.857 7.25351 17.8942 7.19821 17.9195 7.13728C17.9449 7.07635 17.958 7.011 17.958 6.94499C17.958 6.87899 17.9449 6.81364 17.9195 6.75271C17.8942 6.69178 17.857 6.63648 17.8101 6.58999L17.1101 5.87999C17.0149 5.78775 16.8876 5.73616 16.7551 5.73616C16.6226 5.73616 16.4952 5.78775 16.4001 5.87999L9.4001 12.93L7.5601 11.05C7.51361 11.0031 7.45831 10.9659 7.39738 10.9405C7.33645 10.9152 7.2711 10.9021 7.2051 10.9021C7.13909 10.9021 7.07374 10.9152 7.01281 10.9405C6.95188 10.9659 6.89658 11.0031 6.8501 11.05L6.1501 11.75C6.10323 11.7965 6.06604 11.8518 6.04065 11.9127C6.01527 11.9736 6.0022 12.039 6.0022 12.105C6.0022 12.171 6.01527 12.2364 6.04065 12.2973C6.06604 12.3582 6.10323 12.4135 6.1501 12.46L11.5401 17.85C11.5866 17.8969 11.6419 17.9341 11.7028 17.9594C11.7637 17.9848 11.8291 17.9979 11.8951 17.9979C11.9611 17.9979 12.0265 17.9848 12.0874 17.9594C12.1483 17.9341 12.2036 17.8969 12.2501 17.85L22.8501 7.24999C22.897 7.20351 22.9342 7.14821 22.9595 7.08728C22.9849 7.02635 22.998 6.961 22.998 6.89499C22.998 6.82899 22.9849 6.76364 22.9595 6.70271C22.9342 6.64178 22.897 6.58648 22.8501 6.53999Z"
                    fill="black"
                  />
                </svg>
              </div>
              <span>{t('menu:navbar_machine.tasks')}</span>
            </NavLink>
          )}
          {scenario && (
            <NavLink
              activeClassName="active-machine"
              to={`/machines/${name}/scenario`}
              className="nav-link machine-scenarious"
            >
              <div className="icon-area">
                <SvgIcon.Scenario></SvgIcon.Scenario>
              </div>
              <span>{t('menu:navbar_machine.scenario')}</span>
            </NavLink>
          )}
          {!thrust_point && (
            <NavLink
              activeClassName="active-machine"
              to={`/machines/${name}/scoreboard`}
              className="nav-link machine-scoreboard"
            >
              <div className="icon-area">
                <SvgIcon.Trophy></SvgIcon.Trophy>
              </div>
              <span>{t('menu:navbar_machine.scoreboard')}</span>
            </NavLink>
          )}
          {video_id && (
            <NavLink
              activeClassName="active-machine"
              to={`/machines/${name}/videos/${video_id}`}
              className="nav-link machine-videos"
            >
              <div className="icon-area">
                <SvgIcon.Play></SvgIcon.Play>
              </div>
              <span>{t('menu:navbar_machine.videos')}</span>
            </NavLink>
          )}
          {documents && (
            <NavLink
              activeClassName="active-machine"
              to={`/machines/${name}/documentations`}
              className="nav-link machine-docs"
            >
              <div className="icon-area">
                <svg
                  width="16"
                  height="20"
                  viewBox="0 0 16 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 0H7.24C6.44462 0.000700208 5.68207 0.31723 5.12 0.88L0.88 5.12C0.31723 5.68207 0.000700208 6.44462 0 7.24V18C0 18.5304 0.210714 19.0391 0.585786 19.4142C0.960859 19.7893 1.46957 20 2 20H14C14.5304 20 15.0391 19.7893 15.4142 19.4142C15.7893 19.0391 16 18.5304 16 18V2C16 1.46957 15.7893 0.960859 15.4142 0.585786C15.0391 0.210714 14.5304 0 14 0ZM14 18H2V8H7C7.26522 8 7.51957 7.89464 7.70711 7.70711C7.89464 7.51957 8 7.26522 8 7V2H14V18ZM4 10.5V11.5C4 11.6326 4.05268 11.7598 4.14645 11.8536C4.24021 11.9473 4.36739 12 4.5 12H11.5C11.6326 12 11.7598 11.9473 11.8536 11.8536C11.9473 11.7598 12 11.6326 12 11.5V10.5C12 10.3674 11.9473 10.2402 11.8536 10.1464C11.7598 10.0527 11.6326 10 11.5 10H4.5C4.36739 10 4.24021 10.0527 4.14645 10.1464C4.05268 10.2402 4 10.3674 4 10.5ZM8.5 14H4.5C4.36739 14 4.24021 14.0527 4.14645 14.1464C4.05268 14.2402 4 14.3674 4 14.5V15.5C4 15.6326 4.05268 15.7598 4.14645 15.8536C4.24021 15.9473 4.36739 16 4.5 16H8.5C8.63261 16 8.75979 15.9473 8.85355 15.8536C8.94732 15.7598 9 15.6326 9 15.5V14.5C9 14.3674 8.94732 14.2402 8.85355 14.1464C8.75979 14.0527 8.63261 14 8.5 14Z"
                    fill="black"
                  />
                </svg>
              </div>
              <span>{t('menu:navbar_machine.docs_text')}</span>
            </NavLink>
          )}

          <IsAdmin>
            <NavLink
              activeClassName="active-machine"
              to={`/machines/${name}/log`}
              className="nav-link machine-logs"
            >
              <SvgIcon.Command></SvgIcon.Command>
              <span>{t('menu:navbar_machine.action_log')}</span>
            </NavLink>
          </IsAdmin>

          <NavLink
            exact
            activeClassName="active-machine"
            to="/machines"
            className="nav-link logout"
          >
            <div className="icon-area">
              <i className="sign out alternate icon"></i>
            </div>
            <span>{t('menu:navbar_machine.exit_machine')}</span>
          </NavLink>
        </nav>
      ) : challenge ? (
        <nav className="type-2">
          <NavLink
            exact
            activeClassName="active-machine"
            to={`/challenges/${name}`}
            className="nav-link machine-info"
          >
            <div className="icon-area">
              <SvgIcon.FlagAlternate></SvgIcon.FlagAlternate>
            </div>
            <span>{t('menu:navbar_ctf.ctf_information')}</span>
          </NavLink>
          <NavLink
            activeClassName="active-machine"
            to={`/challenges/${name}/task`}
            className="nav-link machine-task"
          >
            <div className="icon-area">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.5601 11.05L8.3101 16.79L7.2501 17.85C7.20361 17.8969 7.14831 17.9341 7.08738 17.9594C7.02645 17.9848 6.9611 17.9979 6.8951 17.9979C6.82909 17.9979 6.76374 17.9848 6.70281 17.9594C6.64188 17.9341 6.58658 17.8969 6.5401 17.85L1.1501 12.46C1.10323 12.4135 1.06604 12.3582 1.04065 12.2973C1.01527 12.2364 1.0022 12.171 1.0022 12.105C1.0022 12.039 1.01527 11.9736 1.04065 11.9127C1.06604 11.8518 1.10323 11.7965 1.1501 11.75L1.8501 11.05C1.89658 11.0031 1.95188 10.9659 2.01281 10.9405C2.07374 10.9152 2.13909 10.9021 2.2051 10.9021C2.2711 10.9021 2.33646 10.9152 2.39738 10.9405C2.45831 10.9659 2.51361 11.0031 2.5601 11.05ZM22.8501 6.53999L22.1501 5.82999C22.0549 5.73775 21.9276 5.68616 21.7951 5.68616C21.6626 5.68616 21.5352 5.73775 21.4401 5.82999L11.8901 15.38L10.8101 14.3L17.8101 7.29999C17.857 7.25351 17.8942 7.19821 17.9195 7.13728C17.9449 7.07635 17.958 7.011 17.958 6.94499C17.958 6.87899 17.9449 6.81364 17.9195 6.75271C17.8942 6.69178 17.857 6.63648 17.8101 6.58999L17.1101 5.87999C17.0149 5.78775 16.8876 5.73616 16.7551 5.73616C16.6226 5.73616 16.4952 5.78775 16.4001 5.87999L9.4001 12.93L7.5601 11.05C7.51361 11.0031 7.45831 10.9659 7.39738 10.9405C7.33645 10.9152 7.2711 10.9021 7.2051 10.9021C7.13909 10.9021 7.07374 10.9152 7.01281 10.9405C6.95188 10.9659 6.89658 11.0031 6.8501 11.05L6.1501 11.75C6.10323 11.7965 6.06604 11.8518 6.04065 11.9127C6.01527 11.9736 6.0022 12.039 6.0022 12.105C6.0022 12.171 6.01527 12.2364 6.04065 12.2973C6.06604 12.3582 6.10323 12.4135 6.1501 12.46L11.5401 17.85C11.5866 17.8969 11.6419 17.9341 11.7028 17.9594C11.7637 17.9848 11.8291 17.9979 11.8951 17.9979C11.9611 17.9979 12.0265 17.9848 12.0874 17.9594C12.1483 17.9341 12.2036 17.8969 12.2501 17.85L22.8501 7.24999C22.897 7.20351 22.9342 7.14821 22.9595 7.08728C22.9849 7.02635 22.998 6.961 22.998 6.89499C22.998 6.82899 22.9849 6.76364 22.9595 6.70271C22.9342 6.64178 22.897 6.58648 22.8501 6.53999Z"
                  fill="black"
                />
              </svg>
            </div>
            <span>{t('menu:navbar_ctf.tasks')}</span>
          </NavLink>
          <NavLink
            activeClassName="active-machine"
            to={`/challenges/${name}/scoreboard`}
            className="nav-link machine-scoreboard"
          >
            <div className="icon-area">
              <SvgIcon.Trophy></SvgIcon.Trophy>
            </div>
            <span>{t('menu:navbar_ctf.scoreboard')}</span>
          </NavLink>
          <IsAdmin>
            <NavLink
              activeClassName="active-machine"
              to={`/challenges/${name}/log`}
              className="nav-link machine-logs"
            >
              <div className="icon-area">
                <SvgIcon.Command></SvgIcon.Command>
              </div>
              <span>{t('menu:navbar_ctf.action_log')}</span>
            </NavLink>
          </IsAdmin>
          <NavLink
            exact
            activeClassName="active-machine"
            to="/challenges"
            className="nav-link logout"
          >
            <div className="icon-area">
              <i className="sign out alternate icon"></i>
            </div>
            <span>{t('menu:navbar_ctf.exit_ctf')}</span>
          </NavLink>
        </nav>
      ) : (
        <nav>
          <Accordion>
            <div className="nav-header">MENU</div>
            <NavLink
              exact
              to="/"
              className="nav-link index"
              activeClassName="active"
            >
              <div className="icon-area">
                <SvgIcon.Home />
              </div>
              <span>{t('sidebar:home')}</span>
            </NavLink>
            {user?.role === 'system_admin' && (
              <>
                <div
                  id="admin"
                  className={cn(
                    'ui',
                    'accordion',
                    'nav-link',
                    'browse',
                    activeIndex === 0 && 'active',
                  )}
                >
                  <Accordion.Title
                    active={activeIndex === 0}
                    index={0}
                    onClick={handleClick}
                  >
                    <div className="icon-area">
                      <SvgIcon.Difficulty />
                    </div>
                    <span>{t('sidebar:admin.title')}</span>
                    <Icon name="angle down" />
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 0}>
                    <NavLink
                      to="/users"
                      className="nav-link"
                      activeClassName="sub-active"
                    >
                      <span>{t('sidebar:admin.userlist')}</span>
                    </NavLink>
                    <NavLink
                      activeClassName="sub-active"
                      to="/ranks"
                      className="nav-link"
                    >
                      <span>{t('sidebar:admin.ranking')}</span>
                    </NavLink>
                    <NavLink
                      activeClassName="sub-active"
                      to="/feedbacks"
                      className="nav-link"
                    >
                      <span>{t('sidebar:admin.feedbacks')}</span>
                    </NavLink>
                    <NavLink
                      activeClassName="sub-active"
                      to="/commands"
                      className="nav-link"
                    >
                      <span>{t('sidebar:admin.commands')}</span>
                    </NavLink>
                    <NavLink
                      activeClassName="sub-active"
                      to="/file-management"
                      className="nav-link"
                    >
                      <span>{t('sidebar:admin.files')}</span>
                    </NavLink>
                    <NavLink
                      activeClassName="sub-active"
                      to="/mitre"
                      className="nav-link"
                    >
                      <span>Mitre</span>
                    </NavLink>
                    <NavLink
                      activeClassName="sub-active"
                      to="/wikis"
                      className="nav-link"
                    >
                      <span>Wiki</span>
                    </NavLink>
                    <NavLink
                      activeClassName="sub-active"
                      to="/contributors"
                      className="nav-link"
                    >
                      <span>{t('sidebar:admin.contributors')}</span>
                    </NavLink>
                  </Accordion.Content>
                </div>

                <div
                  id="infra"
                  className={cn(
                    'ui',
                    'accordion',
                    'nav-link',
                    'browse',
                    activeIndex === 1 && 'active',
                  )}
                >
                  <Accordion.Title
                    active={activeIndex === 1}
                    index={1}
                    onClick={handleClick}
                  >
                    <div className="icon-area">
                      <SvgIcon.Infrastructure />
                    </div>
                    <span>{t('sidebar:infra.title')}</span>
                    <Icon name="angle down" />
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 1}>
                    <NavLink
                      activeClassName="sub-active"
                      to="/proxmox-settings"
                      className="nav-link"
                    >
                      <span>{t('sidebar:infra.proxmox')}</span>
                    </NavLink>
                    <NavLink
                      activeClassName="sub-active"
                      to="/firewall-settings"
                      className="nav-link"
                    >
                      <span>{t('sidebar:infra.firewall')}</span>
                    </NavLink>
                    <NavLink
                      activeClassName="sub-active"
                      to="/smtp-settings"
                      className="nav-link"
                    >
                      <span>{t('sidebar:infra.smtp')}</span>
                    </NavLink>
                    <NavLink
                      activeClassName="sub-active"
                      to="/telegram-settings"
                      className="nav-link"
                    >
                      <span>{t('sidebar:infra.telegram')}</span>
                    </NavLink>
                    <NavLink
                      activeClassName="sub-active"
                      to="/proxmox-system-machines"
                      className="nav-link"
                    >
                      <span>{t('sidebar:infra.system')}</span>
                    </NavLink>
                  </Accordion.Content>
                </div>

                <div
                  id="general"
                  className={cn(
                    'ui',
                    'accordion',
                    'nav-link',
                    'browse',
                    activeIndex === 2 && 'active',
                  )}
                >
                  <Accordion.Title
                    active={activeIndex === 2}
                    index={2}
                    onClick={handleClick}
                  >
                    <div className="icon-area">
                      <SvgIcon.Settings />
                    </div>
                    <span>{t('sidebar:general.title')}</span>
                    <Icon name="angle down" />
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 2}>
                    <NavLink
                      activeClassName="sub-active"
                      to="/meta-tags"
                      className="nav-link"
                    >
                      <span>{t('sidebar:general.meta')}</span>
                    </NavLink>
                    <NavLink
                      activeClassName="sub-active"
                      to="/panel-settings"
                      className="nav-link"
                    >
                      <span>{t('sidebar:general.panel')}</span>
                    </NavLink>
                    <NavLink
                      activeClassName="sub-active"
                      to="/notifications"
                      className="nav-link"
                    >
                      <span>
                        {t('sidebar:general.notifications')}
                      </span>
                    </NavLink>
                  </Accordion.Content>
                </div>
              </>
            )}
            <NavLink
              to="/scenarios"
              className="nav-link scenarious"
              activeClassName="active"
            >
              <div className="icon-area">
                <SvgIcon.Scenario />
              </div>
              <span>{t('sidebar:scenarios')}</span>
            </NavLink>
            {user?.role === 'system_admin' ? (
              <div
                id="machines"
                className={cn(
                  'ui',
                  'accordion',
                  'nav-link',
                  'browse',
                  activeIndex === 3 && 'active',
                )}
              >
                <Accordion.Title
                  active={activeIndex === 3}
                  index={3}
                  onClick={handleClick}
                >
                  <div className="icon-area">
                    <SvgIcon.Machine />
                  </div>
                  <span>{t('sidebar:machines.title')}</span>
                  <Icon name="angle down" />
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 3}>
                  <NavLink
                    activeClassName="sub-active"
                    to="/machines"
                    className="nav-link"
                  >
                    <span>{t('sidebar:machines.list')}</span>
                  </NavLink>
                  <NavLink
                    activeClassName="sub-active"
                    to="/operating-systems"
                    className="nav-link"
                  >
                    <span>{t('sidebar:machines.os')}</span>
                  </NavLink>
                  <NavLink
                    activeClassName="sub-active"
                    to="/sub-operating-systems"
                    className="nav-link"
                  >
                    <span>{t('sidebar:machines.os_dist')}</span>
                  </NavLink>
                  <NavLink
                    activeClassName="sub-active"
                    to="/vlanids"
                    className="nav-link"
                  >
                    <span>{t('sidebar:machines.vlan')}</span>
                  </NavLink>
                  <AddMachineModal
                    newMachine={newMachine}
                    setNewMachine={setNewMachine}
                    trigger={
                      <a className="nav-link">
                        <span>
                          {t('sidebar:machines.new_machine')}
                        </span>
                      </a>
                    }
                  ></AddMachineModal>
                  <NavLink
                    activeClassName="sub-active"
                    to="/categories"
                    className="nav-link"
                  >
                    <span>{t('sidebar:machines.categories')}</span>
                  </NavLink>
                  <NavLink
                    activeClassName="sub-active"
                    to="/sub-categories"
                    className="nav-link"
                  >
                    <span>
                      {t('sidebar:machines.sub_categories')}
                    </span>
                  </NavLink>
                  <NavLink
                    activeClassName="sub-active"
                    to="/linked-machines"
                    className="nav-link"
                  >
                    <span>{t('sidebar:machines.linked')}</span>
                  </NavLink>
                  <NavLink
                    activeClassName="sub-active"
                    to="/appointments"
                    className="nav-link"
                  >
                    <span>{t('sidebar:machines.status')}</span>
                  </NavLink>
                </Accordion.Content>
              </div>
            ) : (
              <NavLink
                to="/machines"
                className="nav-link machines"
                activeClassName="active"
              >
                <div className="icon-area">
                  <SvgIcon.Machine />
                </div>
                <span>{t('sidebar:machines.list')}</span>
              </NavLink>
            )}
            <NavLink
              exact
              to="/simulation-machines"
              className="nav-link simulation"
              activeClassName="active"
            >
              <div className="icon-area">
                <SvgIcon.Simulation />
              </div>
              <span>{t('sidebar:simulation')}</span>
            </NavLink>
            <NavLink
              to="/challenges"
              className="nav-link ctf"
              activeClassName="active"
            >
              <div className="icon-area">
                <SvgIcon.Flag />
              </div>
              <span>{t('sidebar:ctf')}</span>
            </NavLink>
            <div
              id="education"
              className={cn(
                'ui',
                'accordion',
                'nav-link',
                'browse',
                activeIndex === 4 && 'active',
              )}
            >
              <Accordion.Title
                active={activeIndex === 4}
                index={4}
                onClick={handleClick}
              >
                <div className="icon-area">
                  <SvgIcon.Education />
                </div>
                <span>{t('sidebar:education.title')}</span>
                <Icon name="angle down" />
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 4}>
                <NavLink
                  activeClassName="sub-active"
                  to="/videos"
                  className="nav-link"
                >
                  <span>{t('sidebar:education.videos')}</span>
                </NavLink>
                <NavLink
                  activeClassName="sub-active"
                  to="/playlists"
                  className="nav-link"
                >
                  <span>{t('sidebar:education.playlist')}</span>
                </NavLink>
              </Accordion.Content>
            </div>
            {user?.role === 'system_admin' ? (
              <div
                id="quiz"
                className={cn(
                  'ui',
                  'accordion',
                  'nav-link',
                  'browse',
                  activeIndex === 5 && 'active',
                )}
              >
                <Accordion.Title
                  active={activeIndex === 5}
                  index={5}
                  onClick={handleClick}
                >
                  <div className="icon-area">
                    <SvgIcon.Quiz />
                  </div>
                  <span>{t('sidebar:quizzes.title')}</span>
                  <Icon name="angle down" />
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 5}>
                  <NavLink
                    activeClassName="sub-active"
                    to="/quizzes"
                    className="nav-link"
                  >
                    <span>{t('sidebar:quizzes.list')}</span>
                  </NavLink>
                  <NavLink
                    activeClassName="sub-active"
                    to="/quiz-questions"
                    className="nav-link"
                  >
                    <span>{t('sidebar:quizzes.questions')}</span>
                  </NavLink>
                  <NavLink
                    activeClassName="sub-active"
                    to="/quiz-categories"
                    className="nav-link"
                  >
                    <span>{t('sidebar:quizzes.categories')}</span>
                  </NavLink>
                </Accordion.Content>
              </div>
            ) : (
              <NavLink
                to="/quizzes"
                className="nav-link quizzes"
                activeClassName="active"
              >
                <div className="icon-area">
                  <SvgIcon.Quiz />
                </div>
                <span>{t('sidebar:quizzes.list')}</span>
              </NavLink>
            )}

            <NavLink
              to="/teams"
              className="nav-link scoreboard"
              activeClassName="active"
            >
              <div className="icon-area">
                <SvgIcon.TeamAlternate />
              </div>
              <span>{t('sidebar:teams.team_list')}</span>
            </NavLink>
            <NavLink
              exact
              to="/scoreboard"
              className="nav-link scoreboard"
              activeClassName="active"
            >
              <div className="icon-area">
                <SvgIcon.Trophy />
              </div>
              <span>{t('sidebar:scoreboard')}</span>
            </NavLink>
            {user?.role === 'system_admin' && (
              <div
                id="reports"
                className={cn(
                  'ui',
                  'accordion',
                  'nav-link',
                  'browse',
                  activeIndex === 6 && 'active',
                )}
              >
                <Accordion.Title
                  active={activeIndex === 6}
                  index={6}
                  onClick={handleClick}
                >
                  <div className="icon-area">
                    <SvgIcon.Reports />
                  </div>
                  <span>{t('sidebar:reports.title')}</span>
                  <Icon name="angle down" />
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 6}>
                  <Modal
                    size="tiny"
                    open={modal === 'user'}
                    onClose={() => setModal('')}
                    onOpen={() => openModalHandler('user')}
                    title={t('global:modal.report-user.title')}
                    tag={t('global:modal.report-user.tag')}
                    button={
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          if (selectedUser) {
                            setModal('');
                            history.push(
                              `/reports/users/${selectedUser}`,
                            );
                          } else {
                            toast.error(
                              t('general:sure_select_user'),
                            );
                          }
                        }}
                      >
                        {t('global:modal.report-user.ok_button')}
                      </Button>
                    }
                    trigger={
                      <a
                        className={cn(
                          'nav-link',
                          window.location.pathname.startsWith(
                            '/reports/users',
                          ) && 'sub-active',
                        )}
                      >
                        <span>{t('sidebar:reports.user_based')}</span>
                      </a>
                    }
                  >
                    <Form>
                      <Form.Dropdown
                        label={t('global:global.user')}
                        search
                        selection
                        placeholder={t('global:global.choose')}
                        options={userList.map((item, index) => ({
                          key: index,
                          value: item.username,
                          text: item.username,
                        }))}
                        onChange={(e, { value }) =>
                          setSelectedUser(value)
                        }
                        value={selectedUser}
                      ></Form.Dropdown>
                    </Form>
                  </Modal>

                  <Modal
                    size="tiny"
                    open={modal === 'machine'}
                    onClose={() => setModal('')}
                    onOpen={() => openModalHandler('machine')}
                    title={t('global:modal.report-machine.title')}
                    tag={t('global:modal.report-machine.tag')}
                    button={
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!selectedMachine) {
                            toast.error(
                              'Makine seçtiğinizden emin olun',
                            );
                          } else {
                            setModal('');
                            history.push(
                              `/reports/machines/${selectedMachine}`,
                            );
                          }
                        }}
                      >
                        {t('global:modal.report-machine.ok_button')}
                      </Button>
                    }
                    trigger={
                      <a
                        className={cn(
                          'nav-link',
                          window.location.pathname.startsWith(
                            '/reports/machines',
                          ) && 'sub-active',
                        )}
                      >
                        <span>
                          {t('sidebar:reports.machine_based')}
                        </span>
                      </a>
                    }
                  >
                    <Form>
                      <Form.Dropdown
                        label={t('global:global.machine')}
                        search
                        selection
                        placeholder={t('global:global.choose')}
                        options={machineList.map((item, index) => ({
                          key: index,
                          value: item.value,
                          text: item.text,
                        }))}
                        onChange={(e, { value }) =>
                          setSelectedMachine(value)
                        }
                        value={selectedMachine}
                      ></Form.Dropdown>
                    </Form>
                    <div className="report-link">
                      <Button
                        color="grey-bordering"
                        onClick={(e) => {
                          e.stopPropagation();
                          setModal('');
                          history.push(`/reports/machines`);
                        }}
                      >
                        {t('global:modal.report-machine.all_button')}
                      </Button>
                    </div>
                  </Modal>

                  <Modal
                    size="tiny"
                    open={modal === 'ctf'}
                    onClose={() => setModal('')}
                    onOpen={() => openModalHandler('ctf')}
                    title={t('global:modal.report-ctf.title')}
                    tag={t('global:modal.report-ctf.tag')}
                    button={
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!selectedCtf) {
                            toast.error(
                              'Ctf seçtiğinizden emin olun',
                            );
                          } else {
                            setModal('');
                            history.push(
                              `/reports/challenges/${selectedCtf}`,
                            );
                          }
                        }}
                      >
                        {t('global:modal.report-ctf.ok_button')}
                      </Button>
                    }
                    trigger={
                      <a
                        className={cn(
                          'nav-link',
                          window.location.pathname.startsWith(
                            '/reports/challenges',
                          ) && 'sub-active',
                        )}
                      >
                        <span>{t('sidebar:reports.ctf_based')}</span>
                      </a>
                    }
                  >
                    <Form>
                      <Form.Dropdown
                        label="CTF"
                        search
                        selection
                        placeholder={t('global:global.choose')}
                        options={ctfList.map((item, index) => ({
                          key: index,
                          value: item.value,
                          text: item.text,
                        }))}
                        onChange={(e, { value }) =>
                          setSelectedCtf(value)
                        }
                        value={selectedCtf}
                      ></Form.Dropdown>
                    </Form>
                    <div className="report-link">
                      <Button
                        color="grey-bordering"
                        onClick={(e) => {
                          e.stopPropagation();
                          setModal('');
                          history.push(`/reports/challenges`);
                        }}
                      >
                        {t('global:modal.report-ctf.all_button')}
                      </Button>
                    </div>
                  </Modal>
                  <Modal
                    size="tiny"
                    open={modal === 'categoryType'}
                    onOpen={() => openModalHandler('categoryType')}
                    onClose={() => setModal('')}
                    title={t(
                      'global:modal.report-category-type.title',
                    )}
                    tag={t('global:modal.report-category-type.tag')}
                    closeButton={false}
                    trigger={
                      <a
                        className={cn(
                          'nav-link',
                          window.location.pathname.startsWith(
                            '/reports/categories',
                          ) && 'sub-active',
                        )}
                      >
                        <span>
                          {t('sidebar:reports.category_based')}
                        </span>
                      </a>
                    }
                  >
                    <div className="grid-2">
                      <Button
                        color="grey-bordering"
                        onClick={(e) => {
                          e.stopPropagation();
                          setModal('category');
                          setSelectedCategoryType('machines');

                          openModalHandler('machines');
                        }}
                      >
                        {t(
                          'global:modal.report-category-type.button_1',
                        )}
                      </Button>
                      <Button
                        color="grey-bordering"
                        onClick={(e) => {
                          e.stopPropagation();
                          setModal('category');
                          setSelectedCategoryType('challenges');

                          openModalHandler('challenges');
                        }}
                      >
                        {t(
                          'global:modal.report-category-type.button_2',
                        )}
                      </Button>
                    </div>
                  </Modal>
                  <Modal
                    size="tiny"
                    open={
                      modal === 'machines' || modal === 'challenges'
                    }
                    onClose={() => setModal('')}
                    title={
                      selectedCategoryType === 'machines'
                        ? t('global:modal.report-category.title')
                        : t('global:modal.report-category.title2')
                    }
                    tag={t('global:modal.report-category.tag')}
                    button={
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          history.push(
                            `/reports/categories/${selectedCategoryType}/${selectedCategory}`,
                          );
                          setModal('');
                        }}
                      >
                        {t('global:modal.report-category.ok_button')}
                      </Button>
                    }
                  >
                    <Form>
                      <Form.Dropdown
                        label={t('global:global.category')}
                        search
                        selection
                        placeholder={t('global:global.choose')}
                        options={categoryBasedList.map(
                          (item, index) => ({
                            key: index,
                            value: item.value,
                            text: item.text,
                          }),
                        )}
                        onChange={(e, { value }) =>
                          setSelectedCategory(value)
                        }
                        value={selectedCategory}
                      ></Form.Dropdown>
                    </Form>
                  </Modal>
                  <Modal
                    size="tiny"
                    open={modal === 'education'}
                    onClose={() => setModal('')}
                    onOpen={() => openModalHandler('education')}
                    title={t('global:modal.report-education.title')}
                    tag={t('global:modal.report-education.tag')}
                    button={
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!selectedEducation) {
                            toast.error(
                              'Eğitim seçtiğinizden emin olun',
                            );
                          } else {
                            setModal('');
                            history.push(
                              `/reports/educations/${selectedEducation}`,
                            );
                          }
                        }}
                      >
                        {t('global:modal.report-education.ok_button')}
                      </Button>
                    }
                    trigger={
                      <a className="nav-link">
                        <span>
                          {t('sidebar:reports.education_based')}
                        </span>
                      </a>
                    }
                  >
                    <Form>
                      <Form.Dropdown
                        label={t('global:global.category')}
                        search
                        selection
                        placeholder={t('global:global.choose')}
                        options={educationList.map((item, index) => ({
                          key: index,
                          value: item.value,
                          text: item.text,
                        }))}
                        onChange={(e, { value }) =>
                          setSelectedEducation(value)
                        }
                        value={selectedEducation}
                      ></Form.Dropdown>
                    </Form>

                    <div className="report-link">
                      <Button
                        color="grey-bordering"
                        onClick={() => {
                          setModal('');
                          history.push(`/reports/educations`);
                        }}
                      >
                        {t(
                          'global:modal.report-education.all_button',
                        )}
                      </Button>
                    </div>
                  </Modal>

                  <Modal
                    size="tiny"
                    open={modal === 'quiz'}
                    onClose={() => setModal('')}
                    onOpen={() => openModalHandler('quiz')}
                    title={t('global:modal.report-quiz.title')}
                    tag={t('global:modal.report-quiz.tag')}
                    button={
                      <Button
                        onClick={() => {
                          if (!selectedQuiz) {
                            toast.error(
                              'Sınav seçtiğinizden emin olun',
                            );
                          } else {
                            setModal('');
                            history.push(
                              `/reports/quizzes/${selectedQuiz}`,
                            );
                          }
                        }}
                      >
                        {t('global:modal.report-quiz.ok_button')}
                      </Button>
                    }
                    trigger={
                      <a
                        className={cn(
                          'nav-link',
                          window.location.pathname.startsWith(
                            '/reports/quizzes',
                          ) && 'sub-active',
                        )}
                      >
                        <span>
                          {t('sidebar:reports.quiz_report')}
                        </span>
                      </a>
                    }
                  >
                    <Form>
                      <Form.Dropdown
                        label={t('global:global.quiz')}
                        search
                        selection
                        placeholder={t('global:global.choose')}
                        options={quizList.map((item, index) => ({
                          key: index,
                          value: item.id,
                          text: item.name,
                        }))}
                        onChange={(e, { value }) =>
                          setSelectedQuiz(value)
                        }
                        value={selectedQuiz}
                      ></Form.Dropdown>
                    </Form>
                    <div className="report-link">
                      <Button
                        color="grey-bordering"
                        onClick={(e) => {
                          e.stopPropagation();
                          setModal('');
                          history.push(`/reports/quizzes`);
                        }}
                      >
                        {t('global:modal.report-quiz.all_button')}
                      </Button>
                    </div>
                  </Modal>
                  <Modal
                    size="tiny"
                    open={modal === 'team'}
                    onClose={() => setModal('')}
                    onOpen={() => openModalHandler('team')}
                    title={t('global:modal.report-team.title')}
                    tag={t('global:modal.report-team.tag')}
                    button={
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          if (selectedTeam) {
                            setModal('');
                            history.push(
                              `/reports/teams/${selectedTeam}`,
                            );
                          } else {
                            toast.error(
                              t('general:sure_select_user'),
                            );
                          }
                        }}
                      >
                        {t('global:modal.report-team.ok_button')}
                      </Button>
                    }
                    trigger={
                      <a
                        className={cn(
                          'nav-link',
                          window.location.pathname.startsWith(
                            '/reports/teams',
                          ) && 'sub-active',
                        )}
                      >
                        <span>{t('sidebar:reports.team_based')}</span>
                      </a>
                    }
                  >
                    <Form>
                      <Form.Dropdown
                        label={t('global:global.team')}
                        search
                        selection
                        placeholder={t('global:global.choose')}
                        options={teamList.map((item, index) => ({
                          key: index,
                          value: item.name,
                          text: item.name,
                        }))}
                        onChange={(e, { value }) =>
                          setSelectedTeam(value)
                        }
                        value={selectedTeam}
                      ></Form.Dropdown>
                    </Form>
                  </Modal>
                </Accordion.Content>
              </div>
            )}
            <NavLink
              exact
              to="/hubnews"
              className="nav-link hubnews"
              activeClassName="active"
            >
              <div className="icon-area">
                <SvgIcon.Command />
              </div>
              <span>{t('sidebar:hubnews')}</span>
            </NavLink>
            <div className="nav-header">{t('sidebar:help')}</div>
            <NavLink
              to="/knowledge-base"
              className="nav-link knowledge-base"
              activeClassName="active"
            >
              <div className="icon-area">
                <SvgIcon.KnowledgeBase />
              </div>
              <span>{t('sidebar:knowledge')}</span>
            </NavLink>
            <NavLink
              to="/tickets"
              className="nav-link ticket-list"
              activeClassName="active"
            >
              <div className="icon-area">
                <SvgIcon.Support />
              </div>
              <span>{t('sidebar:support')}</span>
            </NavLink>
            <a
              href={panelSettings.web_site}
              className="copyright"
              target="_blank"
              rel="noopener noreferrer"
            >
              © {panelSettings.copright}
            </a>
          </Accordion>
        </nav>
      )}
    </>
  );
}

export default Nav;
