import * as React from 'react';

function SvgInfrastructure(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2 8.001H6.8a.798.798 0 00-.799.799v6.4c0 .442.357.799.799.799h6.4a.798.798 0 00.799-.799V8.8a.798.798 0 00-.799-.799zM6.8 6.4a2.4 2.4 0 00-2.4 2.4v6.4a2.4 2.4 0 002.4 2.4h6.4a2.4 2.4 0 002.4-2.4V8.8a2.4 2.4 0 00-2.4-2.4H6.8z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.2 11.2v1.6h1.6v-1.6H9.2zm-.8-1.6a.8.8 0 00-.8.8v3.2a.8.8 0 00.8.8h3.2a.8.8 0 00.8-.8v-3.2a.8.8 0 00-.8-.8H8.4z"
        fill="currentColor"
      />
      <path
        d="M7.6 4c-.441 0-.8.359-.8.8v1.6h1.6V4.8a.8.8 0 00-.8-.8zM8.4 19.2v-1.6H6.8v1.6a.801.801 0 001.6 0zM11.6 4.8a.8.8 0 011.6 0v1.6h-1.6V4.8zM13.2 19.2v-1.6h-1.6v1.6a.8.8 0 001.6 0zM17.2 8.8a.801.801 0 010 1.6h-1.6V8.8h1.6zM2.8 10.4h1.6V8.8H2.8a.801.801 0 000 1.6zM17.2 13.6a.8.8 0 010 1.6h-1.6v-1.6h1.6zM2.8 15.2h1.6v-1.6H2.8a.8.8 0 000 1.6z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgInfrastructure;
