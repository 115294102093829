import * as React from 'react';

function SvgCommand(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.795 11.032L4.086 7.047a1.268 1.268 0 00-1.637 1.936L6.013 12l-3.564 3.017a1.267 1.267 0 101.637 1.936l4.709-3.985a1.269 1.269 0 000-1.936zM16.732 14.717h-5.705a1.267 1.267 0 100 2.536h5.705a1.268 1.268 0 000-2.536z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCommand;
