import React from 'react';
import cn from 'classnames';
import { Icon } from 'semantic-ui-react';
import Breadcrumb from '../../../Breadcrumb';
import Modal from '../../../Modal';
import styles from './onboardingModal.module.scss';
import OnboardingTab from './OnboardingTab';
import { nanoid } from 'nanoid';
import { useSelector } from 'react-redux';

const ContentModal = ({
  data,
  isOpen,
  setOpen,
  index,
  breadcrumb,
  tabs,
  activeTab = 0,
  setActiveTab = () => null,
  children,
}) => {
  const panelSettings = useSelector((state) => state.panelSettings);

  const handleTabChange = (index) => {
    if (index > 0) {
      data.tab_success[index - 1].success && setActiveTab(index);
    } else setActiveTab(index);
  };

  return (
    <Modal
      size="small"
      className={cn('right-modal', styles.onboardingContent)}
      open={isOpen}
      onOpen={() => setOpen(index)}
      onClose={() => {
        setActiveTab(0);
        setOpen(-1);
      }}
      closeButton={false}
    >
      <div className={styles['onboarding-modal-wrapper']}>
        <div
          className="cancel"
          onClick={() => {
            setActiveTab(0);
            setOpen(false);
          }}
        >
          <Icon name="times" />
        </div>

        <Breadcrumb titles={breadcrumb} />
        <div className={styles['modal-tab-area']}>
          {tabs.length > 1 &&
            tabs.map((tab, index) => (
              <OnboardingTab
                key={nanoid()}
                active={activeTab === index}
                onClick={() => handleTabChange(index)}
                success={
                  data ? data['tab_success'][index]['success'] : false
                }
                title={tab.name}
              />
            ))}
        </div>
        <div className={styles['modal-content-area']}>
          {React.Children.map(children, (child, index) => (
            <div
              className={cn(
                styles.content,
                activeTab === index && styles.active,
              )}
            >
              {child}
            </div>
          ))}
        </div>

        <a
          className={'copyright-area'}
          href={panelSettings.web_site}
          target="_blank"
          rel="noopener noreferrer"
        >
          © {panelSettings.copright}
        </a>
      </div>
    </Modal>
  );
};

ContentModal.Tab = React.memo(function ({ text, success }) {
  return <div className={styles['tab-item']}></div>;
});

export default ContentModal;
