import React from 'react';
import cn from 'classnames';
import { Progress } from 'semantic-ui-react';
import styles from './progress.module.scss';

function ProgressBar({
  percent,
  className,
  children,
  colorized,
  indicating,
  color,
  right = false,
  titles,
  ...props
}) {
  return (
    <Progress
      className={cn(
        styles.progress,
        (percent >= 100 || colorized) && styles.success,
        color,
        className,
        right === true ? 'right' : '',
      )}
      percent={percent}
      indicating={indicating}
      {...props}
    >
      {children}
      
    </Progress>
  );
}

export default ProgressBar;
