import React from 'react';
import cn from 'classnames';
import styles from './onboardingTab.module.scss';

const OnboardingTab = ({ success, title, active, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={cn(styles.tab, active && styles.active)}
    >
      {success && <div className={styles.check}></div>}
      {title}
    </div>
  );
};

export default OnboardingTab;
