import React from 'react';
import { Modal as SemanticModal } from 'semantic-ui-react';
import styles from './emptyModal.module.scss';

const EmptyModal = ({ children, ...props }) => {
  return (
    <SemanticModal className={styles.emptyModal} {...props}>
      {children}
    </SemanticModal>
  );
};

export default EmptyModal;
