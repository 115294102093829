import * as React from 'react';

function SvgHelp(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 4a8.003 8.003 0 00-7.391 4.938 8.004 8.004 0 001.734 8.719 8.007 8.007 0 008.718 1.734 8.002 8.002 0 004.33-10.453A8.003 8.003 0 0010 4zm0 14.4a6.404 6.404 0 01-5.913-3.951 6.404 6.404 0 011.388-6.975A6.399 6.399 0 1110 18.4zm.4-3.2h-.8a.4.4 0 00-.4.4v.8a.4.4 0 00.4.4h.8a.4.4 0 00.4-.4v-.8a.4.4 0 00-.4-.4zm2.704-5.6A2.304 2.304 0 0010.8 7.296H9.2A2.304 2.304 0 006.896 9.6v.4a.4.4 0 00.4.4h.608a.4.4 0 00.4-.4v-.4a.897.897 0 01.896-.896h1.6a.896.896 0 01.896.896v.448a.905.905 0 01-.608.856l-.76.248a1.504 1.504 0 00-1.032 1.424v.624a.4.4 0 00.4.4h.608a.4.4 0 00.4-.4v-.624a.104.104 0 01.096-.096l.76-.248a2.31 2.31 0 001.6-2.184l-.056-.448z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgHelp;
