import * as React from 'react';

function SvgQuestionMark(props) {
  return (
    <svg
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      {...props}
    >
      <ellipse
        cx={10}
        cy={12}
        rx={8}
        ry={7.917}
        fill="#75797E"
        fillOpacity={0.1}
        strokeWidth={0.3}
        stroke="currentColor"
      />
      <path
        d="M9.581 9.242a2.74 2.74 0 00-.742.099c-.237.06-.485.16-.743.3L7.719 8.61c.273-.155.584-.277.931-.366A4.142 4.142 0 019.736 8.1c.444 0 .809.063 1.098.188.288.118.517.27.687.455.169.184.288.388.355.609.066.222.099.437.099.643 0 .251-.048.477-.144.676-.088.2-.203.384-.343.555-.141.17-.293.332-.455.487a5.242 5.242 0 00-.454.466 2.51 2.51 0 00-.355.499 1.311 1.311 0 00-.133.598v.133c0 .044.003.089.011.133H8.949a2.697 2.697 0 01-.044-.477c0-.243.04-.461.122-.653a2.33 2.33 0 01.31-.532c.125-.163.258-.315.399-.455.148-.14.285-.281.41-.421.126-.14.229-.284.311-.432.081-.148.122-.311.122-.488a.78.78 0 00-.255-.599c-.163-.162-.41-.243-.743-.243zm.865 5.985a.84.84 0 01-.255.632.881.881 0 01-.632.243.907.907 0 01-.632-.243.836.836 0 01-.255-.632c0-.259.085-.47.255-.632a.883.883 0 01.632-.255c.251 0 .462.085.632.255a.838.838 0 01.255.632z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgQuestionMark;
