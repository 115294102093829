import * as React from 'react';

function SvgTime(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.317 8.21a.665.665 0 00-.31.89A6.674 6.674 0 0110 18.666 6.674 6.674 0 013.333 12 6.675 6.675 0 0110 5.333c1.523 0 2.954.497 4.139 1.438.287.23.707.182.936-.107a.664.664 0 00-.107-.936A8.008 8.008 0 0010 4c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8a7.925 7.925 0 00-.793-3.481.665.665 0 00-.89-.309z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        d="M10.428 11.247v.364h3.177a.33.33 0 01.339.32.33.33 0 01-.339.32h-3.517a.33.33 0 01-.34-.32V7.145a.33.33 0 01.34-.32.33.33 0 01.34.32v4.102z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  );
}

export default SvgTime;
