import { toast } from 'react-toastify';
import i18n from '../i18n';
import { _fetchJson, API_URL } from '../libs/fetch';

export const login = (email, password, setLoading) => (dispatch) => {
  setLoading(true);
  fetch(API_URL + '/auth/sign_in', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password }),
  })
    .then((res) => {
      if (res.status === 500) {
        throw new Error('Something went wrong.');
      }
      res.headers.forEach((val, key) => {
        if (val !== null) {
          if (
            key === 'access-token' ||
            key === 'token-type' ||
            key === 'client' ||
            key === 'uid' ||
            key === 'expiry'
          )
            localStorage.setItem(key, val);
        }
      });
      return res.json();
    })
    .then((res) => {
      if (res.data) {
        dispatch(getUser());
      } else res.errors.map((err) => toast.error(err));
    })
    .catch((err) => toast.error(err.message))
    .finally(() => setLoading(false));
};

export const signup = (data, setSuccess, setLoading) => {
  setLoading(true);
  _fetchJson('/auth', {
    method: 'post',
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (res.status === 'success') {
        setSuccess(true);
      } else {
        res.errors.full_messages.map((m) => toast.error(m));
      }
    })
    .catch((err) =>
      err.errors.full_messages.map((m) => toast.error(m)),
    )
    .finally(() => setLoading(false));
};

export const logout = () => (dispatch) => {
  const token = localStorage.getItem('access-token');
  const client = localStorage.getItem('client');
  const uid = localStorage.getItem('uid');

  _fetchJson('/auth/sign_out', {
    method: 'delete',
    body: JSON.stringify({ 'access-token': token, client, uid }),
  })
    .then((res) => {
      if (res.success) {
        localStorage.removeItem('access-token');
        localStorage.removeItem('client');
        localStorage.removeItem('uid');
        localStorage.removeItem('token-type');
        localStorage.removeItem('user');
        localStorage.removeItem('expiry');
      } else {
        toast.error(res.error);
      }
    })
    .catch((err) => toast.error(err.message || err.statusText));

  dispatch({ type: 'LOGOUT' });
};

export const reSendMail = (email, setSuccess, setLoading) => {
  setLoading(true);
  _fetchJson('/auth/confirmation', {
    method: 'post',
    body: JSON.stringify({ email }),
  })
    .then((res) => {
      if (res.success) {
        setSuccess(true);
      } else {
        toast.error(res.error);
      }
    })
    .catch((err) => toast.error(err.message || err.statusText))
    .finally(() => setLoading(false));
};

export const resetPassword = (email, setSuccess, setLoading) => {
  setLoading(true);
  _fetchJson('/auth/password', {
    method: 'post',
    body: JSON.stringify({
      email,
    }),
  })
    .then((res) => {
      if (res.success) {
        setLoading(false);
        setSuccess(true);
      } else {
        toast.error(res.error);
        setLoading(false);
      }
    })
    .catch((err) => {
      setLoading(false);
      toast.error(err.message || err.statusText);
    });
};

export const inviteComplete = (
  username,
  password,
  password_confirmation,
  invitation_token,
  setLoading,
) => {
  setLoading(true);
  return _fetchJson(`/auth/invitation`, {
    method: 'put',
    body: JSON.stringify({
      username,
      password,
      password_confirmation,
      invitation_token,
    }),
  })
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getUser = () => (dispatch) => {
  _fetchJson('/set_user', {
    method: 'post',
    body: JSON.stringify({
      access_token: localStorage.getItem('access-token'),
      client: localStorage.getItem('client'),
      uid: localStorage.getItem('uid'),
    }),
  })
    .then((data) => {
      if (!data.status) {
        dispatch({ type: 'SET_USER' });
        localStorage.removeItem('access-token');
        localStorage.removeItem('client');
        localStorage.removeItem('uid');
        localStorage.removeItem('token-type');
        localStorage.removeItem('user');
        localStorage.removeItem('expiry');
      } else
        dispatch({
          type: 'SET_USER',
          payload: data,
        });
      i18n.changeLanguage(data.language);
    })
    .catch((err) => dispatch({ type: 'SET_USER', payload: null }));
};

export const newPassword = (
  password,
  password_confirmation,
  access_token,
  client_id,
  uid,
  expiry,
  setLoading,
) => (dispatch) => {
  setLoading(true);
  fetch(API_URL + '/auth/password', {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      'access-token': access_token,
      client: client_id,
      uid: uid,
      expiry: expiry,
    },
    body: JSON.stringify({ password, password_confirmation }),
  })
    .then((res) => {
      if (res.status === 500) {
        throw new Error('Something went wrong.');
      }
      res.headers.forEach((val, key) => {
        if (val !== null) {
          if (
            key === 'access-token' ||
            key === 'token-type' ||
            key === 'client' ||
            key === 'uid' ||
            key === 'expiry'
          )
            localStorage.setItem(key, val);
        }
      });
      return res.json();
    })
    .then((res) => {
      if (res.success) {
        toast.success('Your password has been changed successfully');
        dispatch(getUser());
      } else res.errors.full_messages.map((err) => toast.error(err));
    })
    .catch((err) => {
      if (Array.isArray(err.errors?.full_messages)) {
        err.errors.full_messages.map((mes) => toast.error(mes));
      } else if (Array.isArray(err.errors)) {
        err.errors.map((mes) => toast.error(mes));
      } else {
        toast.error(err.message || err.data || err.statusText);
      }
    })
    .finally(() => setLoading(false));
};

export const updateUser = (payload) => (dispatch) => {
  dispatch({ type: 'UPDATE_USER', payload });
};
