import { store } from '../index';

export const API_URL = 'https://api.priviahub.com';

const handleResponse = (response) => {
  return response.json().then((json) => {
    if (response.ok) {
      return json;
    } else {
      const error = Object.assign({}, json, {
        status: response.status,
        statusText: response.statusText,
      });
      return Promise.reject(error);
    }
  });
};

export function _fetchJson(url, options = {}, customHeaders = {}) {
  return fetch(API_URL + url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'access-token': localStorage.getItem('access-token'),
      'token-type': localStorage.getItem('token-type'),
      client: localStorage.getItem('client'),
      uid: localStorage.getItem('uid'),
      ...customHeaders,
    },
    ...options,
  })
    .then(handleResponse)
    .catch((err) => {
      if (err.status === 401) {
        store.dispatch({ type: 'SET_USER', payload: null });
        localStorage.removeItem('access-token');
        localStorage.removeItem('client');
        localStorage.removeItem('expiry');
        localStorage.removeItem('token-type');
        localStorage.removeItem('uid');
        return Promise.reject(err);
      } else {
        return Promise.reject(err);
      }
    });
}

export function _fetchFormData(
  url,
  options = {},
  customHeaders = {},
) {
  return fetch(API_URL + url, {
    method: 'GET',
    headers: {
      'access-token': localStorage.getItem('access-token'),
      'token-type': localStorage.getItem('token-type'),
      client: localStorage.getItem('client'),
      uid: localStorage.getItem('uid'),
      ...customHeaders,
    },
    ...options,
  })
    .then(handleResponse)
    .catch((err) => {
      if (err.status === 401) {
        store.dispatch({ type: 'SET_USER', payload: null });
        localStorage.removeItem('access-token');
        localStorage.removeItem('client');
        localStorage.removeItem('expiry');
        localStorage.removeItem('token-type');
        localStorage.removeItem('uid');
        return Promise.reject(err);
      } else {
        return Promise.reject(err);
      }
    });
}

export function _fetchBlob(url, options = {}, customHeaders = {}) {
  return fetch(API_URL + url, {
    method: 'GET',
    headers: {
      'access-token': localStorage.getItem('access-token'),
      'token-type': localStorage.getItem('token-type'),
      client: localStorage.getItem('client'),
      uid: localStorage.getItem('uid'),
      ...customHeaders,
    },
    ...options,
  })
    .then((res) => res.blob())
    .catch((err) => {
      if (err.status === 401) {
        store.dispatch({ type: 'SET_USER', payload: null });
        localStorage.removeItem('access-token');
        localStorage.removeItem('client');
        localStorage.removeItem('expiry');
        localStorage.removeItem('token-type');
        localStorage.removeItem('uid');
      } else {
        return Promise.reject(err);
      }
    });
}
