const INITIAL_STATE = {
  activeStep: 1,
  root_category: 'offensive',
  name: '',
  retired: 'active',
  splunk: 'not_splunk',
  description_en: '',
  description: '',
  sub_category_ids: [],
  sub_osystem_id: '',
  difficulty_level: '',
  suspend: false,
  snapshot: false,
  tag_list: [],
  vmid: '',
  vlanid_id: '',
  time_to_solve: '1',
  time_to_reready: '2',
  defensive_url: '',
  defensive_username: '',
  defensive_password: '',
  file: '',
  traning_video_ids: [],
  documentation_ids: [],
  vulnability_machine_attributes: {
    vulnability_id: '',
    sub_vulnability_id: '',
  },
  machine_steps_attributes: [],
  thumbnail: '',
};

const machine = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_MACHINE':
      return {
        ...state,
        ...action.payload,
      };
    case 'RESET':
      return {
        activeStep: 1,
        root_category: 'offensive',
        name: '',
        retired: 'active',
        splunk: 'not_splunk',
        description_en: '',
        description: '',
        sub_category_ids: [],
        sub_osystem_id: '',
        difficulty_level: '',
        suspend: false,
        snapshot: false,
        tag_list: [],
        vmid: '',
        vlanid_id: '',
        time_to_solve: '1',
        time_to_reready: '2',
        defensive_url: '',
        defensive_username: '',
        defensive_password: '',
        file: '',
        traning_video_ids: [],
        documentation_ids: [],
        vulnability_machine_attributes: {
          vulnability_id: '',
          sub_vulnability_id: '',
        },
        machine_steps_attributes: [],
        thumbnail: '',
      };
    default:
      return state;
  }
};

export default machine;
