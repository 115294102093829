import { combineReducers } from 'redux';
import auth from './auth';
import machine from './machine';
import machineResource from './machineResource';
import appointment from './appointment';
import machinePlaylist from './machinePlaylist';
import knowledgeBase from './knowledgeBase';
import mitre from './mitre';
import panelSettings from './panel';
import siteConfig from './siteConfig';

const reducer = combineReducers({
  auth,
  machine,
  machineResource,
  appointment,
  machinePlaylist,
  knowledgeBase,
  mitre,
  panelSettings,
  siteConfig,
});

export default reducer;
