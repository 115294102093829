import React from 'react';
import cn from 'classnames';
import styles from '../styles/pages/serverError.module.scss';
import { Link } from 'react-router-dom';

const ServerError = () => {
  return (
    <div className={styles.bg}>
      <div className={styles.container}>
        <div className={styles.content}>
          <span className={styles.notFound}>
            Internal Server Error
          </span>
          <span className={styles.status}>500</span>
          <div className={styles.desc}>
            <Link className={styles.button} to={`/`}>
              Go To Homepage
            </Link>
          </div>
        </div>
        <img
          className={styles['stars']}
          src="/img/errors/stars.svg"
          alt=""
        ></img>
        <img
          className={cn(styles['wave'], styles['wave1'])}
          src="/img/errors/wave.svg"
          alt=""
        ></img>
        <img
          className={cn(styles['wave'], styles['wave2'])}
          src="/img/errors/wave2.svg"
          alt=""
        ></img>
        <img
          className={cn(styles['wave'], styles['wave3'])}
          src="/img/errors/wave3.svg"
          alt=""
        ></img>
        <img
          className={styles['human']}
          src="/img/errors/human.svg"
          alt=""
        ></img>
        <img
          className={styles['satellite']}
          src="/img/errors/satellite.svg"
          alt=""
        ></img>
        <img
          className={styles.logo}
          src="/img/logo.png"
          alt="priviahub"
        ></img>
      </div>
    </div>
  );
};

export default ServerError;
