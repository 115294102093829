import * as React from 'react';

function SvgPie(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.523 12.941h-6.979l4.648 4.648c.177.177.47.192.652.02a7.044 7.044 0 002.151-4.143.47.47 0 00-.472-.525zm-.466-1.906a7.077 7.077 0 00-6.563-6.564.474.474 0 00-.494.477v6.581h6.581a.473.473 0 00.476-.494zm-8.469 1.906V5.962c0-.281-.247-.512-.524-.472a7.052 7.052 0 00-6.06 7.226c.128 3.769 3.373 6.86 7.144 6.813a7.007 7.007 0 003.978-1.296c.232-.164.248-.506.046-.708l-4.584-4.584z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgPie;
