import * as React from 'react';

function SvgTeam(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.777 9.223a2.525 2.525 0 015.049 0v1.136c0 .563-.184 1.083-.496 1.503l2.389 1.505c.55.346.885.951.885 1.602v.439a1.894 1.894 0 01-1.894 1.894H3.893A1.894 1.894 0 012 15.408v-.439c0-.651.334-1.256.885-1.602l1.975-1.244c.372.59.195 1.369-.395 1.741l-.908.571a.633.633 0 00-.295.534v.439c0 .349.283.631.631.631h6.817a.63.63 0 00.631-.631v-.439a.63.63 0 00-.295-.534L8.274 12.69a2.526 2.526 0 01-3.497-2.331V9.223zm1.263 0a1.262 1.262 0 112.524 0v1.136a1.262 1.262 0 11-2.524 0V9.223z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.971 12.391l.222-.14a2.338 2.338 0 01-.461-1.396V9.8a2.345 2.345 0 014.689 0v1.055a2.343 2.343 0 01-3.247 2.164l-.105.066-1.098-.694zm2.196 3.739h3.075a.587.587 0 00.586-.586v-.408a.586.586 0 00-.274-.496l-.843-.531a1.171 1.171 0 01-.367-1.616l1.834 1.155c.512.322.822.884.822 1.488v.408c0 .971-.787 1.758-1.758 1.758h-4.208c.751-.329 1.042-.85 1.133-1.172zm1.082-6.33a1.172 1.172 0 00-2.344 0v1.055a1.172 1.172 0 102.344 0V9.8z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgTeam;
