import * as React from 'react';

function SvgCareer(props) {
  return (
    <svg
      viewBox="0 0 20 24"
      width="1em"
      height="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.857 7.048A2.857 2.857 0 002 9.905v7.238A2.857 2.857 0 004.857 20h10.286A2.857 2.857 0 0018 17.143V9.905a2.857 2.857 0 00-2.857-2.857h-.953v-.19A2.858 2.858 0 0011.333 4H8.666a2.858 2.858 0 00-2.857 2.858v.19h-.952zm3.809-1.143a.953.953 0 00-.952.953v.19h4.571v-.19a.953.953 0 00-.952-.953H8.666zm-4.762 4c0-.526.427-.952.953-.952h10.286c.525 0 .952.426.952.952v2.667H5.047c0 1.052.853 1.905 1.905 1.905h9.143v2.666a.953.953 0 01-.952.953H4.857a.953.953 0 01-.953-.953V9.905z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCareer;
