import * as React from 'react';

function SvgQuestionalternate(props) {
  return (
    <svg
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 5.905a6.095 6.095 0 00-5.599 8.508c.651 1.509.849 3.684-.596 5.129l-.179.176a.952.952 0 11-1.336-1.357l.168-.165c.637-.638.711-1.832.194-3.028a8 8 0 112.807 3.419c.598-.866 1.784-1.047 2.792-.746A6.095 6.095 0 1010 5.905z"
        fill="currentColor"
      />
      <path
        d="M9.631 9.539c-.275 0-.534.034-.778.103a4.447 4.447 0 00-.811.331l-.48-1.291a3.452 3.452 0 011.051-.434c.404-.107.8-.16 1.189-.16.488 0 .888.064 1.2.194.32.129.571.293.754.491.183.198.309.416.377.652.077.236.115.461.115.674 0 .213-.035.411-.103.594a2.266 2.266 0 01-.24.492 3.04 3.04 0 01-.343.434c-.129.137-.255.267-.377.389a9.35 9.35 0 00-.286.297c-.099.099-.19.205-.274.32a2.088 2.088 0 00-.194.365.923.923 0 00-.08.378v.137c0 .061.003.114.011.16H8.853a2.372 2.372 0 01-.034-.252v-.251a1.9 1.9 0 01.08-.572 2.314 2.314 0 01.514-.902c.115-.13.233-.256.355-.378a9.61 9.61 0 00.48-.514.906.906 0 00.217-.594.607.607 0 00-.217-.469c-.137-.129-.343-.194-.617-.194zm1.062 5.749c0 .335-.11.594-.331.777a1.09 1.09 0 01-.731.274c-.275 0-.522-.091-.743-.274-.214-.183-.32-.442-.32-.777 0-.336.106-.595.32-.778.221-.182.468-.274.743-.274.274 0 .518.092.731.274.221.183.331.442.331.778z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgQuestionalternate;
