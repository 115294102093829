import React from 'react';
import cn from 'classnames';
import styles from './spinner.module.scss';
const Spinner = ({
  fullscreen,
  noMargin,
  dimmer,
  removeArea,
  type = 'screen',
  spinnerClass,
}) => {
  return (
    <div
      className={cn(
        dimmer && styles.dimmer,
        !removeArea && styles.spinnerArea,
      )}
    >
      {type === 'button' ? (
        <div
          className={cn(
            styles.loadingSpinner,
            fullscreen && styles.fullscreen,
            noMargin && styles.noMargin,
            spinnerClass,
          )}
        ></div>
      ) : (
        <>
          <div className={cn(styles.imageBox)}>
            <img src="/img/spinnerEagle.png"></img>
            {/* <div className={cn(styles.moveBox)}></div> */}
          </div>
        </>
      )}
    </div>
  );
};

export default Spinner;
