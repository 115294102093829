import * as React from 'react';

function SvgUser(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.19 7.81a3.81 3.81 0 017.619 0v1.714c0 .85-.278 1.635-.749 2.269l3.605 2.269A2.857 2.857 0 0118 16.48v.663A2.858 2.858 0 0115.142 20H4.857A2.857 2.857 0 012 17.143v-.663c0-.982.504-1.895 1.334-2.418l2.982-1.877c.56.891.293 2.067-.597 2.627l-1.37.862a.954.954 0 00-.445.806v.663c0 .526.427.952.953.952h10.285a.953.953 0 00.953-.952v-.663a.954.954 0 00-.445-.806l-4.183-2.633A3.81 3.81 0 016.19 9.524V7.81zm1.905 0a1.905 1.905 0 013.809 0v1.714a1.904 1.904 0 11-3.809 0V7.81z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgUser;
