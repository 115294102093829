import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import styles from '../styles/pages/notFound.module.scss';

const NotFound = () => {
  return (
    <div className={styles.bg}>
      <div className={styles.container}>
        <div className={styles.content}>
          <span className={styles.notFound}>Page Not Found</span>
          <span className={styles.status}>404</span>
          <div className={styles.desc}>
            <span className={styles.text}>
              We can't seem to find the page you're looking for...
            </span>
            <Link className={styles.button} to={`/`}>
              Go To Homepage
            </Link>
          </div>
        </div>
        <img
          className={styles.stars}
          src={'/img/errors/stars.svg'}
          alt="stars"
        ></img>
        <img
          className={cn(styles.wave, styles.wave1)}
          src={'/img/errors/wave.svg'}
          alt="wave"
        ></img>
        <img
          className={cn(styles.wave, styles.wave2)}
          src={'/img/errors/wave2.svg'}
          alt="wave"
        ></img>
        <img
          className={cn(styles.wave, styles.wave3)}
          src={'/img/errors/wave3.svg'}
          alt="wave"
        ></img>
        <img
          className={cn(styles.astronaut, styles.astronaut1)}
          src={'/img/errors/astronaut1.svg'}
          alt="astronaut"
        ></img>
        <img
          className={cn(styles.astronaut, styles.astronaut2)}
          src={'/img/errors/astronaut2.svg'}
          alt="astronaut"
        ></img>
        <img
          className={cn(styles.astronaut, styles.astronaut3)}
          src={'/img/errors/astronaut3.svg'}
          alt="astronaut"
        ></img>

        <img
          className={styles.logo}
          src="/img/logo.png"
          alt="priviahub"
        ></img>
      </div>
    </div>
  );
};

export default NotFound;
