import * as React from 'react';

function SvgPlus(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 11V4h2v7h7v2h-7v7H9v-7H2v-2h7z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={2}
      />
    </svg>
  );
}

export default SvgPlus;
