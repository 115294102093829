import * as React from 'react';

function SvgBell(props) {
  return (
    <svg
      viewBox="0 0 20 24"
      width="1em"
      height="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.638 16.452l-1.038-.996a3.527 3.527 0 01-1.1-2.544V9.6A7.015 7.015 0 0016 5.28l-1.125-1.44a3.724 3.724 0 00-1.323-1.06 3.879 3.879 0 00-1.677-.38h-.625V.6a.588.588 0 00-.183-.424.64.64 0 00-.442-.176h-1.25a.636.636 0 00-.442.176A.588.588 0 008.75.6v1.8h-.625c-.582 0-1.157.13-1.677.38-.521.25-.974.613-1.323 1.06L4 5.28A7.01 7.01 0 002.5 9.6v3.312a3.535 3.535 0 01-1.1 2.544l-1.037.996a1.17 1.17 0 00-.363.84V18c0 .318.132.623.366.849.234.225.552.351.884.351h17.5c.331 0 .649-.126.883-.351.235-.226.367-.531.367-.849v-.708a1.18 1.18 0 00-.362-.84zM15 16.8H5V9.6c0-1.038.351-2.05 1-2.88l1.125-1.44c.116-.149.267-.27.441-.354.173-.083.365-.126.559-.126h3.75c.193 0 .385.043.558.126.175.084.325.205.442.354L14 6.72c.648.83 1 1.842 1 2.88v7.2zm-7.5 4.8h5c0 .636-.264 1.247-.732 1.697-.47.45-1.105.703-1.768.703a2.555 2.555 0 01-1.768-.703A2.352 2.352 0 017.5 21.6z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgBell;
