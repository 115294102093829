import * as React from 'react';

function SvgSupport(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.272 9.462C1.565 8.58 2.193 6 4.114 6H15.5A2.5 2.5 0 0118 8.5v7a2.5 2.5 0 01-2.5 2.5H4.499A2.499 2.499 0 012 15.5v-5.167c.92 0 1.667.747 1.667 1.667v3.5c0 .461.372.833.832.833H15.5c.46 0 .833-.372.833-.833v-7a.833.833 0 00-.833-.833H4.114c-.066 0-.093.018-.107.03a.184.184 0 00-.055.097.18.18 0 00.005.111c.007.017.022.046.081.076l5.508 2.846c.26.134.57.123.819-.03l2.822-1.737a.833.833 0 11.873 1.42l-2.821 1.736a2.497 2.497 0 01-2.458.092L3.272 9.462z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgSupport;
