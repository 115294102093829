import * as React from 'react';

function SvgThumbsUp(props) {
  return (
    <svg
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        d="M2 19.04h2.909v-8.448H2v8.448zm16-7.744c0-.774-.655-1.408-1.455-1.408h-4.589l.691-3.217.022-.226c0-.288-.124-.556-.32-.746l-.771-.739-4.785 4.639a1.358 1.358 0 00-.429.993v7.04c0 .774.654 1.408 1.454 1.408h6.546c.603 0 1.12-.352 1.338-.859l2.196-4.963c.066-.162.102-.331.102-.514v-1.408z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgThumbsUp;
