const INITIAL_STATE = {
  video_id: '',
  documents: null,
  scenario: false,
  thrust_point: false,
  open_machines: [],
};

const machineResource = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'MACHINE_RESOURCE':
      return {
        ...state,
        ...action.payload,
      };
    case 'MACHINE_RESOURCE_RESET':
      return {
        ...state,
        video_id: '',
        documents: null,
        scenario: false,
        thrust_point: false,
      };
    default:
      return state;
  }
};

export default machineResource;
