import * as React from 'react';

function SvgConfirm(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={13}
        cy={13}
        r={12}
        fill="currentColor"
        fillOpacity={0.1}
        stroke="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.365 13.335L6 14.57l3.459 4.108a.884.884 0 001.317.053L20 9.34 18.73 8l-8.538 8.693-2.827-3.358z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgConfirm;
