import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { Link } from 'react-router-dom';
import { Form, Image } from 'semantic-ui-react';
import { Button } from '../components/Button';
import cn from 'classnames';
import styles from '../styles/pages/login.module.scss';
import { toast } from 'react-toastify';
import { signup } from '../actions/user';

function Register() {
  const width = useRef(0);
  const counter = useRef(0);
  const [refresh, setRefresh] = useState(0);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password_confirmation, setPasswordConfirmation] = useState(
    '',
  );
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(
    false,
  );
  const [privacy, setPrivacy] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const register = useCallback(
    (e) => {
      e.preventDefault();
      if (
        !username ||
        !password ||
        !email ||
        !password_confirmation
      ) {
        toast.error('Please fill all fields');
        return;
      }

      signup(
        { username, password, password_confirmation, email },
        setSuccess,
        setLoading,
      );
    },
    [email, password, password_confirmation, username],
  );

  useEffect(() => {
    const interval = setInterval(() => {
      width.current += 1;
      setRefresh(width.current);
      if (width.current == 100) {
        width.current = 1;
        counter.current = (counter.current + 1) % 3;
      }
    }, 60);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className={cn(styles['loginPage'])}>
        <div className={cn(styles['left-area'])}>
          <div className={cn(styles['text-area'])}>
            <div className={cn(styles['logo-area'])}>
              <Image src="/img/logo-white.svg"></Image>
            </div>
            <h1>Your Opening Door to the Cyber World</h1>
            <div className={cn(styles['progress-area'])}>
              <div className={cn(styles['progress-bar'])}>
                <div
                  className={cn(styles['active'])}
                  style={{ width: width.current + '%' }}
                ></div>
              </div>
              <div className={cn(styles['progress-text'])}>
                0<span>{counter.current + 1}</span> - 03
              </div>
            </div>
          </div>
          <div className={cn(styles['img-area'])}>
            <Image
              src="/img/login/8.png"
              alt="Cyber Range Platform | Cyber Range Portal"
              className={cn(
                'img-1',
                styles[
                  ('img', counter.current === 0 ? 'active' : '')
                ],
              )}
            />
            <Image
              src="/img/login/9.png"
              alt="Cyber Range Platform | Cyber Range Portal"
              className={cn(
                'img-2',
                styles[
                  ('img', counter.current === 1 ? 'active' : '')
                ],
              )}
            />
            <Image
              src="/img/login/10.png"
              alt="Cyber Range Platform | Cyber Range Portal"
              className={cn(
                'img-3',
                styles[
                  ('img', counter.current === 2 ? 'active' : '')
                ],
              )}
            />
          </div>
        </div>
        <div className={cn(styles['right-area'])}>
          <div className={cn(styles['logo-area'], styles['signup'])}>
            <Image src="/img/logo.svg"></Image>
            <p>Cyber Range Platform</p>
          </div>
          <div className={cn(styles['content-area'])}>
            {!success ? (
              <>
                <Form
                  className={cn('ui form', styles.form)}
                  autoComplete="off"
                  onSubmit={register}
                >
                  <Form.Field>
                    <Form.Input
                      icon="user"
                      iconPosition="left"
                      placeholder="Username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      required
                    ></Form.Input>
                  </Form.Field>
                  <Form.Field>
                    <Form.Input
                      icon="mail"
                      iconPosition="left"
                      placeholder="Email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    ></Form.Input>
                  </Form.Field>
                  <Form.Field>
                    <Form.Input
                      type={showPassword ? 'text' : 'password'}
                      icon="lock"
                      iconPosition="left"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    ></Form.Input>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        setShowPassword(!showPassword);
                      }}
                    >
                      <i
                        className={cn(
                          showPassword
                            ? 'eye icon'
                            : 'eye slash icon',
                          styles.eyeIcon,
                        )}
                      ></i>
                    </a>
                  </Form.Field>

                  <Form.Field>
                    <Form.Input
                      type={showPasswordConfirm ? 'text' : 'password'}
                      icon="lock"
                      iconPosition="left"
                      placeholder="Password Confirm"
                      value={password_confirmation}
                      onChange={(e) =>
                        setPasswordConfirmation(e.target.value)
                      }
                      required
                    ></Form.Input>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        setShowPasswordConfirm(!showPasswordConfirm);
                      }}
                    >
                      <i
                        className={cn(
                          showPasswordConfirm
                            ? 'eye icon'
                            : 'eye slash icon',
                          styles.eyeIcon,
                        )}
                      ></i>
                    </a>
                  </Form.Field>
                  <Form.Field className={styles.privacy}>
                    <input
                      id="privacy"
                      type="checkbox"
                      value={privacy}
                      onChange={() => setPrivacy(!privacy)}
                      style={{ display: 'inline-block' }}
                      required
                    ></input>
                    <label htmlFor="privacy">
                      I consent to sending commercial messages to my
                      contact information within the scope of the{' '}
                      <a
                        href="https://priviahub.com/information_security_policy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Data Security Policy
                      </a>{' '}
                      and the processing of my personal data within
                      the scope of the{' '}
                      <a
                        href="https://priviahub.com/clarification_text"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Clarification Text.
                      </a>
                    </label>
                  </Form.Field>
                  <Button type="submit" loading={loading}>
                    Sign Up
                  </Button>
                </Form>
                <div
                  className={cn('divider-1', styles['divider-1'])}
                ></div>
                <div className={cn(styles['sub-link'])}>
                  <Link to="/auth/reset-password">
                    Forgot Password
                  </Link>
                  <Link to="/auth/signin">
                    Already have an account?
                  </Link>
                </div>
              </>
            ) : (
              <>
                <span>
                  A verification link has been sent to your email
                  account
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
