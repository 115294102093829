import * as React from 'react';

function SvgMission(props) {
  return (
    <svg
      viewBox="0 0 20 24"
      width="1em"
      height="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.973 3.148H14v-.793a.814.814 0 00-.811-.817H6.81a.813.813 0 00-.81.817v.793H4.027A2.038 2.038 0 002 5.192v15.226c0 1.127.91 2.043 2.027 2.043h11.946A2.037 2.037 0 0018 20.418V5.192c0-1.127-.91-2.044-2.027-2.044zm-3.595.024v1.586H7.621V3.172h4.757zm4 17.246a.408.408 0 01-.405.409H4.027a.407.407 0 01-.405-.409V5.192c0-.226.181-.409.405-.409h1.972v.793c0 .451.363.817.811.817h6.379A.814.814 0 0014 5.576v-.793h1.973c.223 0 .405.183.405.409v15.226zm-2.616-9.337a.821.821 0 010 1.156l-4.26 4.295a.808.808 0 01-1.148 0l-2.116-2.135a.821.821 0 010-1.156.808.808 0 011.146 0l1.544 1.557 3.687-3.717a.807.807 0 011.147 0z"
        strokeWidth={0.5}
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  );
}

export default SvgMission;
