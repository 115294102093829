import * as React from 'react';

function SvgKnowledgeBase(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 7.517c-.46 0-.833.389-.833.869v6.26c0 .481.373.87.833.87h2.717c.673 0 1.334.136 1.95.397V9.256c.92 0 1.666.778 1.666 1.739v4.918a4.992 4.992 0 011.95-.397H15.5c.46 0 .833-.389.833-.87v-6.26c0-.48-.373-.869-.833-.869h-2.717c-.761 0-1.501.257-2.109.733a1.085 1.085 0 01-1.348 0 3.418 3.418 0 00-2.109-.733H4.5zM2 8.386c0-1.44 1.119-2.608 2.5-2.608h2.717c.989 0 1.954.295 2.783.846a5.023 5.023 0 012.783-.846H15.5c1.381 0 2.5 1.168 2.5 2.608v6.26c0 1.441-1.119 2.609-2.5 2.609h-2.717c-.761 0-1.501.257-2.109.733a1.085 1.085 0 01-1.348 0 3.418 3.418 0 00-2.109-.733H4.5c-1.381 0-2.5-1.168-2.5-2.609v-6.26z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgKnowledgeBase;
