import * as React from 'react';

function SvgCursor(props) {
  return (
    <svg
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.12 5.945A.953.953 0 003.945 7.12l2.53 9.048c.245.878 1.464.944 1.803.098l.606-1.515a.952.952 0 111.77.708l-.606 1.515c-1.016 2.539-4.673 2.341-5.41-.292L2.109 7.633c-.601-2.147 1.377-4.125 3.524-3.524l9.173 2.564c2.614.731 2.836 4.351.33 5.394l-.473.198 2.426 2.425a3.112 3.112 0 010 4.399 3.112 3.112 0 01-4.399 0l-1.907-1.907a1.907 1.907 0 012.697 0l.558.559a1.205 1.205 0 001.703-1.703l-3.03-3.029a1.335 1.335 0 01.431-2.176l1.261-.525c.835-.348.761-1.555-.11-1.799L5.12 5.945z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCursor;
