import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { reSendMail, resetPassword } from '../actions/user';
import { Form, Image } from 'semantic-ui-react';
import { Button } from '../components/Button';
import cn from 'classnames';
import styles from '../styles/pages/login.module.scss';

function Login() {
  const width = useRef(0);
  const counter = useRef(0);
  const [, setRefresh] = useState(0);
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const submit = (e) => {
    e.preventDefault();
    location.pathname === '/auth/reset-password'
      ? resetPassword(email, setSuccess, setLoading)
      : reSendMail(email, setSuccess, setLoading);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      width.current += 1;
      setRefresh(width.current);
      if (width.current === 100) {
        width.current = 1;
        counter.current = (counter.current + 1) % 3;
      }
    }, 60);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className={cn(styles['loginPage'])}>
        <div className={cn(styles['left-area'])}>
          <div className={cn(styles['text-area'])}>
            <div className={cn(styles['logo-area'])}>
              <Image src="/img/logo-white.svg"></Image>
            </div>
            <h1>Your Opening Door to the Cyber World</h1>
            <div className={cn(styles['progress-area'])}>
              <div className={cn(styles['progress-bar'])}>
                <div
                  className={cn(styles['active'])}
                  style={{ width: width.current + '%' }}
                ></div>
              </div>
              <div className={cn(styles['progress-text'])}>
                0<span>{counter.current + 1}</span> - 03
              </div>
            </div>
          </div>
          <div className={cn(styles['img-area'])}>
            <Image
              src="/img/login/8.png"
              alt="Cyber Range Platform | Cyber Range Portal"
              className={cn(
                'img-1',
                styles[
                  ('img', counter.current === 0 ? 'active' : '')
                ],
              )}
            />
            <Image
              src="/img/login/9.png"
              alt="Cyber Range Platform | Cyber Range Portal"
              className={cn(
                'img-2',
                styles[
                  ('img', counter.current === 1 ? 'active' : '')
                ],
              )}
            />
            <Image
              src="/img/login/10.png"
              alt="Cyber Range Platform | Cyber Range Portal"
              className={cn(
                'img-3',
                styles[
                  ('img', counter.current === 2 ? 'active' : '')
                ],
              )}
            />
          </div>
        </div>
        <div className={cn(styles['right-area'])}>
          <div className={cn(styles['logo-area'])}>
            <Image src="/img/logo.svg"></Image>
            <p>Cyber Range Platform</p>
          </div>
          <div className={cn(styles['content-area'])}>
            {!success ? (
              <>
                <h2>
                  {location.pathname === '/auth/reset-password'
                    ? 'Reset Password'
                    : 'Resend Activation Mail'}
                </h2>
                <Form
                  className={styles.form}
                  autoComplete="off"
                  onSubmit={submit}
                >
                  <Form.Field>
                    <Form.Input
                      icon="mail"
                      iconPosition="left"
                      placeholder="Email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    ></Form.Input>
                  </Form.Field>
                  <Button loading={loading} type="submit">
                    Send
                  </Button>
                </Form>
                <div
                  className={cn('divider-1', styles['divider-1'])}
                ></div>
                <div className={cn(styles['sub-link'])}>
                  <Link to="/auth/signin">
                    Already have an account?
                  </Link>
                  <Link to="/auth/signup">Sign up for new user</Link>
                </div>
              </>
            ) : (
              <>
                <span>
                  {location.pathname === '/auth/reset-password'
                    ? `An email has been sent to ${email} containing
                    instructions for resetting your password.`
                    : 'A verification link has been sent to your email account'}
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
