import React from 'react';
import cn from 'classnames';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Spinner from '../Spinner';
import './button.scss';

const Button = React.memo(function Button({
  is = 'button',
  href,
  color = 'classic',
  className,
  loading,
  noSpinner,
  children,
  ...props
}) {
  if (href) {
    return (
      <Link
        to={href}
        className={cn('ui button hub-button', color, className)}
      >
        {children}
      </Link>
    );
  }

  return React.createElement(
    is,
    {
      className: cn('ui button hub-button ', color, className),
      disabled: loading,
      ...props,
    },
    loading && !noSpinner ? <Spinner type="button" removeArea/> : children,
  );
});

const ButtonWithIcon = React.memo(function ButtonWithIcon({
  is = 'a',
  color = 'classic',
  className = '',
  icon = '',
  children,
  ...props
}) {
  return React.createElement(
    is,
    {
      className: cn(
        'ui button hub-button with-icon',
        color,
        className,
      ),
      ...props,
    },
    <>
      {icon && <Icon name={icon} />}
      {children}
    </>,
  );
});

export { Button, ButtonWithIcon };
