import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import global_tr from './translations/tr/global.json';
import global_en from './translations/en/global.json';
import sidebar_tr from './translations/tr/sidebar.json';
import sidebar_en from './translations/en/sidebar.json';
import homepage_tr from './translations/tr/homepage.json';
import homepage_en from './translations/en/homepage.json';
import machines_show_tr from './translations/tr/machines/show/language.json';
import machines_show_en from './translations/en/machines/show/language.json';
import hubnews_tr from './translations/tr/hubnews.json';
import hubnews_en from './translations/en/hubnews.json';
import scenarios_tr from './translations/tr/scenarios.json';
import scenarios_en from './translations/en/scenarios.json';
import ctflist_tr from './translations/tr/ctflist.json';
import ctflist_en from './translations/en/ctflist.json';
import events_tr from './translations/tr/events.json';
import events_en from './translations/en/events.json';
import machinelist_tr from './translations/tr/machinelist.json';
import machinelist_en from './translations/en/machinelist.json';
import quizes_tr from './translations/tr/quizes.json';
import quizes_en from './translations/en/quizes.json';
import scoreboard_tr from './translations/tr/scoreboard.json';
import scoreboard_en from './translations/en/scoreboard.json';
import team_tr from './translations/tr/team.json';
import team_en from './translations/en/team.json';
import teamDetail_tr from './translations/tr/teamDetail.json';
import teamDetail_en from './translations/en/teamDetail.json';
import playlist_tr from './translations/tr/EducationModule/playlist.json';
import playlist_en from './translations/en/EducationModule/playlist.json';
import videolist_tr from './translations/tr/EducationModule/videolist.json';
import videolist_en from './translations/en/EducationModule/videolist.json';
import knowledgebase_tr from './translations/tr/Help/knowledgebase.json';
import knowledgebase_en from './translations/en/Help/knowledgebase.json';
import simulation_tr from './translations/tr/simulation.json';
import simulation_en from './translations/en/simulation.json';
import menu_tr from './translations/tr/menu.json';
import menu_en from './translations/en/menu.json';
import machine_information_tr from './translations/tr/machines/machine_information.json';
import machine_information_en from './translations/en/machines/machine_information.json';
import machine_body_tr from './translations/tr/machines/machine_body.json';
import machine_body_en from './translations/en/machines/machine_body.json';
import tasks_tr from './translations/tr/tasks.json';
import tasks_en from './translations/en/tasks.json';
import profile_tr from './translations/tr/profile.json';
import profile_en from './translations/en/profile.json';
import support_tr from './translations/tr/support.json';
import support_en from './translations/en/support.json';
import plus_tr from './translations/tr/plus.json';
import plus_en from './translations/en/plus.json';
import study_tr from './translations/tr/study.json';
import study_en from './translations/en/study.json';
import lifeArea_tr from './translations/tr/lifeArea.json';
import lifeArea_en from './translations/en/lifeArea.json';
import rankingSystem_tr from './translations/tr/AdminPanel/rankingSystem.json';
import rankingSystem_en from './translations/en/AdminPanel/rankingSystem.json';
import feedbacks_tr from './translations/tr/AdminPanel/feedbacks.json';
import feedbacks_en from './translations/en/AdminPanel/feedbacks.json';
import commadnList_tr from './translations/tr/AdminPanel/commandList.json';
import commadnList_en from './translations/en/AdminPanel/commandList.json';
import fileManagement_tr from './translations/tr/AdminPanel/fileManagment.json';
import fileManagement_en from './translations/en/AdminPanel/fileManagment.json';
import mitre_tr from './translations/tr/AdminPanel/mitre.json';
import mitre_en from './translations/en/AdminPanel/mitre.json';
import wiki_tr from './translations/tr/AdminPanel/wiki.json';
import wiki_en from './translations/en/AdminPanel/wiki.json';
import proxmox_tr from './translations/tr/InfrastructureSettings/proxmox.json';
import proxmox_en from './translations/en/InfrastructureSettings/proxmox.json';
import firewall_tr from './translations/tr/InfrastructureSettings/firewall.json';
import firewall_en from './translations/en/InfrastructureSettings/firewall.json';
import smtp_tr from './translations/tr/InfrastructureSettings/smtp.json';
import smtp_en from './translations/en/InfrastructureSettings/smtp.json';
import telegram_tr from './translations/tr/InfrastructureSettings/telegram.json';
import telegram_en from './translations/en/InfrastructureSettings/telegram.json';
import systemMachines_tr from './translations/tr/InfrastructureSettings/systemMachines.json';
import systemMachines_en from './translations/en/InfrastructureSettings/systemMachines.json';
import metaTags_tr from './translations/tr/GeneralSettings/metaTags.json';
import metaTags_en from './translations/en/GeneralSettings/metaTags.json';
import panelSettings_tr from './translations/tr/GeneralSettings/panelSettings.json';
import panelSettings_en from './translations/en/GeneralSettings/panelSettings.json';
import userList_tr from './translations/tr/AdminPanel/userList.json';
import userList_en from './translations/en/AdminPanel/userList.json';
import notifications_tr from './translations/tr/GeneralSettings/notifications.json';
import notifications_en from './translations/en/GeneralSettings/notifications.json';
import home_tr from './translations/tr/home.json';
import home_en from './translations/en/home.json';
import operatingSystemsList_tr from './translations/tr/machines/operatingSystemsList.json';
import operatingSystemsList_en from './translations/en/machines/operatingSystemsList.json';
import vlanDidList_tr from './translations/tr/machines/vlanDidList.json';
import vlanDidList_en from './translations/en/machines/vlanDidList.json';
import addNewMachine_tr from './translations/tr/machines/addNewMachine.json';
import addNewMachine_en from './translations/en/machines/addNewMachine.json';
import machineCategories_tr from './translations/tr/machines/machineCategories.json';
import machineCategories_en from './translations/en/machines/machineCategories.json';
import linkedMachines_tr from './translations/tr/machines/linkedMachines.json';
import linkedMachines_en from './translations/en/machines/linkedMachines.json';
import machineStatus_tr from './translations/tr/machines/machineStatus.json';
import machineStatus_en from './translations/en/machines/machineStatus.json';
import ipAddressList_tr from './translations/tr/machines/ipAddressList.json';
import ipAddressList_en from './translations/en/machines/ipAddressList.json';
import categoryCTFBased_tr from './translations/tr/Reports/categoryCTFBased.json';
import categoryCTFBased_en from './translations/en/Reports/categoryCTFBased.json';
import ctfReport_en from './translations/en/Reports/ctfReport.json';
import ctfReport_tr from './translations/tr/Reports/ctfReport.json';
import specificCtfReport_tr from './translations/tr/Reports/specificCtfReport.json';
import specificCtfReport_en from './translations/en/Reports/specificCtfReport.json';
import quizReport_tr from './translations/tr/Reports/quizReport.json';
import quizReport_en from './translations/en/Reports/quizReport.json';
import specificQuizReport_en from './translations/en/Reports/specificQuizReport.json';
import specificQuizReport_tr from './translations/tr/Reports/specificQuizReport.json';
import machineReport_tr from './translations/tr/Reports/machineReports.json';
import machineReport_en from './translations/en/Reports/machineReports.json';
import categoryMachineBased_tr from './translations/tr/Reports/categoryMachineBased.json';
import categoryMachineBased_en from './translations/en/Reports/categoryMachineBased.json';
import educationReport_tr from './translations/tr/Reports/education.json';
import educationReport_en from './translations/en/Reports/education.json';
import teamReport_tr from './translations/tr/Reports/teamReports.json';
import teamReport_en from './translations/en/Reports/teamReports.json';
import vpnConnections_tr from './translations/tr/vpnConnections.json';
import vpnConnections_en from './translations/en/vpnConnections.json';
import general_tr from './translations/tr/Reports/general.json';
import general_en from './translations/en/Reports/general.json';
import machineSolution_tr from './translations/tr/Reports/machineSolution.json';
import machineSolution_en from './translations/en/Reports/machineSolution.json';
import machine_loading_tr from './translations/tr/machines/machineLoading.json';
import machine_loading_en from './translations/en/machines/machineLoading.json';
import welcomeModal_tr from './translations/tr/welcomeModal.json';
import welcomeModal_en from './translations/en/welcomeModal.json';
import userCompare_tr from './translations/tr/Reports/userCompare.json';
import userCompare_en from './translations/en/Reports/userCompare.json';
import ticket_tr from './translations/tr/Help/support.json';
import ticket_en from './translations/en/Help/support.json';
import contributors_tr from './translations/tr/AdminPanel/contributors.json';
import contributors_en from './translations/en/AdminPanel/contributors.json';
import quizForm_tr from './translations/tr/quizForm.json';
import quizForm_en from './translations/en/quizForm.json';
import feedbackForm_tr from './translations/tr/feedbackForm.json';
import feedbackForm_en from './translations/en/feedbackForm.json';
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      tr: {
        global: global_tr,
        sidebar: sidebar_tr,
        homepage: homepage_tr,
        machines_show: machines_show_tr,
        hubnews: hubnews_tr,
        scenarios: scenarios_tr,
        ctflist: ctflist_tr,
        evets: events_tr,
        machinelist: machinelist_tr,
        quizes: quizes_tr,
        scoreboard: scoreboard_tr,
        team: team_tr,
        teamDetail: teamDetail_tr,
        playlist: playlist_tr,
        videolist: videolist_tr,
        knowledgebase: knowledgebase_tr,
        simulation: simulation_tr,
        menu: menu_tr,
        machine_information: machine_information_tr,
        machine_body: machine_body_tr,
        tasks: tasks_tr,
        profile: profile_tr,
        support: support_tr,
        plus: plus_tr,
        study: study_tr,
        lifeArea: lifeArea_tr,
        userList: userList_tr,
        rankingSystem: rankingSystem_tr,
        feedbacks: feedbacks_tr,
        commandList: commadnList_tr,
        fileManagement: fileManagement_tr,
        mitre: mitre_tr,
        wiki: wiki_tr,
        proxmox: proxmox_tr,
        firewall: firewall_tr,
        smtp: smtp_tr,
        telegram: telegram_tr,
        systemMachines: systemMachines_tr,
        metaTags: metaTags_tr,
        panelSettings: panelSettings_tr,
        notifications: notifications_tr,
        home: home_tr,
        welcomeModal: welcomeModal_tr,
        operatingSystemsList: operatingSystemsList_tr,
        vlanDidList: vlanDidList_tr,
        addNewMachine: addNewMachine_tr,
        machineCategories: machineCategories_tr,
        linkedMachines: linkedMachines_tr,
        machineStatus: machineStatus_tr,
        ipAddressList: ipAddressList_tr,
        categoryCTFBased: categoryCTFBased_tr,
        ctfReport: ctfReport_tr,
        specificCtfReport: specificCtfReport_tr,
        quizReport: quizReport_tr,
        specificQuizReport: specificQuizReport_tr,
        machineReport: machineReport_tr,
        categoryMachineBased: categoryMachineBased_tr,
        education: educationReport_tr,
        teamReport: teamReport_tr,
        vpnConnections: vpnConnections_tr,
        general: general_tr,
        machineSolution: machineSolution_tr,
        machine_loading: machine_loading_tr,
        userCompare: userCompare_tr,
        ticket: ticket_tr,
        contributors: contributors_tr,
        quizForm: quizForm_tr,
        feedbackForm: feedbackForm_tr,
      },
      en: {
        global: global_en,
        sidebar: sidebar_en,
        homepage: homepage_en,
        machines_show: machines_show_en,
        hubnews: hubnews_en,
        scenarios: scenarios_en,
        ctflist: ctflist_en,
        events: events_en,
        machinelist: machinelist_en,
        quizes: quizes_en,
        scoreboard: scoreboard_en,
        team: team_en,
        teamDetail: teamDetail_en,
        playlist: playlist_en,
        videolist: videolist_en,
        knowledgebase: knowledgebase_en,
        simulation: simulation_en,
        menu: menu_en,
        machine_information: machine_information_en,
        machine_body: machine_body_en,
        tasks: tasks_en,
        profile: profile_en,
        support: support_en,
        plus: plus_en,
        study: study_en,
        lifeArea: lifeArea_en,
        userList: userList_en,
        rankingSystem: rankingSystem_en,
        feedbacks: feedbacks_en,
        commandList: commadnList_en,
        fileManagement: fileManagement_en,
        mitre: mitre_en,
        wiki: wiki_en,
        proxmox: proxmox_en,
        firewall: firewall_en,
        smtp: smtp_en,
        telegram: telegram_en,
        systemMachines: systemMachines_en,
        metaTags: metaTags_en,
        panelSettings: panelSettings_en,
        notifications: notifications_en,
        home: home_en,
        welcomeModal: welcomeModal_en,
        operatingSystemsList: operatingSystemsList_en,
        vlanDidList: vlanDidList_en,
        addNewMachine: addNewMachine_en,
        machineCategories: machineCategories_en,
        linkedMachines: linkedMachines_en,
        machineStatus: machineStatus_en,
        ipAddressList: ipAddressList_en,
        categoryCTFBased: categoryCTFBased_en,
        ctfReport: ctfReport_en,
        specificCtfReport: specificCtfReport_en,
        quizReport: quizReport_en,
        specificQuizReport: specificQuizReport_en,
        machineReport: machineReport_en,
        categoryMachineBased: categoryMachineBased_en,
        education: educationReport_en,
        teamReport: teamReport_en,
        vpnConnections: vpnConnections_en,
        general: general_en,
        machineSolution: machineSolution_en,
        machine_loading: machine_loading_en,
        userCompare: userCompare_en,
        ticket: ticket_en,
        contributors: contributors_en,
        quizForm: quizForm_en,
        feedbackForm: feedbackForm_en,
      },
    },
    lng: 'en',
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
