import * as React from 'react';

function SvgUndo(props) {
  return (
    <svg
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        d="M11.119 4.126c4.498 0 8.096 3.747 7.871 8.319-.225 3.974-3.561 7.234-7.533 7.422-2.924.112-5.509-1.35-6.933-3.599.824-.524 1.911-.449 2.623.225 1.161 1.05 2.736 1.612 4.422 1.5 2.998-.225 5.397-2.699 5.547-5.697.149-3.448-2.587-6.297-5.997-6.297-3.186 0-5.771 2.474-5.996 5.585l.6-.6c.337-.338.899-.412 1.273-.113a.937.937 0 01.076 1.388l-1.987 1.986c-.524.525-1.349.525-1.836 0L1.3 12.296c-.337-.338-.412-.899-.112-1.274a.934.934 0 011.386-.075l.675.675c.188-4.16 3.635-7.496 7.87-7.496z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgUndo;
