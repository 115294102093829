import * as React from 'react';

function SvgOs(props) {
  return (
    <svg
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      {...props}
    >
      <circle
        cx={10}
        cy={12}
        r={9}
        fill="none"
        paintOrder="fill"
        stroke="currentColor"
      />
      <path
        d="M6.2 12c0 .284.034.545.105.779.069.231.17.428.3.594a1.429 1.429 0 001.132.509c.234 0 .447-.045.638-.135.19-.09.35-.214.48-.374.135-.166.237-.363.307-.594.069-.234.105-.495.105-.779 0-.284-.036-.542-.105-.773a1.617 1.617 0 00-.307-.591 1.316 1.316 0 00-.48-.383 1.48 1.48 0 00-.638-.135c-.24 0-.455.045-.645.135-.19.09-.352.217-.487.383-.13.163-.231.361-.3.591A2.628 2.628 0 006.2 12zm4.057 0c0 .445-.068.838-.203 1.178-.13.339-.31.625-.54.855-.225.225-.49.394-.794.51-.306.115-.63.171-.975.171-.346 0-.674-.056-.982-.171a2.301 2.301 0 01-.804-.51 2.56 2.56 0 01-.54-.855A3.178 3.178 0 015.217 12c0-.445.067-.835.202-1.17a2.5 2.5 0 01.548-.855c.235-.23.505-.402.81-.517.305-.115.627-.172.968-.172.339 0 .662.057.967.172.304.115.571.287.795.517.23.23.412.516.547.855.135.335.203.725.203 1.17zm2.568 1.898c.34 0 .589-.058.743-.174a.57.57 0 00.233-.486.601.601 0 00-.084-.324.686.686 0 00-.225-.239 1.442 1.442 0 00-.352-.202 4.969 4.969 0 00-.48-.189 8.823 8.823 0 01-.525-.208 2.04 2.04 0 01-.443-.293 1.45 1.45 0 01-.307-.413 1.347 1.347 0 01-.112-.577c0-.471.163-.838.487-1.102.325-.27.768-.405 1.327-.405.327 0 .613.037.863.112.256.069.456.147.6.231l-.293.765a2.218 2.218 0 00-.562-.216 2.5 2.5 0 00-.622-.076c-.256 0-.455.053-.6.157a.529.529 0 00-.21.444c0 .114.022.215.067.299a.852.852 0 00.203.219c.09.064.192.124.307.18.12.054.251.106.39.157.245.09.463.182.653.276.194.09.357.201.487.332.135.124.238.274.307.45.071.17.106.377.106.622 0 .47-.168.835-.503 1.094-.33.256-.815.382-1.455.382-.215 0-.413-.014-.593-.045a3.41 3.41 0 01-.824-.216c-.1-.045-.18-.085-.24-.121l.277-.771c.135.073.318.149.548.225.23.073.507.112.832.112z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgOs;
