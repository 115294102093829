import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { toast } from 'react-toastify';

import Layout from '../components/Layout';

const LayoutRoutes = ({
  roles,
  component: Component,
  layout,
  unrestricted,
  ...rest
}) => {
  const auth = useSelector((state) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (unrestricted) {
          return (
            <Layout unrestricted layout={layout}>
              <Component {...props} />
            </Layout>
          );
        } else {
          if (!auth.user) {
            return <Redirect to="/auth/signin" />;
          } else if (roles && roles.indexOf(auth.user.role) === -1) {
            toast.error('Bu sayfayı görmeye yetkin yok!');
            return <Redirect to="/" />;
          } else
            return (
              <Layout layout={layout}>
                <Component {...props} />
              </Layout>
            );
        }
      }}
    />
  );
};

export default LayoutRoutes;
