import * as React from 'react';

function SvgPlay(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 3.712c0-1.375 1.743-2.153 2.952-1.319l12.533 8.665c.576.398.685 1.142.245 1.662-.44.52-1.263.619-1.838.222L4.623 5.152v13.696l7.39-5.109c1.151-.796 2.797-.597 3.677.443L4.952 21.605C3.743 22.441 2 21.662 2 20.287V3.712z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgPlay;
