import * as React from 'react';

function SvgDifficulty(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.091 13c.502 0 .909-.438.909-.978s-.407-.978-.909-.978h-.182V4.979c0-.541-.407-.979-.909-.979s-.909.438-.909.979v6.065h-.182c-.502 0-.909.438-.909.978s.407.978.909.978h2.182zM15 19.118V14c1.105 0 2 .791 2 1.765v3.353c0 .487-.448.882-1 .882s-1-.395-1-.882zM11.091 10c.502 0 .909-.419.909-.937s-.407-.938-.909-.938h-.182V4.938C10.909 4.42 10.502 4 10 4s-.909.42-.909.938v3.187h-.182c-.502 0-.909.42-.909.938s.407.937.909.937h2.182zM9 19.063V11c1.105 0 2 .84 2 1.875v6.188c0 .518-.448.937-1 .937s-1-.419-1-.937zM5.091 15c.502 0 .909-.439.909-.982 0-.542-.407-.982-.909-.982h-.182V4.983C4.909 4.44 4.502 4 4 4s-.909.44-.909.983v8.053h-.182c-.502 0-.909.44-.909.982 0 .543.407.982.909.982h2.182zM3 19.167V16c1.105 0 2 .747 2 1.667v1.5c0 .46-.448.833-1 .833s-1-.373-1-.833z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgDifficulty;
