import * as React from 'react';

function SvgTag(props) {
  return (
    <svg
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 6.862A2.863 2.863 0 014.862 4h4.552c.759 0 1.487.301 2.023.838l5.725 5.724a2.864 2.864 0 010 4.048l-4.552 4.552a2.864 2.864 0 01-4.048 0l-5.519-5.52a1.908 1.908 0 012.698 0l4.17 4.17a.954.954 0 001.35 0l4.551-4.551a.954.954 0 000-1.35l-5.724-5.723a.947.947 0 00-.674-.28H4.862a.954.954 0 00-.954.954v4.388a.955.955 0 11-1.908 0V6.862z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgTag;
