import * as React from 'react';

function SvgCalender(props) {
  return (
    <svg
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 8a2.857 2.857 0 012.857-2.857h.952v-.19a.953.953 0 111.905 0v.19h4.571v-.19a.953.953 0 011.905 0v.19h.952A2.858 2.858 0 0118 8v9.143A2.858 2.858 0 0115.142 20H4.857A2.857 2.857 0 012 17.143V8zm1.904 0c0-.525.427-.952.953-.952h10.285c.526 0 .953.427.953.952v.953H5.047c0 1.052.853 1.905 1.905 1.905h9.143v6.285a.953.953 0 01-.953.953H4.857a.953.953 0 01-.953-.953V8z"
        fill="#75797E"
      />
    </svg>
  );
}

export default SvgCalender;
