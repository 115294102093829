import * as React from 'react';

function SvgTrophy(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <clipPath id="trophy_svg__a">
          <path fill="currentColor" d="M0 0h41v41H0z" />
        </clipPath>
      </defs>
      <g
        clipPath="url(#trophy_svg__a)"
        transform="translate(.977 2.977) scale(.44016)"
        fill="currentColor"
      >
        <path d="M24.905 33.792h-8.81v2.403h8.81v-2.403z" />
        <path
          d="M36.093 4.879c.069-.749.105-1.497.105-2.243V1.65l-.967-.192a75.14 75.14 0 00-29.462 0l-.967.192v.986c0 .746.036 1.494.105 2.243H.053l.166 1.348c.502 4.069 1.815 8.022 3.902 11.751.15.27.308.541.466.807a6.017 6.017 0 005.15 2.913h3.159a23.664 23.664 0 004 3.197v4.091h-5.607v9.611H8.486V41h24.028v-2.403H29.71v-9.61h-5.606v-4.092a23.67 23.67 0 004-3.197h3.16a6.018 6.018 0 005.149-2.913c.158-.265.315-.537.466-.807 2.087-3.729 3.4-7.683 3.902-11.75l.166-1.35h-4.854zM9.736 19.295a3.604 3.604 0 01-3.085-1.74 31.485 31.485 0 01-.433-.75c-1.702-3.042-2.848-6.239-3.415-9.523h2.443c.543 2.858 1.578 5.685 3.068 8.35a28.12 28.12 0 002.44 3.663H9.737zM27.308 31.39v7.208H13.692V31.39h13.616zm-5.015-8.203l-.592.349v5.371H19.3v-5.371l-.592-.349C12.21 19.36 7.592 11.426 7.228 3.625a72.7 72.7 0 0126.544 0c-.364 7.8-4.982 15.735-11.479 19.561zm12.49-6.38c-.141.25-.287.502-.434.748a3.604 3.604 0 01-3.085 1.741h-1.019a28.13 28.13 0 002.44-3.663c1.492-2.665 2.526-5.492 3.069-8.35h2.443c-.567 3.284-1.713 6.481-3.415 9.523z"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}

export default SvgTrophy;
