import * as React from 'react';

function SvgSimulation(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <clipPath id="simulation_svg__a">
          <path fill="#fff" d="M0 0h39v39H0z" />
        </clipPath>
      </defs>
      <g
        clipPath="url(#simulation_svg__a)"
        transform="translate(1.998 3.998) scale(.41037)"
        fill="currentColor"
      >
        <path d="M5.717 0h-4.57C.518 0 .007.511.007 1.142v4.645a1.142 1.142 0 102.284 0V2.285h3.427a1.142 1.142 0 100-2.285zM37.852 0h-4.569a1.142 1.142 0 000 2.285h3.427v3.502a1.142 1.142 0 102.285 0V1.142c0-.63-.512-1.142-1.143-1.142zM5.717 36.705H2.29v-3.503a1.142 1.142 0 10-2.285 0v4.645c0 .63.512 1.142 1.143 1.142h4.569a1.142 1.142 0 000-2.284zM37.852 32.06c-.63 0-1.142.51-1.142 1.142v3.503h-3.427a1.142 1.142 0 000 2.284h4.57c.63 0 1.142-.511 1.142-1.142v-4.645c0-.631-.512-1.143-1.143-1.143zM35.981 9.37L20.073.155a1.142 1.142 0 00-1.146 0L3.012 9.371c-.35.203-.57.582-.57.987v18.283c0 .41.223.793.582.995l15.903 9.21c.172.1.376.154.573.154.206 0 .408-.058.573-.154l15.897-9.21.008-.005c.368-.266.567-.49.572-.977V10.365l.001-.006c0-.407-.219-.785-.57-.988zM18.358 35.877l-12.48-7.23 9.621-5.517a1.142 1.142 0 10-1.136-1.982L4.727 26.67V12.333l13.63 7.828v15.715zM19.5 18.183L5.877 10.355 19.5 2.462l13.623 7.893L19.5 18.183zm14.774 8.486l-9.637-5.522a1.142 1.142 0 10-1.136 1.982l9.62 5.517-12.479 7.23V20.16l13.632-7.828v14.336z" />
      </g>
    </svg>
  );
}

export default SvgSimulation;
