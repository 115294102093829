import cn from 'classnames';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Image } from 'semantic-ui-react';
import { _fetchJson } from '../../libs/fetch';
import styles from './machineArea.module.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

function MachineArea() {
  const dispatch = useDispatch();
  const { open_machines } = useSelector(
    (state) => state.machineResource,
  );

  useEffect(() => {
    _fetchJson(`/user_open_machines`)
      .then((res) => {
        dispatch({
          type: 'MACHINE_RESOURCE',
          payload: { open_machines: res.open_machines },
        });
      })
      .catch(() => null);
  }, [dispatch]);

  const { t } = useTranslation('global');
  return (
    <div className={styles['machine-area']}>
      <Dropdown
        text={`${open_machines.length} ${t('global.machine')}`}
        icon="circle"
        floating
        className={cn(
          styles['machineDropdown'],
          open_machines.length > 0 ? styles.green : styles.red,
          'active-machine-dropdown',
        )}
      >
        <Dropdown.Menu className={styles['menu']}>
          {open_machines.map((mac, i) => (
            <Link
              key={i}
              to={`/machines/${mac.name}`}
              className={styles['machine-link']}
            >
              <div className={styles['img-area']}>
                <Image src={mac.thumbnail}></Image>
              </div>
              <div className={styles['machine-detail']}>
                <p>{mac.name}</p>
                <p className={styles['machine-ip']}>
                  {mac.ip_address}
                </p>
              </div>
            </Link>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default MachineArea;
