import * as React from 'react';

function SvgLock(props) {
  return (
    <svg
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 13.313a2.811 2.811 0 012.811-2.811H13V7.876a3 3 0 10-6 0 1.875 1.875 0 01-1.876 1.875V7.876A4.878 4.878 0 019.999 3a4.877 4.877 0 014.877 4.876v2.783a2.818 2.818 0 011.874 2.654v4.876A2.812 2.812 0 0113.939 21H6.061a2.812 2.812 0 01-2.811-2.811v-4.876zm1.874 0a.94.94 0 01.937-.938h7.878a.94.94 0 01.937.938v4.876c0 .516-.42.936-.937.936H6.061a.937.937 0 01-.937-.936v-4.876z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgLock;
