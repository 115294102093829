const INITIAL_STATE = [];

const machinePlaylist = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_MACHINE_PLAYLIST':
      return [...action.payload];
    default:
      return state;
  }
};

export default machinePlaylist;
