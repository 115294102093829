import * as React from 'react';

function SvgGem(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.34 5.906a.95.95 0 00-.801.441l-1.17 1.847h8.524a.95.95 0 01.949.953.951.951 0 01-.949.953H3.331c-1.048 0-1.684-1.163-1.121-2.051l1.727-2.725A2.845 2.845 0 016.34 4h7.409c.974 0 1.88.499 2.403 1.324l1.403 2.214a2.869 2.869 0 01-.103 3.221l-6.325 8.694a1.326 1.326 0 01-2.168-.031l-5.628-8.21a1.893 1.893 0 012.64.49l4.097 5.975 5.851-8.042a.957.957 0 00.034-1.074L14.55 6.347a.95.95 0 00-.801-.441H6.34z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgGem;
