import * as React from 'react';

function SvgConnection(props) {
  return (
    <svg
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      {...props}
    >
      <g xmlns="http://www.w3.org/2000/svg" fill="currentColor">
        <path
          d="M7.43 11.824a.303.303 0 00-.303-.303h-.781v-.98h1.431c.75 0 1.362-.612 1.362-1.363V5.352c0-.751-.611-1.362-1.362-1.362H2.362C1.61 3.99 1 4.6 1 5.352v3.826c0 .751.61 1.362 1.362 1.362h1.43v.981h-.78a.303.303 0 100 .606h4.115a.303.303 0 00.303-.303zM1.908 9.178V5.352c0-.25.203-.454.454-.454h5.415c.25 0 .454.203.454.454v3.826c0 .25-.204.454-.454.454H2.362a.454.454 0 01-.454-.454zM5.74 10.54v.92H4.398v-.92h1.343zM17.638 11.873h-5.415c-.751 0-1.362.611-1.362 1.362v3.827c0 .751.611 1.362 1.362 1.362h1.431v.981h-.78a.303.303 0 100 .605h4.114a.303.303 0 100-.605h-.78v-.981h1.43c.751 0 1.362-.611 1.362-1.362v-3.827c0-.75-.61-1.362-1.362-1.362zm-2.036 7.471h-1.343v-.92h1.343v.92zm2.49-2.282c0 .25-.203.454-.454.454h-5.415a.454.454 0 01-.454-.454v-3.827c0-.25.204-.454.454-.454h5.415c.25 0 .454.204.454.454v3.827zM14.723 10.769a.454.454 0 10.822-.386c-.835-1.779-2.78-3.07-5.074-3.37a.454.454 0 00-.118.9c1.99.26 3.665 1.354 4.37 2.856zM9.431 16.778c-2.576 0-4.823-1.379-5.465-3.352a.454.454 0 00-.864.28c.762 2.343 3.365 3.98 6.33 3.98a.454.454 0 100-.908z"
          data-original="#000002"
        />
      </g>
    </svg>
  );
}

export default SvgConnection;
