import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { login as loginAction } from '../actions/user';
import { Image } from 'semantic-ui-react';
import { Button } from '../components/Button';
import cn from 'classnames';
import styles from '../styles/pages/login.module.scss';
import { toast } from 'react-toastify';

function Login() {
  const width = useRef(0);
  const counter = useRef(0);
  const [, setRefresh] = useState(0);
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const query = new URLSearchParams(useLocation().search);
  const login = useCallback(
    (e) => {
      e.preventDefault();

      if (!email && !password) {
        return;
      }

      dispatch(loginAction(email, password, setLoading));
    },
    [email, password, dispatch],
  );

  useEffect(() => {
    const confirm = query.get('account_confirmation_success');
    if (confirm === 'true') {
      toast.success('Your account has been activated successfully!');
    } else if (confirm === 'false') {
      toast.error('Email already confirmed, please try signing in.');
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      width.current += 1;
      setRefresh(width.current);
      if (width.current === 100) {
        width.current = 1;
        counter.current = (counter.current + 1) % 3;
      }
    }, 60);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className={cn(styles['loginPage'])}>
        <div className={cn(styles['left-area'])}>
          <div className={cn(styles['text-area'])}>
            <div className={cn(styles['logo-area'])}>
              <Image src="/img/logo-white.svg"></Image>
            </div>
            <h1>Your Opening Door to the Cyber World</h1>
            <div className={cn(styles['desc-area'])}>
              <div
                className={cn(
                  styles['desc'],
                  styles['desc-1'],
                  styles[counter.current === 0 ? 'active' : ''],
                )}
              >
                Your Own Isolated Live Machines
              </div>
              <div
                className={cn(
                  styles['desc'],
                  styles['desc-2'],
                  styles[counter.current === 1 ? 'active' : ''],
                )}
              >
                No Hardware or Software Need
              </div>
              <div
                className={cn(
                  styles['desc'],
                  styles['desc-3'],
                  styles[counter.current === 2 ? 'active' : ''],
                )}
              >
                Reach from all around the world
              </div>
            </div>
            <div className={cn(styles['progress-area'])}>
              <div className={cn(styles['progress-bar'])}>
                <div
                  className={cn(styles['active'])}
                  style={{ width: width.current + '%' }}
                ></div>
              </div>
              <div className={cn(styles['progress-text'])}>
                0<span>{counter.current + 1}</span> - 03
              </div>
            </div>
          </div>
          <div className={cn(styles['img-area'])}>
            <Image
              src="/img/login/8.png"
              alt="Cyber Range Platform | Cyber Range Portal"
              className={cn(
                'img-1',
                styles[
                  ('img', counter.current === 0 ? 'active' : '')
                ],
              )}
            />
            <Image
              src="/img/login/9.png"
              alt="Cyber Range Platform | Cyber Range Portal"
              className={cn(
                'img-2',
                styles[
                  ('img', counter.current === 1 ? 'active' : '')
                ],
              )}
            />
            <Image
              src="/img/login/10.png"
              alt="Cyber Range Platform | Cyber Range Portal"
              className={cn(
                'img-3',
                styles[
                  ('img', counter.current === 2 ? 'active' : '')
                ],
              )}
            />
          </div>
        </div>
        <div className={cn(styles['right-area'])}>
          <div className={cn(styles['logo-area'])}>
            <Image src="/img/logo.svg"></Image>
            <p>Cyber Range Platform</p>
          </div>
          <div className={cn(styles['content-area'])}>
            <h2>Sign in to your account</h2>
            <form
              className={cn('ui form', styles.form)}
              autoComplete="off"
              onSubmit={login}
            >
              <div className="field">
                <div className="ui input left icon">
                  <i className={cn('mail', 'icon')}></i>
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    type="text"
                    required
                  />
                </div>
              </div>
              <div className="field">
                <div className={cn('ui', 'input', 'left', 'icon')}>
                  <i className="lock icon"></i>
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    type={showPassword ? 'text' : 'password'}
                    required
                  />
                </div>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    setShowPassword(!showPassword);
                  }}
                >
                  <i
                    className={cn(
                      showPassword ? 'eye icon' : 'eye slash icon',
                      styles.eyeIcon,
                    )}
                  ></i>
                </a>
              </div>
              <Button loading={loading} type="submit">
                Sign in
              </Button>
            </form>
            <div
              className={cn('divider-1', styles['divider-1'])}
            ></div>
            <div className={cn(styles['sub-link'])}>
              <Link to="/auth/reset-password">Forgot Password</Link>
              <Link to="/auth/signup">Sign up for new user</Link>
              <Link to="/auth/confirmation">
                Resend Activation Mail
              </Link>
            </div>
            {/* <div class={cn(styles['right-footer'])}>
              <a
                href="https://priviahub.com/privacy_policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              <a
                href="https://priviahub.com/privacy_policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Use
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
