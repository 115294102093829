import * as React from 'react';

function SvgSecurity(props) {
  return (
    <svg
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        d="M17.218 5.885L14.08 2.747a1.773 1.773 0 00-1.262-.525H7.182a1.779 1.779 0 00-1.262.525L2.782 5.885A2.667 2.667 0 002 7.769v5.2a7.114 7.114 0 002.747 5.609l3.626 2.827c.313.242.698.374 1.094.373h1.066c.396.001.781-.131 1.094-.373l3.626-2.827A7.104 7.104 0 0018 12.969v-5.2a2.667 2.667 0 00-.782-1.884zm-.996 7.111A5.276 5.276 0 0114.16 17.2L10.533 20H10v-8H3.778V7.769a.888.888 0 01.257-.622L7.182 4H10v8h6.222v.996z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgSecurity;
