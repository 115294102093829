import React from 'react';
import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';
import { store } from '..';

export const setMachine = (payload) => (dispatch) => {
  dispatch({
    type: 'SET_MACHINE',
    payload,
  });
};

export const resetState = () => (dispatch) => {
  dispatch({ type: 'RESET' });
};

export const onMachineClose = (machine) => (dispatch) => {
  const open_machines = store.getState().machineResource
    .open_machines;

  const new_machines = open_machines.filter(
    (m) => m.name !== machine.name,
  );
  dispatch({
    type: 'MACHINE_RESOURCE',
    payload: { open_machines: new_machines },
  });

  toast.info(
    <Trans
      i18nKey="machine_information:left_content.machine_time_up"
      values={{ machine_name: machine.name }}
    ></Trans>,
  );
};
