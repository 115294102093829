import React, { useEffect } from 'react';
import Modal from '../Modal';
import { useHistory } from 'react-router';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import './lifeArea.scss';
import * as SvgIcon from '../icons';

function LifeArea({ setOnboardingEnabled, isOpen, setIsOpen }) {
  const panelSettings = useSelector((state) => state.panelSettings);
  const history = useHistory();
  const { t } = useTranslation('lifeArea');

  useEffect(() => {
    const unlisten = history.listen(() => {
      setIsOpen(false);
      setOnboardingEnabled(false);
    });

    return () => unlisten();
  }, []);

  return (
    <Modal
      size="tiny"
      className="help-modal right-modal"
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      title={t('help_and_feedback')}
      desc={t('help_desc')}
      trigger={
        <a className="life-area">
          <SvgIcon.QuestionAlternate style = {{fontSize: "32px", color: "var(--icon)"}}></SvgIcon.QuestionAlternate>
          {/* <Icon name="question circle" /> */}
        </a>
      }
      closeButton={false}
    >
      <div className="cancel" onClick={() => setIsOpen(false)}>
        <Icon name="times" />
      </div>
      <a
        className="cd-tour-trigger"
        onClick={() => setOnboardingEnabled('layout')}
      >
        <div className="img-area">
          <img src="/img/tour-wizard.svg" alt="Question" />
        </div>
        <div className="content">
          <div className="item-name">{t('page_info1')}</div>
          {t('page_desc1')}
        </div>
      </a>
      <a className="take-tour" onClick={() => setOnboardingEnabled()}>
        <div className="img-area">
          <img src="/img/page-about.svg" alt="about" />
        </div>
        <div className="content">
          <div className="item-name">{t('page_info')}</div>
          {t('page_desc')}
        </div>
      </a>
      <Link to="/knowledge-base">
        <div className="img-area">
          <img src="/img/knowledge-base.svg" alt="Knowledge Base" />
        </div>
        <div className="content">
          <div className="item-name">{t('kb_title')}</div>
          {t('kb_desc')}
        </div>
      </Link>
      <a
        href="https://priviahub.com/changelogs"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="img-area">
          <img src="/img/updates.svg" alt="Update" />
        </div>
        <div className="content">
          <div className="item-name">{t('changelog_title')}</div>
          {t('changelog_desc')}
        </div>
      </a>
      <a
        href={panelSettings.discord_link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="img-area">
          <img src="/img/discord.svg" alt="Discord Logo" />
        </div>
        <div className="content">
          <div className="item-name">
            {t('discord_title')}{' '}
            <i className="external alternate icon"></i>
          </div>
          {t('discord_desc')}
        </div>
      </a>
      <div className="copyright-area">
        <a
          href={panelSettings.web_site}
          target="_blank"
          rel="noopener noreferrer"
        >
          © {panelSettings.copright}
        </a>
      </div>
    </Modal>
  );
}

export default LifeArea;
