import * as React from 'react';

function SvgPower(props) {
  return (
    <svg
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.283 5.997a.952.952 0 01-.344 1.302 6.095 6.095 0 106.122 0 .952.952 0 11.959-1.646A8 8 0 0110 20.571 8 8 0 015.98 5.653a.952.952 0 011.303.344z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.047 3.428c1.052 0 1.905.853 1.905 1.905v7.428a.953.953 0 11-1.905 0V3.428z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgPower;
