import * as React from 'react';

function SvgTeamAlternate(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.471 8.528a3.156 3.156 0 016.312 0v1.42a3.14 3.14 0 01-.62 1.879l2.986 1.881a2.367 2.367 0 011.106 2.002v.549a2.368 2.368 0 01-2.367 2.368H2.366A2.367 2.367 0 010 16.259v-.549c0-.813.418-1.57 1.106-2.002l2.469-1.555a1.577 1.577 0 01-.494 2.176l-1.135.714a.791.791 0 00-.368.667v.549c0 .436.353.789.788.789h8.522a.788.788 0 00.788-.789v-.549a.787.787 0 00-.368-.667l-3.465-2.181a3.158 3.158 0 01-4.372-2.914v-1.42zm1.579 0a1.578 1.578 0 013.155 0v1.42a1.577 1.577 0 11-3.155 0v-1.42z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.214 12.488l.277-.175a2.924 2.924 0 01-.576-1.745V9.249a2.931 2.931 0 015.861 0v1.319a2.929 2.929 0 01-4.058 2.705l-.132.082-1.372-.867zm2.745 4.674h3.844a.734.734 0 00.732-.733v-.51a.734.734 0 00-.342-.62l-1.054-.664a1.465 1.465 0 01-.459-2.02l2.293 1.444A2.197 2.197 0 0120 15.919v.51a2.198 2.198 0 01-2.197 2.198h-5.26c.938-.412 1.302-1.063 1.416-1.465zm1.352-7.913a1.465 1.465 0 00-2.93 0v1.319a1.464 1.464 0 102.93 0V9.249z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgTeamAlternate;
