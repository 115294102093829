const INITIAL_STATE = { theme: 'dark', tour: false };

const siteConfig = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_TOUR':
      return {
        ...state,
        tour: action.payload,
      };
    case 'SET_THEME':
      return {
        ...state,
        theme: action.payload,
      };
    default:
      return state;
  }
};

export default siteConfig;
