import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { _fetchJson } from '../../../libs/fetch';
import styles from './chatCheckbox.module.scss';
import ChatScreen from './ChatScreen';

const ChatArea = () => {
  const [checked, setChecked] = useState(true);
  const [messages, setMessages] = useState([]);

  const onReceived = (message) => {
    setMessages((prevState) => [...prevState, message]);
  };

  useLayoutEffect(() => {
    const checked = localStorage.getItem('chat');
    if (checked === 'false') {
      setChecked(false);
    }
  }, []);

  useEffect(() => {
    _fetchJson(`/messages`)
      .then((res) => {
        if (res.status) {
          setMessages(res.messages);
        } else {
          toast.error('An error occured while getting messages');
        }
      })
      .catch((err) => toast.error(err.error))
      .finally(() => null);
  }, []);

  const setChatStatus = () => {
    localStorage.setItem('chat', !checked);
    setChecked(!checked);
  };

  return (
    <div>
      <ActionCableConsumer
        channel={{
          channel: 'ChatChannel',
        }}
        onReceived={onReceived}
      />
      <label className={styles.switch}>
        <input
          type="checkbox"
          checked={checked}
          onChange={() => setChatStatus()}
        />
        <span className={styles.slider}></span>
      </label>
      {checked ? (
        <ChatScreen messages={messages} setMessages={setMessages} />
      ) : null}
    </div>
  );
};

export default ChatArea;
