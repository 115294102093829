import * as React from 'react';

function SvgFlag(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.944 3.938c0-.518-.435-.938-.972-.938C3.435 3 3 3.42 3 3.938v16.125c0 .518.435.937.972.937.537 0 .972-.419.972-.937V6h9.139c.537 0 .973.42.973.938v5.625c0 .518-.436.937-.973.937H7.667c-1.074 0-1.945.84-1.945 1.875h8.361c1.611 0 2.917-1.259 2.917-2.812V6.938c0-1.553-1.306-2.813-2.917-2.813H4.944v-.187z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgFlag;
