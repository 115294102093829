import React, { lazy } from 'react';
import LayoutRoutes from './LayoutRoutes';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import NotFound from '../pages/NotFound';
import ServerError from '../pages/ServerError';
import Spinner from '../components/Spinner';
import Login from '../pages/Login';
import Register from '../pages/Register';
import ResendMail from '../pages/ResendMail';
import ResetPassword from '../pages/ResetPassword';
import Invitation from '../pages/Invitation';

const HomePage = lazy(() => import('../pages/Home'));
const MachineListPage = lazy(() => import('../pages/MachineList'));
const MachineDetailPage = lazy(() =>
  import('../pages/MachineDetail'),
);
const OperatingSystemsPage = lazy(() =>
  import('../pages/OperatingSystems'),
);
const DistributionsPage = lazy(() =>
  import('../pages/Distributions'),
);
const VlanIdsPage = lazy(() => import('../pages/VlanIds'));
const VlanIdsFormPage = lazy(() => import('../pages/VlanidsForm'));
const HubNewsPage = lazy(() => import('../pages/HubNews'));
const UsersPage = lazy(() => import('../pages/Users'));

const RanksPage = lazy(() => import('../pages/Ranks'));

const FeedbacksPage = lazy(() => import('../pages/Feedbacks'));
const CtfListPage = lazy(() => import('../pages/CtfList'));
const CtfDetailPage = lazy(() => import('../pages/CtfDetail'));
const AddCtfPage = lazy(() => import('../pages/AddCtf'));
const PanelSettingsPage = lazy(() =>
  import('../pages/PanelSettings'),
);
const ProfilePage = lazy(() => import('../pages/Profile'));
const EditProfilePage = lazy(() => import('../pages/EditProfile'));
const MachineCategoriesPage = lazy(() =>
  import('../pages/MachineCategories'),
);
const MachineSubCategoriesPage = lazy(() =>
  import('../pages/MachineSubCategories'),
);
const NewMachinePage = lazy(() => import('../pages/NewMachine'));
const NotificationsPage = lazy(() =>
  import('../pages/Notifications'),
);
const MetaTagsPage = lazy(() => import('../pages/MetaTags'));

const CommandListPage = lazy(() => import('../pages/CommandList'));
const KBCommandListPage = lazy(() =>
  import('../pages/KnowledgeBase/CommandList'),
);
const TeamsPage = lazy(() => import('../pages/Teams'));
const TeamDetailPage = lazy(() => import('../pages/TeamDetail'));
const ScoreboardPage = lazy(() => import('../pages/Scoreboard'));
const ScenariosPage = lazy(() =>
  import('../pages/Scenarios/Scenarios'),
);
const ScenarioDetailPage = lazy(() =>
  import('../pages/Scenarios/ScenarioDetail'),
);
const ScenarioEditorPage = lazy(() =>
  import('../pages/Scenarios/ScenarioEditor'),
);
const KnowledgeBasePage = lazy(() =>
  import('../pages/KnowledgeBase/index'),
);
const SearchResultPage = lazy(() =>
  import('../pages/KnowledgeBase/SearchResult'),
);
const TicketListPage = lazy(() => import('../pages/TicketList'));
const TicketDetailPage = lazy(() => import('../pages/TicketDetail'));
const QuestionListPage = lazy(() => import('../pages/QuestionList'));
const QuizQuestionFormPage = lazy(() =>
  import('../pages/QuizQuestionForm'),
);
const Exams = lazy(() => import('../pages/ExamList'));
const ExamPage = lazy(() => import('../pages/Exam'));
const ExamDetailPage = lazy(() => import('../pages/ExamDetail'));
const ExamResultPage = lazy(() => import('../pages/ExamResult'));
const ExamCategoriesPage = lazy(() =>
  import('../pages/ExamCategories'),
);
const QuestionAssignmentPage = lazy(() =>
  import('../pages/QuestionAssignment'),
);
const VideoListPage = lazy(() => import('../pages/VideoList'));
const AddVideoPage = lazy(() => import('../pages/AddVideo'));
const PlaylistPage = lazy(() => import('../pages/Playlist'));
const PlaylistWatchPage = lazy(() =>
  import('../pages/PlaylistWatch'),
);
const AddPlaylistPage = lazy(() => import('../pages/AddPlaylist'));
const UserReportPage = lazy(() =>
  import('../pages/Reports/UserReport'),
);
const UserComparePage = lazy(() =>
  import('../pages/Reports/UserCompareReport'),
);
const MachineReportPage = lazy(() =>
  import('../pages/Reports/MachineReport'),
);
const CtfReportPage = lazy(() =>
  import('../pages/Reports/CtfReport'),
);
const EducationReportPage = lazy(() =>
  import('../pages/Reports/EducationReport'),
);
const CtfCategoryReportPage = lazy(() =>
  import('../pages/Reports/CtfCategoryReport'),
);
const MachineCategoryReportPage = lazy(() =>
  import('../pages/Reports/MachineCategoryReport'),
);
const QuizReportPage = lazy(() =>
  import('../pages/Reports/QuizReport'),
);
const TeamReportPage = lazy(() =>
  import('../pages/Reports/TeamReport'),
);
const TeamComparePage = lazy(() =>
  import('../pages/Reports/TeamCompareReport'),
);
const FileManagementPage = lazy(() =>
  import('../pages/FileManagement'),
);
const FolderDetailPage = lazy(() => import('../pages/FolderDetail'));
const FolderListPage = lazy(() => import('../pages/FolderList'));
const FileListPage = lazy(() => import('../pages/FileList'));
const EventDetail = lazy(() => import('../pages/EventDetail'));
const LinkedMachinesPage = lazy(() =>
  import('../pages/LinkedMachines'),
);
const AppointmentsPage = lazy(() => import('../pages/Appointments'));
const SMTPSettingsPage = lazy(() => import('../pages/SMTPSettings'));
const TelegramSettingsPage = lazy(() =>
  import('../pages/TelegramSettings'),
);
const ProxmoxSystemMachinesPage = lazy(() =>
  import('../pages/ProxmoxSystemMachines'),
);
const ProxmoxSettingsPage = lazy(() =>
  import('../pages/ProxmoxSettings'),
);
const ProxmoxFormPage = lazy(() => import('../pages/ProxmoxForm'));
const ProxmoxDetailPage = lazy(() =>
  import('../pages/ProxmoxDetail'),
);
const FirewallListPage = lazy(() => import('../pages/FirewallList'));
const FirewallFormPage = lazy(() => import('../pages/FirewallForm'));
const FirewallDetailPage = lazy(() =>
  import('../pages/FirewallDetail'),
);
const TacticsPage = lazy(() => import('../pages/Tactics'));
const KBTacticsPage = lazy(() =>
  import('../pages/KnowledgeBase/Tactics'),
);
const TacticDetailPage = lazy(() => import('../pages/TacticDetail'));
const KBTacticDetailPage = lazy(() =>
  import('../pages/KnowledgeBase/TacticDetail'),
);
const TechniqueDetailPage = lazy(() =>
  import('../pages/TechniqueDetail'),
);
const KBTechniqueDetailPage = lazy(() =>
  import('../pages/KnowledgeBase/TechniqueDetail'),
);
const SubTechniqueDetailPage = lazy(() =>
  import('../pages/SubTechniqueDetail'),
);
const KBSubTechniqueDetailPage = lazy(() =>
  import('../pages/KnowledgeBase/SubTechniqueDetail'),
);
const WikisPage = lazy(() => import('../pages/Wikis'));
const WikiDetailPage = lazy(() => import('../pages/WikiDetail'));
const KBWikiDetailPage = lazy(() =>
  import('../pages/KnowledgeBase/WikiDetail'),
);
const WikiFormPage = lazy(() => import('../pages/WikiForm'));
const WikiCategoriesPage = lazy(() =>
  import('../pages/WikiCategories'),
);
const SimulationPage = lazy(() => import('../pages/Simulation'));
const ContributorsPage = lazy(() => import('../pages/Contributors'));
const ContributorFormPage = lazy(() =>
  import('../pages/ContributorForm'),
);
const VpnConnectionsPage = lazy(() =>
  import('../pages/VpnConnections'),
);

function AuthorizedRoutes() {
  const { user, isLoading } = useSelector((state) => state.auth);

  if (isLoading) {
    return <Spinner fullscreen />;
  }

  return (
    <Router>
      <Switch>
        <Route
          path="/auth/signin"
          render={() => {
            if (user) return <Redirect to="/"></Redirect>;
            return <Login></Login>;
          }}
        />
        <Route
          path="/auth/signup"
          render={() => {
            if (user) return <Redirect to="/"></Redirect>;
            return <Register></Register>;
          }}
        />
        <Route
          path="/auth/reset-password"
          render={() => {
            if (user) return <Redirect to="/"></Redirect>;
            return <ResendMail></ResendMail>;
          }}
        />
        <Route
          path="/auth/password/edit"
          render={() => {
            if (user) return <Redirect to="/"></Redirect>;
            return <ResetPassword></ResetPassword>;
          }}
        />
        <Route
          path="/auth/confirmation"
          render={() => {
            if (user) return <Redirect to="/"></Redirect>;
            return <ResendMail></ResendMail>;
          }}
        />
        <Route
          path="/auth/invitation/accept"
          render={() => {
            if (user) return <Redirect to="/"></Redirect>;
            return <Invitation></Invitation>;
          }}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/operating-systems"
          component={OperatingSystemsPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/sub-operating-systems"
          component={DistributionsPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/vlanids"
          component={VlanIdsPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/vlanids/new"
          component={VlanIdsFormPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/vlanids/:id/edit"
          component={VlanIdsFormPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/categories"
          component={MachineCategoriesPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/sub-categories"
          component={MachineSubCategoriesPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/linked-machines"
          component={LinkedMachinesPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/appointments"
          component={AppointmentsPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/users"
          component={UsersPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/ranks"
          component={RanksPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/feedbacks"
          component={FeedbacksPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/panel-settings"
          component={PanelSettingsPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/challenges/new"
          component={AddCtfPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/challenges/:name/edit"
          component={AddCtfPage}
        />
        <LayoutRoutes
          exact
          path="/notifications"
          component={NotificationsPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/meta-tags"
          component={MetaTagsPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/commands"
          component={CommandListPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/scenarios/new"
          component={ScenarioEditorPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/scenarios/:id/edit"
          component={ScenarioEditorPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/quiz-questions"
          component={QuestionListPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/quiz-questions/:id/edit"
          component={QuizQuestionFormPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/quiz-questions/new"
          component={QuizQuestionFormPage}
        />
        <LayoutRoutes exact path="/quizzes" component={Exams} />
        <LayoutRoutes
          exact
          path="/quizzes/:id"
          layout="quiz"
          component={ExamPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/quiz-categories"
          component={ExamCategoriesPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/quizzes/:id/detail"
          component={ExamDetailPage}
        />
        <LayoutRoutes
          exact
          path="/quizzes/:id/result"
          component={ExamResultPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/quizzes/:id/question_assignment"
          component={QuestionAssignmentPage}
        />
        <LayoutRoutes
          exact
          path="/videos"
          component={VideoListPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/videos/:id/edit"
          component={AddVideoPage}
        />
        <LayoutRoutes
          exact
          path="/videos/:category_id/:id"
          component={PlaylistWatchPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/videos/new"
          component={AddVideoPage}
        />
        <LayoutRoutes
          exact
          path="/playlists"
          component={PlaylistPage}
        />
        <LayoutRoutes
          exact
          path="/playlists/:id/edit"
          component={AddPlaylistPage}
        />
        <LayoutRoutes
          exact
          path="/playlists/new"
          component={AddPlaylistPage}
        />
        <LayoutRoutes
          exact
          path="/playlists/:category_id/:id"
          component={PlaylistWatchPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/file-management"
          component={FileManagementPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/folders"
          component={FolderListPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/folders/:id"
          component={FolderDetailPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/files"
          component={FileListPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/proxmox-settings"
          component={ProxmoxSettingsPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/proxmox-settings/:id/edit"
          component={ProxmoxFormPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/proxmox-settings/new"
          component={ProxmoxFormPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/proxmox-settings/:id"
          component={ProxmoxDetailPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/smtp-settings"
          component={SMTPSettingsPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/telegram-settings"
          component={TelegramSettingsPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/proxmox-system-machines"
          component={ProxmoxSystemMachinesPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/firewall-settings/new"
          component={FirewallFormPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/firewall-settings/:id/edit"
          component={FirewallFormPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/firewall-settings/:id"
          component={FirewallDetailPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/firewall-settings"
          component={FirewallListPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/mitre"
          component={TacticsPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/mitre/tactics/:mitre_id"
          component={TacticDetailPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/mitre/techniques/:mitre_id"
          component={TechniqueDetailPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/mitre/techniques/:technique_id/:sub_technique_id"
          component={SubTechniqueDetailPage}
        />
        <LayoutRoutes
          roles={['system_admin']}
          exact
          path="/wikis"
          component={WikisPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/wikis/new"
          component={WikiFormPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/wikis/categories"
          component={WikiCategoriesPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/wikis/:id"
          component={WikiDetailPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/wikis/:id/edit"
          component={WikiFormPage}
        />
        <LayoutRoutes
          layout="step-form"
          roles={['system_admin']}
          path="/machines/:type(ready-machine|simulation-machine)/new"
          component={NewMachinePage}
        />
        <LayoutRoutes
          layout="step-form"
          roles={['system_admin']}
          path="/machines/:name/edit"
          component={NewMachinePage}
        />
        <LayoutRoutes exact path="/" component={HomePage} />
        <LayoutRoutes
          exact
          path="/machines"
          component={MachineListPage}
        />
        <LayoutRoutes
          layout="machine"
          exact
          path="/machines/:name"
          component={MachineDetailPage}
        />
        <LayoutRoutes
          layout="machine"
          exact
          path="/machines/:name/task"
          component={MachineDetailPage}
        />
        <LayoutRoutes
          layout="machine"
          exact
          path="/machines/:name/scenario"
          component={MachineDetailPage}
        />
        <LayoutRoutes
          layout="machine"
          exact
          path="/machines/:name/scoreboard"
          component={MachineDetailPage}
        />
        <LayoutRoutes
          layout="machine"
          exact
          roles={['system_admin']}
          path="/machines/:name/log"
          component={MachineDetailPage}
        />
        <LayoutRoutes
          layout="machine"
          exact
          path="/machines/:name/scenario"
          component={MachineDetailPage}
        />
        <LayoutRoutes
          layout="machine"
          exact
          path="/machines/:name/videos/:id"
          component={MachineDetailPage}
        />
        <LayoutRoutes
          layout="machine"
          exact
          path="/machines/:name/documentations"
          component={MachineDetailPage}
        />
        <LayoutRoutes exact path="/hubnews" component={HubNewsPage} />
        <LayoutRoutes
          exact
          path="/challenges"
          component={CtfListPage}
        />
        <LayoutRoutes
          layout="challenge"
          exact
          path="/challenges/:name"
          component={CtfDetailPage}
        />
        <LayoutRoutes
          layout="challenge"
          exact
          path="/challenges/:name/task"
          component={CtfDetailPage}
        />
        <LayoutRoutes
          layout="challenge"
          exact
          path="/challenges/:name/scenario"
          component={CtfDetailPage}
        />
        <LayoutRoutes
          layout="challenge"
          exact
          path="/challenges/:name/scoreboard"
          component={CtfDetailPage}
        />
        <LayoutRoutes
          layout="challenge"
          exact
          roles={['system_admin']}
          path="/challenges/:name/log"
          component={CtfDetailPage}
        />
        <LayoutRoutes
          exact
          path="/profile/:username/edit"
          component={EditProfilePage}
        />
        <LayoutRoutes
          exact
          unrestricted
          path="/profile/:username"
          component={ProfilePage}
        />
        <LayoutRoutes
          exact
          path="/profile/:username1/vs/:username2"
          component={UserComparePage}
        />
        <LayoutRoutes exact path="/teams" component={TeamsPage} />
        <LayoutRoutes
          exact
          path="/teams/:team_name"
          component={TeamDetailPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/teams/:team_name/activity"
          component={TeamDetailPage}
        />
        <LayoutRoutes
          exact
          path="/scoreboard"
          component={ScoreboardPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/users/:username"
          component={UserReportPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/users/:username/machines"
          component={UserReportPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/users/:username/ctfs"
          component={UserReportPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/users/:username/quizzes"
          component={UserReportPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/users/:username/simulations"
          component={UserReportPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/users/:username1/vs/:username2"
          component={UserComparePage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/machines"
          component={MachineReportPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/machines/solutions"
          component={MachineReportPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/machines/logs"
          component={MachineReportPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/machines/:name/solutions"
          component={MachineReportPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/machines/:name/logs"
          component={MachineReportPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/machines/:name"
          component={MachineReportPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/quizzes"
          component={QuizReportPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/quizzes/solutions"
          component={QuizReportPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/quizzes/logs"
          component={QuizReportPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/quizzes/:name/solutions"
          component={QuizReportPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/quizzes/:name/logs"
          component={QuizReportPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/quizzes/:name"
          component={QuizReportPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/challenges"
          component={CtfReportPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/challenges/solutions"
          component={CtfReportPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/challenges/logs"
          component={CtfReportPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/challenges/:name"
          component={CtfReportPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/challenges/:name/solutions"
          component={CtfReportPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/challenges/:name/logs"
          component={CtfReportPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/educations"
          component={EducationReportPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/educations/logs"
          component={EducationReportPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/educations/:name/logs"
          component={EducationReportPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/educations/:name"
          component={EducationReportPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/teams/:name"
          component={TeamReportPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/teams/:name/points"
          component={TeamReportPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/teams/:team1/vs/:team2"
          component={TeamComparePage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/categories/machines/:name"
          component={MachineCategoryReportPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/categories/machines/:name/solutions"
          component={MachineCategoryReportPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/categories/machines/:name/logs"
          component={MachineCategoryReportPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/categories/challenges/:name"
          component={CtfCategoryReportPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/categories/challenges/:name/solutions"
          component={CtfCategoryReportPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/reports/categories/challenges/:name/logs"
          component={CtfCategoryReportPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/contributors"
          component={ContributorsPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/contributors/new"
          component={ContributorFormPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/contributors/:id/edit"
          component={ContributorFormPage}
        />
        <LayoutRoutes
          exact
          roles={['system_admin']}
          path="/profile/:username/vpn-connections"
          component={VpnConnectionsPage}
        />
        <LayoutRoutes
          exact
          path="/scenarios"
          component={ScenariosPage}
        />
        <LayoutRoutes
          exact
          path="/scenarios/:id"
          component={ScenarioDetailPage}
        />
        <LayoutRoutes
          exact
          path="/search"
          component={SearchResultPage}
        />
        <LayoutRoutes
          exact
          path="/tickets"
          component={TicketListPage}
        />
        <LayoutRoutes
          exact
          path="/tickets/:id"
          component={TicketDetailPage}
        />
        <LayoutRoutes
          exact
          path="/events/:id"
          component={EventDetail}
        />
        <LayoutRoutes
          exact
          path="/knowledge-base"
          component={KnowledgeBasePage}
        />
        <LayoutRoutes
          exact
          path="/knowledge-base/mitre/tactics"
          component={KBTacticsPage}
        />
        <LayoutRoutes
          exact
          path="/knowledge-base/mitre/tactics/:mitre_id"
          component={KBTacticDetailPage}
        />
        <LayoutRoutes
          exact
          path="/knowledge-base/mitre/techniques/:mitre_id"
          component={KBTechniqueDetailPage}
        />
        <LayoutRoutes
          exact
          path="/knowledge-base/mitre/techniques/:technique_id/:sub_technique_id"
          component={KBSubTechniqueDetailPage}
        />
        <LayoutRoutes
          exact
          path="/knowledge-base/commands/:category"
          component={KBCommandListPage}
        />
        <LayoutRoutes
          exact
          path="/knowledge-base/wikis/:id"
          component={KBWikiDetailPage}
        />
        <LayoutRoutes
          exact
          path="/simulation-machines"
          component={SimulationPage}
        />
        <Route exact path="/500" component={ServerError} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default AuthorizedRoutes;
