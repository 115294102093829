import * as React from 'react';

function SvgHash(props) {
  return (
    <svg
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        d="M16.718 14.423h-1.655a.119.119 0 01-.119-.119v-4.61a.12.12 0 01.119-.119h1.655c.359 0 .705-.149.947-.41a1.24 1.24 0 00.332-.943 1.265 1.265 0 00-1.257-1.167h-1.677a.12.12 0 01-.119-.119V5.282c0-.36-.15-.705-.411-.948a1.237 1.237 0 00-.943-.331c-.654.048-1.167.6-1.167 1.257v1.676a.12.12 0 01-.119.119h-4.61a.12.12 0 01-.119-.119V5.282a1.3 1.3 0 00-.41-.948 1.24 1.24 0 00-.944-.331c-.654.048-1.167.6-1.167 1.257v1.676a.12.12 0 01-.119.119H3.283c-.36 0-.706.149-.948.41a1.24 1.24 0 00-.331.943c.048.654.6 1.167 1.256 1.167h1.675a.12.12 0 01.119.119v4.61a.119.119 0 01-.119.119H3.283c-.36 0-.706.15-.948.411a1.237 1.237 0 00-.331.943c.048.654.6 1.167 1.256 1.167h1.675c.066 0 .119.053.119.119v1.654c0 .36.15.705.41.947.258.24.593.358.944.332a1.265 1.265 0 001.167-1.257v-1.676c0-.066.053-.119.119-.119h4.61a.12.12 0 01.119.119v1.654c0 .36.15.705.41.947a1.238 1.238 0 00.943.332 1.265 1.265 0 001.168-1.257v-1.676c0-.066.053-.119.119-.119h1.677c.657 0 1.209-.513 1.257-1.167a1.238 1.238 0 00-.332-.943 1.296 1.296 0 00-.947-.411zm-4.295-.119a.12.12 0 01-.119.119h-4.61a.119.119 0 01-.119-.119v-4.61a.12.12 0 01.119-.119h4.61a.12.12 0 01.119.119v4.61z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgHash;
