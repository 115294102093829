import * as React from 'react';

function SvgEducation(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.441 7.461a1.254 1.254 0 00-.632 1.097v3.095c0 .457.236.867.633 1.096.197.113.412.17.628.17.218 0 .435-.058.633-.172l2.667-1.548c.394-.228.629-.638.629-1.094 0-.456-.235-.865-.629-1.094L9.703 7.464a1.25 1.25 0 00-1.262-.003zm3.146 2.644l-2.44 1.416V8.689l2.44 1.416zm5.074-3.572v4.007a.67.67 0 001.339 0V6.533A2.535 2.535 0 0015.467 4H4.533A2.535 2.535 0 002 6.533v7.144a2.535 2.535 0 002.533 2.533h10.934A2.535 2.535 0 0018 13.677a.669.669 0 10-1.339 0c0 .658-.536 1.194-1.194 1.194H4.533a1.196 1.196 0 01-1.194-1.194V6.533c0-.659.536-1.194 1.194-1.194h10.934c.658 0 1.194.535 1.194 1.194zm.67 12.814a.669.669 0 100-1.338H9.348a.668.668 0 100 1.338h7.983zM6.241 20a1.291 1.291 0 10-1.083-1.991H2.669a.669.669 0 100 1.338H5.12c.222.39.641.653 1.121.653z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgEducation;
