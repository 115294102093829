import * as React from 'react';

function SvgEducationalternate(props) {
  return (
    <svg
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        d="M17.847 8.392l-4.344-1.511a.24.24 0 00-.307.133.229.229 0 00.137.298l3.762 1.296-4.01 1.501L9.635 8.6a.24.24 0 00-.315.115.229.229 0 00.119.306l3.021 1.32-2.46.896-7.095-2.629L10 5.978l1.749.648a.239.239 0 00.306-.133.228.228 0 00-.137-.298l-1.833-.68a.248.248 0 00-.17 0L2.153 8.392A.231.231 0 002 8.608v.211c0 .095.061.181.153.215l1.212.449 1.212.45v1.39c0 .127.106.231.237.231a.235.235 0 00.238-.231v-1.214l4.863 1.802a.25.25 0 00.17 0l2.81-1.089-.037 4.555c-1.174.464-1.549.577-2.858.577a8.232 8.232 0 01-4.948-1.651v-1.359a.234.234 0 00-.238-.231.234.234 0 00-.237.231v1.471c0 .07.033.137.089.18A8.712 8.712 0 0010 16.416c1.303 0 1.544-.083 2.93-.575v.935a.883.883 0 00-.666.846c0 .484.405.878.903.878a.892.892 0 00.903-.878.882.882 0 00-.665-.846v-1.179c.387-.214 1.579-.729 1.929-1.002a.225.225 0 00.089-.18V9.932l2.424-.898A.231.231 0 0018 8.818v-.21a.231.231 0 00-.153-.216zm-4.252 9.23a.423.423 0 01-.428.416.423.423 0 01-.427-.416c0-.229.192-.415.427-.415.236 0 .428.186.428.415zm1.353-3.319c-.232.176-1.365.663-1.615.812l.037-4.469 1.578-.537v4.194z"
        strokeWidth={0.5}
        fill="currentColor"
        paintOrder="stroke"
        stroke="currentColor"
      />
    </svg>
  );
}

export default SvgEducationalternate;
