import React, { useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';
import Modal from '../Modal';
import { Button } from '../Button';
import { _fetchJson } from '../../libs/fetch';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import SvgSupport from '../icons/Support';
import SvgPlus from '../icons/Plus';
import styles from './OnboardingArea/MainModal/onboardingArea.module.scss';

function reducer(state, action) {
  switch (action.type) {
    case 'USER_DROPDOWN':
      return {
        userDropdown: action.payload,
      };
    case 'ADD_USER_MODAL':
      return {
        addUserModal: action.payload,
      };
    case 'ADD_TICKET_MODAL':
      return {
        addTicketModal: action.payload,
      };
    case 'INVITE_USER_MODAL':
      return {
        inviteUserModal: action.payload,
      };
    case 'CREATE_USER_MODAL':
      return {
        createUserModal: action.payload,
      };
    default:
      return state;
  }
}

const initialState = {
  userDropdown: false,
  addUserModal: false,
  addTicketModal: false,
  inviteUserModal: false,
  createUserModal: false,
};
function PlusArea() {
  const { t } = useTranslation(['global', 'plus']);
  const { user } = useSelector((state) => state.auth);

  const [state, dispatch] = useReducer(reducer, initialState);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [ticket, setTicket] = useState({
    subject: '',
    title: '',
    content: '',
  });
  const [inviteForm, setInviteForm] = useState({
    name: '',
    surname: '',
    email: '',
  });
  const [createForm, setCreateForm] = useState({
    name: '',
    surname: '',
    email: '',
    role: '',
    password: '',
    password_confirmation: '',
  });

  const inviteUser = (e) => {
    e.preventDefault();
    if (Object.values(inviteForm).some((field) => field === '')) {
      return toast.error('Tüm alanların doldurulması zorunludur');
    }

    setButtonLoading(true);
    _fetchJson(`/auth/invitation`, {
      method: 'post',
      body: JSON.stringify({ user: inviteForm }),
    })
      .then((res) => {
        res.success.map((message) => toast.success(message));
        dispatch({ type: 'INVITE_USER_MODAL', payload: false });
        dispatch({ type: 'ADD_USER_MODAL', payload: false });
      })
      .catch((err) =>
        err.errors.map((message) => toast.error(message)),
      )
      .finally(() => setButtonLoading(false));
  };

  const createUser = (e) => {
    e.preventDefault();
    if (Object.values(createForm).some((field) => field === '')) {
      return toast.error('Tüm alanların doldurulması zorunludur');
    }

    setButtonLoading(true);
    _fetchJson(`/user/create`, {
      method: 'post',
      body: JSON.stringify({ user: createForm }),
    })
      .then((res) => {
        toast.success(res.messages);
        dispatch({ type: 'CREATE_USER_MODAL', payload: false });
        dispatch({ type: 'ADD_USER_MODAL', payload: false });
      })
      .catch((err) =>
        err.messages.map((message) => toast.error(message)),
      )
      .finally(() => setButtonLoading(false));
  };

  const createTicket = (e) => {
    e.preventDefault();
    _fetchJson(`/tickets`, {
      method: 'post',
      body: JSON.stringify({ contact: ticket }),
    })
      .then((res) => {
        if (res.success) {
          toast.success(res.data);
        } else {
          res.data.map((err) => toast.error(err));
        }
      })
      .catch((err) => toast.error(err.statusText))
      .finally(() =>
        dispatch({ type: 'ADD_TICKET_MODAL', payload: false }),
      );
  };

  return user.role === 'system_admin' ? (
    <Modal
      className="user-add-modal"
      size="tiny"
      open={state.addUserModal}
      onClose={() =>
        dispatch({
          type: 'ADD_USER_MODAL',
          payload: false,
        })
      }
      onOpen={() =>
        dispatch({
          type: 'ADD_USER_MODAL',
          payload: true,
        })
      }
      title={t('global:modal.user-add.title')}
      tag={t('global:modal.user-add.tag')}
      desc={t('global:modal.user-add.desc')}
      trigger={
        <div className={styles['onboarding-area']}>
          <SvgPlus style={{ fontSize: '30px' }} />
        </div>
      }
    >
      <Modal
        size="small"
        open={state.inviteUserModal}
        onClose={() =>
          dispatch({ type: 'INVITE_USER_MODAL', payload: false })
        }
        onOpen={() =>
          dispatch({ type: 'INVITE_USER_MODAL', payload: true })
        }
        title={t('global:modal.invite-user.title')}
        tag={t('global:modal.invite-user.tag')}
        desc={t('global:modal.invite-user.desc')}
        button={
          <Button loading={buttonLoading} form="invite-user">
            {t('global:global.send')}
          </Button>
        }
        trigger={
          <button class="open-modal">
            <span class="invite-user-img"></span>
            {t('global:modal.user-add.invite')}
          </button>
        }
      >
        <Form onSubmit={inviteUser} id="invite-user">
          <Form.Group widths="equal">
            <Form.Input
              required
              label={t('plus:name')}
              placeholder={t('plus:name')}
              name="name"
              value={inviteForm.name}
              onChange={(e, { value }) =>
                setInviteForm({ ...inviteForm, name: value })
              }
            />
            <Form.Input
              required
              label={t('plus:surname')}
              placeholder={t('plus:surname')}
              name="surname"
              value={inviteForm.surname}
              onChange={(e, { value }) =>
                setInviteForm({ ...inviteForm, surname: value })
              }
            />
          </Form.Group>
          <Form.Input
            required
            label={t('plus:email')}
            type="email"
            placeholder={t('plus:email')}
            name="email"
            value={inviteForm.email}
            onChange={(e, { value }) =>
              setInviteForm({ ...inviteForm, email: value })
            }
          />
        </Form>
      </Modal>
      <Modal
        size="small"
        open={state.createUserModal}
        onClose={() =>
          dispatch({ type: 'CREATE_USER_MODAL', payload: false })
        }
        onOpen={() =>
          dispatch({ type: 'CREATE_USER_MODAL', payload: true })
        }
        title={t('global:modal.create-user.title')}
        tag={t('global:modal.create-user.tag')}
        desc={t('global:modal.create-user.desc')}
        button={
          <Button loading={buttonLoading} form="create-user">
            {t('global:global.create')}
          </Button>
        }
        trigger={
          <button class="open-modal">
            <span class="create-user-img"></span>
            {t('global:modal.user-add.create')}
          </button>
        }
      >
        <Form id="create-user" onSubmit={createUser}>
          <Form.Group widths="equal">
            <Form.Input
              required
              autoComplete="new-password"
              label={t('plus:username')}
              placeholder={t('plus:username')}
              name="username"
              value={createForm.username}
              onChange={(e, { value }) =>
                setCreateForm({ ...createForm, username: value })
              }
            />
            <Form.Dropdown
              required
              label={t('plus:authority')}
              selection
              placeholder={t('plus:authority')}
              options={[
                {
                  key: '0',
                  value: 'user',
                  text: t('plus:user'),
                },
                {
                  key: '1',
                  value: 'student',
                  text: t('plus:student'),
                },
              ]}
              name="role"
              value={createForm.role}
              onChange={(e, { value }) =>
                setCreateForm({ ...createForm, role: value })
              }
            ></Form.Dropdown>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              required
              label={t('plus:name')}
              placeholder={t('plus:name')}
              name="name"
              value={createForm.name}
              onChange={(e, { value }) =>
                setCreateForm({ ...createForm, name: value })
              }
            />
            <Form.Input
              required
              label={t('plus:surname')}
              placeholder={t('plus:surname')}
              name="surname"
              value={createForm.surname}
              onChange={(e, { value }) =>
                setCreateForm({ ...createForm, surname: value })
              }
            />
          </Form.Group>
          <Form.Input
            required
            label={t('plus:email')}
            placeholder={t('plus:email')}
            name="email"
            value={createForm.email}
            onChange={(e, { value }) =>
              setCreateForm({ ...createForm, email: value })
            }
          />
          <Form.Group widths="equal">
            <Form.Input
              required
              autoComplete="new-password"
              type="password"
              label={t('plus:password')}
              placeholder={t('plus:password')}
              name={t('plus:password')}
              value={createForm.password}
              onChange={(e, { value }) =>
                setCreateForm({ ...createForm, password: value })
              }
            />
            <Form.Input
              required
              type="password"
              label={t('plus:password_confirmation')}
              placeholder={t('plus:password_confirmation')}
              name="password_confirmation"
              value={createForm.password_confirmation}
              onChange={(e, { value }) =>
                setCreateForm({
                  ...createForm,
                  password_confirmation: value,
                })
              }
            />
          </Form.Group>
        </Form>
      </Modal>
    </Modal>
  ) : (
    <Modal
      size="tiny"
      open={state.addTicketModal}
      onClose={() =>
        dispatch({
          type: 'ADD_TICKET_MODAL',
          payload: false,
        })
      }
      onOpen={() =>
        dispatch({
          type: 'ADD_TICKET_MODAL',
          payload: true,
        })
      }
      title={t('global:modal.create-ticket.title')}
      tag={t('global:modal.create-ticket.tag')}
      desc={t('global:modal.create-ticket.desc')}
      button={
        <Button form="header-ticket-form" loading={buttonLoading}>
          {t('global:global.send')}
        </Button>
      }
      trigger={
        <div className={styles['onboarding-area']}>
          <SvgSupport style={{ fontSize: '30px' }} />
        </div>
      }
    >
      <Form id="header-ticket-form" onSubmit={createTicket}>
        <Form.Dropdown
          label={t('support:subject')}
          selection
          placeholder={t('support:drop_select')}
          options={[
            {
              key: '0',
              value: 'technical_support',
              text: t('support:technical_support'),
            },
            {
              key: '1',
              value: 'vpn_support',
              text: t('support:vpn_support'),
            },
            {
              key: '2',
              value: 'production_support',
              text: t('support:production_support'),
            },
            {
              key: '3',
              value: 'bug_notification',
              text: t('support:bug_notification'),
            },
            { key: '4', value: 'other', text: t('support:other') },
          ]}
          onChange={(e, { value }) =>
            setTicket({ ...ticket, subject: value })
          }
          value={ticket.subject}
        ></Form.Dropdown>
        <Form.Input
          label={t('support:title')}
          placeholder={t('support:enter_the_message_title')}
          name="title"
          onChange={(e) =>
            setTicket({ ...ticket, title: e.target.value })
          }
          value={ticket.title}
        />
        <Form.TextArea
          label={t('support:message')}
          placeholder={t('support:enter_the_message_content')}
          name="content"
          onChange={(e) =>
            setTicket({ ...ticket, content: e.target.value })
          }
          value={ticket.content}
          rows={8}
        />
      </Form>
    </Modal>
  );
}

export default PlusArea;
