import * as React from 'react';

function SvgTrophyCut(props) {
  return (
    <svg
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        d="M16.474 5.375c.026-.308.04-.62.04-.926v-.41l-.398-.075a26.214 26.214 0 00-1.646-.281l-.39.944c.475.066.953.142 1.427.231-.138 3.231-2.067 6.506-4.769 8.082l-.246.147v2.225h-.998v-2.225l-.245-.147c-2.698-1.576-4.618-4.851-4.77-8.073.376-.072.747-.129 1.123-.186l-.415-.941a23.22 23.22 0 00-1.311.232l-.403.077v.409c0 .306.015.612.045.917H1.5l.067.556a13.183 13.183 0 001.624 4.863c.063.112.125.223.193.334a2.511 2.511 0 002.142 1.202h1.315a9.783 9.783 0 001.66 1.335v1.691H6.166v3.97H5.003v.992h9.981v-1.006h-1.163v-3.969H11.49v-1.692a9.764 9.764 0 001.665-1.334h1.319a2.483 2.483 0 002.138-1.202c.067-.111.135-.222.198-.333.85-1.5 1.399-3.149 1.619-4.856l.071-.551h-2.026zM5.526 11.337a1.49 1.49 0 01-1.279-.716l-.183-.311a12.317 12.317 0 01-1.419-3.935h1.012a11.619 11.619 0 002.29 4.962h-.421zm7.297 4.998v2.977H7.168v-2.977h5.655zm3.105-6.025l-.179.311a1.502 1.502 0 01-1.275.716h-.447a11.44 11.44 0 001.028-1.513 11.266 11.266 0 001.275-3.449h1.016a12.34 12.34 0 01-1.418 3.935z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgTrophyCut;
