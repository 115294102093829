import React, { useState } from 'react';
import cn from 'classnames';
import { Modal, Form, Icon } from 'semantic-ui-react';
import TagLabel from '../../TagLabel';
import { Button } from '../../Button';
import { Trans } from 'react-i18next';
import '../Modal.scss';
import './stepForm.scss';

function StepFormModal({ steps = [], tag, children, ...props }) {
  const [activeStep, setActiveStep] = useState(0);

  return (
    <Modal className="step-form" {...props}>
      <Form>
        <div className="modal-title-area">
          <div className="modal-name">{steps[activeStep].title}</div>
          <TagLabel className="modal-root-page" color="primary">
            {tag}
          </TagLabel>
        </div>
        <div className="modal-desc">{steps[activeStep].desc}</div>
        <div className="modal-divider"></div>
        {React.Children.map(children, (child, index) => (
          <div
            key={index}
            className={cn('step', { active: activeStep === index })}
          >
            <div className="modal-content-area">{child}</div>
          </div>
        ))}
        <div class="actions hub-action">
          {activeStep === 0 && (
            <Button
              is="div"
              color="privia-grey"
              onClick={() => props.onClose()}
            >
              Vazgeç
            </Button>
          )}
          {activeStep > 0 && (
            <Button
              is="div"
              color="privia-grey"
              onClick={() => setActiveStep(activeStep - 1)}
            >
              <Icon name="chevron left" /> Geri
            </Button>
          )}
          <div class="steps">
            {steps.map((_, index) => (
              <button
                key={index}
                className={cn('step-btn', {
                  active: activeStep === index,
                })}
                onClick={() => setActiveStep(index)}
              ></button>
            ))}
          </div>
          {activeStep < steps.length - 1 && (
            <Button
              is="div"
              onClick={() => setActiveStep(activeStep + 1)}
            >
              {<Trans i18nKey="addNewMachine:advance"></Trans>} <i class="chevron right icon"></i>
            </Button>
          )}
          {activeStep === steps.length - 1 && (
            <Button is="div" onClick={() => props.onFinish()}>
              Kaydet
            </Button>
          )}
        </div>
      </Form>
    </Modal>
  );
}

export default StepFormModal;
