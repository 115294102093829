import * as React from 'react';

function SvgQuiz(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.609 6.75a.872.872 0 00-.87.875v8.75c0 .483.389.875.87.875h8.695c.481 0 .87-.392.87-.875V13.4c0-.966.779-1.75 1.739-1.75v4.725A2.617 2.617 0 0113.304 19H4.609A2.617 2.617 0 012 16.375v-8.75A2.617 2.617 0 014.609 5h8.695c1.326 0 2.421.996 2.587 2.284l.625-.628a.866.866 0 011.229 0c.34.342.34.896 0 1.238l-6.188 6.227a1.211 1.211 0 01-1.722 0l-3.059-3.077a.88.88 0 010-1.238.867.867 0 011.23 0l2.69 2.707 3.478-3.5V7.625a.872.872 0 00-.87-.875H4.609z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgQuiz;
