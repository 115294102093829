import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { logout as logoutAction } from '../../actions/user';
import { Link, useRouteMatch } from 'react-router-dom';
import cn from 'classnames';
import { Dropdown, Icon } from 'semantic-ui-react';
import Nav from '../Nav';
import PlusArea from './PlusArea';
import NotificationArea from './NotificationArea';
import LifeArea from './LifeArea';
import MachineArea from './machineArea';
import Tour from 'reactour';
import { steps } from '../../onboarding';
import { _fetchJson, _fetchBlob } from '../../libs/fetch';
import ChatArea from './ChatArea/ChatArea';
import OnboardingArea from './OnboardingArea';
import { toast } from 'react-toastify';
import { changeTheme } from '../../actions/siteConfig';

function Header({
  collapsed,
  setCollapsed,
  machine,
  challenge,
  layout,
}) {
  const panelSettings = useSelector((state) => state.panelSettings);
  const { theme, tour } = useSelector((state) => state.siteConfig);
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const auth = useSelector((state) => state.auth);
  const { t } = useTranslation('global');

  const [lifeAreaModal, setLifeAreaModal] = useState(false);
  const [onboardingModal, setOnboardingModal] = useState(false);
  const [notificationDropdown, setNotificationDropdown] = useState(
    false,
  );
  const [logo, setLogo] = useState('');

  const darkLogos = ['light'];
  const whiteLogos = ['dark', 'green', 'default'];

  const changeLanguage = useCallback((language) => {
    _fetchJson(`/locale/${language}`)
      .then((res) => {
        if (res.success) {
          window.location.reload();
        }
      })
      .catch((err) => toast.error(err.error));
  }, []);

  const download = () => {
    _fetchJson(`/download_vpn/TCP`)
      .then((res) => {
        if (res.success) {
          _fetchBlob(`/download_vpn_file/TCP`).then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', res.filename);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          });
        }
      })
      .catch((err) => toast.error(err.statusText));
  };

  const logout = useCallback(
    (e) => {
      e.preventDefault();

      dispatch(logoutAction());
    },
    [dispatch],
  );

  useEffect(() => {
    if (darkLogos.some((e) => e === theme))
      setLogo(panelSettings.logo_white);
    if (whiteLogos.some((e) => e === theme))
      setLogo(panelSettings.logo);
  }, [theme, panelSettings]);

  return (
    <>
      <Tour
        steps={steps[tour] ?? []}
        isOpen={!!tour}
        onRequestClose={() =>
          dispatch({ type: 'SET_TOUR', payload: false })
        }
        startAt={0}
        className="tour-container"
      />
      <header
        className={cn(
          collapsed && 'collapsed',
          layout === 'quiz' && 'display-none',
        )}
      >
        <div className="navigation">
          <div className="collapse-area">
            {!machine && auth.user && (
              <a
                className="collapse-button"
                onClick={() => setCollapsed(!collapsed)}
              >
                <Icon name="bars" />
              </a>
            )}
            <Link to="/" className="logo">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div className="nav-action">
            {auth.user && (
              <>
                <div className="header-left">
                  <MachineArea />
                </div>

                <div className="header-right">
                  <ChatArea />
                  <LifeArea
                    isOpen={lifeAreaModal}
                    setIsOpen={setLifeAreaModal}
                    setOnboardingEnabled={(type = path) => {
                      if (steps[type]) {
                        setLifeAreaModal(false);
                        dispatch({ type: 'SET_TOUR', payload: type });
                      } else {
                        dispatch({
                          type: 'SET_TOUR',
                          payload: false,
                        });
                      }
                    }}
                  />
                  <OnboardingArea
                    isOpen={onboardingModal}
                    setOpen={setOnboardingModal}
                  />
                  <PlusArea />
                  <NotificationArea
                    notificationDropdown={notificationDropdown}
                    onClick={setNotificationDropdown}
                  />
                  <Dropdown
                    trigger={
                      <>
                        <img
                          src={`/img/avatar/${auth.user.avatar}.jpg`}
                          alt="Profile"
                        />
                        <span className="username">
                          {auth.user.username}
                        </span>
                      </>
                    }
                    pointing="top right"
                    className="user-area"
                  >
                    <Dropdown.Menu>
                      <Dropdown.Item className="link">
                        <Link to={`/profile/${auth.user.username}`}>
                          <Icon name="user" />
                          {t('layout.profile.show')}
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item
                        icon="download"
                        text={t('layout.profile.vpn')}
                        onClick={() => download()}
                      />
                      <Dropdown.Item>
                        <Dropdown
                          pointing="right"
                          trigger={
                            <span className="profile-dropdown">
                              <Icon name="language" />
                              {t('layout.profile.language')}
                            </span>
                          }
                        >
                          <Dropdown.Menu>
                            <Dropdown.Item
                              text={t('global:global:turkish')}
                              onClick={() => changeLanguage('tr')}
                            />
                            <Dropdown.Item
                              text={t('global:global:english')}
                              onClick={() => changeLanguage('en')}
                            />
                          </Dropdown.Menu>
                        </Dropdown>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <Dropdown
                          pointing="right"
                          trigger={
                            <span className="profile-dropdown">
                              <Icon name="paint brush" />
                              {t('layout.profile.theme')}
                            </span>
                          }
                        >
                          <Dropdown.Menu>
                            <Dropdown.Item
                              className={
                                theme === 'default' ? 'active' : ''
                              }
                              onClick={() =>
                                dispatch(changeTheme('default'))
                              }
                              text="Default Mode"
                            />
                            <Dropdown.Item
                              className={
                                theme === 'light' ? 'active' : ''
                              }
                              onClick={() =>
                                dispatch(changeTheme('light'))
                              }
                              text="Light Mode"
                            />
                            <Dropdown.Item
                              className={
                                theme === 'dark' ? 'active' : ''
                              }
                              onClick={() =>
                                dispatch(changeTheme('dark'))
                              }
                              text="Dark Mode"
                            />
                            <Dropdown.Item
                              className={
                                theme === 'green' ? 'active' : ''
                              }
                              onClick={() =>
                                dispatch(changeTheme('green'))
                              }
                              text="H4ck3r Mode"
                            />
                          </Dropdown.Menu>
                        </Dropdown>
                      </Dropdown.Item>
                      <Dropdown.Item className="link">
                        <Link to="/" onClick={(e) => logout(e)}>
                          <Icon name="sign out alternate" />
                          {t('layout.profile.logout')}
                        </Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="mobile-navbar">
          <label htmlFor="toggled" className="target-burger">
            <ul className="buns">
              <li className="bun"></li>
              <li className="bun"></li>
            </ul>
          </label>
        </div>
        <input
          type="checkbox"
          className="toggled-checkbox display-none"
          id="toggled"
        />
        {auth.user && <Nav machine={machine} challenge={challenge} />}
      </header>
    </>
  );
}

export default Header;
