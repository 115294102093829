import * as React from 'react';

function SvgEvents(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.678 4v1.356h4.644V4h1.549v1.356h2.323c.997 0 1.806.85 1.806 1.898v10.847C18 19.15 17.191 20 16.194 20H3.807C2.809 20 2 19.15 2 18.101V7.254c0-1.048.809-1.898 1.807-1.898h2.322V4h1.549zm4.644 2.983H7.678v2.712h4.644V6.983zm1.549 2.712V6.983h2.323c.142 0 .257.122.257.271v2.441h-2.58zM3.807 6.983h2.322v2.712h-2.58V7.254c0-.149.115-.271.258-.271zm-.258 4.34v6.778c0 .151.115.272.258.272h12.387a.264.264 0 00.257-.272v-6.778H3.549z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgEvents;
