import React, { useEffect, useRef, useState } from 'react';
import { Form, Icon } from 'semantic-ui-react';
import cn from 'classnames';
import SvgChat from '../../../icons/Chat';
import styles from './chatScreen.module.scss';
import SvgSend from '../../../icons/Send';
import Message from '../Message';
import { _fetchJson } from '../../../../libs/fetch';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const ChatScreen = ({ messages = [], setMessages = () => null }) => {
  const { user } = useSelector((state) => state.auth);
  const [active, setActive] = useState(false);
  const [message, setMessage] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);
  const ref = useRef();

  const { t } = useTranslation('global')

  useEffect(() => {
    ref.current.scrollTop = ref.current.scrollHeight;
  }, [messages]);

  const headerClick = () => {
    setActive(!active);
  };

  const sendMessage = (e) => {
    e.preventDefault();
    if (message.trim().length === 0) {
      return toast.error(t('global:global.message'));
    }
    setButtonLoading(true);
    _fetchJson(`/messages`, {
      method: 'post',
      body: JSON.stringify({
        message: {
          body: message,
        },
      }),
    })
      .then((res) => {
        if (res.status) {
          setMessage('');
        }
      })
      .catch((err) => toast.error(err.error))
      .finally(() => setButtonLoading(false));
  };

  return (
    <div className={styles.chatScreen}>
      <div
        onClick={headerClick}
        className={cn(styles.header, active && styles.active)}
      >
        <div className={styles.left}>
          <div className={styles.icon}>
            <SvgChat style={{ fontSize: '2rem', color: '#fff' }} />
          </div>
          {t('global.chat')}
        </div>
        <Icon name="chevron up" />
      </div>
      <div className={cn(styles.content, active && styles.active)}>
        <div ref={ref} className={styles.body}>
          {messages.map((message, index) => (
            <Message
              key={index}
              username={message.username}
              content={message.body}
              date={message.created_at}
              color={message.color_hex}
              self={message.username === user.username}
            />
          ))}
        </div>
        <div className={styles.footer}>
          <Form onSubmit={sendMessage}>
            <input
              placeholder={t("global.message_placeholder")}
              className={styles.chatInput}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            
            ></input>
            <button disabled={buttonLoading}>
              <SvgSend />
            </button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ChatScreen;
