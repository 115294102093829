import * as React from 'react';

function SvgCrown(props) {
  return (
    <svg
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.901 7.703a.155.155 0 00-.061.077l-1.357 3.252a1.49 1.49 0 01-2.438.481L3.952 9.402c-.043-.043-.075-.051-.094-.053a.184.184 0 00-.105.026.184.184 0 00-.076.077c-.009.018-.019.049-.004.109l1.04 4.261c.219.9-.326 1.809-1.218 2.03l-1.437-5.89c-.443-1.814 1.76-3.066 3.07-1.746l1.918 1.935 1.262-3.022c.628-1.505 2.743-1.505 3.372 0l1.244 2.981 1.909-2.054c1.23-1.323 3.417-.25 3.144 1.542l-.957 6.268A2.5 2.5 0 0114.554 18H4.838a.835.835 0 01-.832-.839c0-.463.372-.838.832-.838h9.716c.411 0 .76-.303.822-.712l.957-6.268c.008-.057-.003-.087-.013-.105a.186.186 0 00-.079-.071.184.184 0 00-.104-.019c-.02.003-.05.012-.09.055l-2.084 2.242a1.49 1.49 0 01-2.472-.446L10.147 7.78a.154.154 0 00-.153-.103.17.17 0 00-.093.026z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCrown;
