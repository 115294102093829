import * as React from 'react';

function SvgScenario(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 5.333C2 4.597 2.597 4 3.333 4h3.048c.736 0 1.333.597 1.333 1.333v.572h4.572v-.572c0-.736.597-1.333 1.333-1.333h3.048C17.403 4 18 4.597 18 5.333v3.048c0 .736-.597 1.333-1.333 1.333h-.572v4.572h.572c.736 0 1.333.597 1.333 1.333v3.048c0 .736-.597 1.333-1.333 1.333h-3.048a1.333 1.333 0 01-1.333-1.333v-.572H7.714v.572c0 .736-.597 1.333-1.333 1.333H3.333A1.333 1.333 0 012 18.667v-3.048c0-.736.597-1.333 1.333-1.333h.572v-3.81c1.052 0 1.904.853 1.904 1.905v1.905h.572c.736 0 1.333.597 1.333 1.333v.572h4.572v-.572c0-.736.597-1.333 1.333-1.333h.571V9.714h-.571a1.333 1.333 0 01-1.333-1.333V7.81H7.714v.571c0 .736-.597 1.333-1.333 1.333H3.333A1.333 1.333 0 012 8.381V5.333zM3.905 7.81V5.905h1.904V7.81H3.905zM14.19 5.905V7.81h1.905V5.905H14.19zm0 12.19v-1.904h1.905v1.904H14.19zM3.905 16.191v1.904h1.904v-1.904H3.905z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgScenario;
