import * as React from 'react';

function SvgReports(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 6.642c0-.477.373-.864.833-.864.461 0 .834.387.834.864v8.988c0 .477.373.864.833.864h12.667c.46 0 .833.387.833.864 0 .478-.373.865-.833.865H4.5c-1.381 0-2.5-1.161-2.5-2.593V6.642zm10.667 0c0-.477.373-.864.833-.864h3.333c.645 0 1.167.542 1.167 1.21v3.457c0 .477-.373.864-.833.864-.461 0-.834-.387-.834-.864V8.729l-3.216 3.335a2.11 2.11 0 01-2.919.137l-1.156-.998a.486.486 0 00-.663.022l-1.762 1.727a1.624 1.624 0 01-2.356-.069l2.973-2.914a2.109 2.109 0 012.875-.094l1.156.999c.198.171.49.158.673-.032l3.217-3.335H13.5c-.46 0-.833-.387-.833-.865z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgReports;
