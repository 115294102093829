import * as React from 'react';

function SvgEye(props) {
  return (
    <svg
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.491 11.973l.058-.07A7.84 7.84 0 016.52 9.709a3.002 3.002 0 00-.212 1.113c0 1.824 1.632 3.303 3.645 3.303 2.014 0 3.646-1.479 3.646-3.303 0-.383-.072-.751-.204-1.094a7.828 7.828 0 012.937 2.191.045.045 0 01-.001.06l-.493.544c-2.699 2.977-7.487 3.232-10.555.846-.695-.54-1.723-.695-2.457-.199 3.523 4.283 10.549 4.434 14.292.306l.494-.544c.5-.552.519-1.343.046-1.914C13.803 6.361 6.095 6.352 2.226 11l-.058.069c-.276.331-.203.802.162 1.052.366.249.885.183 1.161-.148zm6.462-2.952c-1.098 0-1.988.806-1.988 1.801 0 .995.89 1.802 1.988 1.802 1.099 0 1.989-.807 1.989-1.802s-.89-1.801-1.989-1.801z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgEye;
