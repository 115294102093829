import * as React from 'react';

function SvgChat(props) {
  return (
    <svg
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.857 5.905a.952.952 0 00-.952.952v12.191a.953.953 0 01-1.905 0V6.857A2.857 2.857 0 014.857 4h10.286A2.857 2.857 0 0118 6.857v8.381a2.857 2.857 0 01-2.857 2.857H4.667c0-1.052.852-1.905 1.904-1.905h8.572a.952.952 0 00.952-.952V6.857a.952.952 0 00-.952-.952H4.857z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.286 9.143a.953.953 0 01-.953.952H6.762a.953.953 0 110-1.905h4.571c.526 0 .953.427.953.953zm-1.905 3.809a.953.953 0 01-.952.953H6.762a.953.953 0 010-1.905h2.667c.525 0 .952.426.952.952z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgChat;
