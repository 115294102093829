import * as React from 'react';

function SvgGraduation(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 10.558l-.937.387v4.715h-.962v-3.153l.024-1.186L10 13.768l-8-3.21 8-3.21 8 3.21z"
        fill="currentColor"
      />
      <path
        d="M10 14.778l-4.219-1.694v1.224c0 1.336 1.814 2.343 4.219 2.343 2.405 0 4.219-1.007 4.219-2.343v-1.224L10 14.778z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgGraduation;
