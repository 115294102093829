const INITIAL_STATE = { search: '', category: 'all', loading: false };

const knowledgeBase = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SEARCH_QUERY':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default knowledgeBase;
