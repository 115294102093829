import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from './i18n';


export const steps = {
  layout: [
    {
      selector: 'nav',
      content: <Trans i18nKey="lifeArea:take_tour_1"></Trans>,
    },
    {
      selector: 'header .user-area',
      content: <Trans i18nKey="lifeArea:take_tour_2"></Trans>,
    },
    {
      selector: 'header .notification-area',
      content: () => <div dangerouslySetInnerHTML={{
        __html: i18n.t("lifeArea:take_tour_3"),
      }} ></div>,
    },
    {
      selector: '.active-machine-dropdown',
      content: <Trans i18nKey="lifeArea:take_tour_4"></Trans>,
    },
  ],
  '/': [
    {
      selector: '[data-step = "1"]',
      content: (
        <Trans i18nKey="lifeArea:dashboard_about_page_1"></Trans>
      ),
    },
    {
      selector: '[data-step = "2"]',
      content: (
        <>
          <Trans i18nKey="lifeArea:dashboard_about_page_2"></Trans>
        </>
      ),
    },
    {
      selector: '[data-step = "3"]',
      content: (
        <Trans i18nKey="lifeArea:dashboard_about_page_3"></Trans>
      ),
    },
    {
      selector: '[data-step = "4"]',
      content: (
        <Trans i18nKey="lifeArea:dashboard_about_page_4"></Trans>
      ),
    },
    {
      selector: '[data-step = "5"]',
      content: (
        <Trans i18nKey="lifeArea:dashboard_about_page_5"></Trans>
      ),
    },
    {
      selector: '[data-step = "6"]',
      content: (
        <Trans i18nKey="lifeArea:dashboard_about_page_6"></Trans>
      ),
    },
    {
      selector: '[data-step = "7"]',
      content: (
        <>
          <Trans i18nKey="lifeArea:dashboard_about_page_7"></Trans>
          <br></br>
          <br></br>
          <Link
            to="/machines"
            className="ui button hub-button classic p10-mx"
          >
            <Trans i18nKey="lifeArea:go_machine"></Trans>
          </Link>{' '}
          <Link
            to="/challenges"
            className="ui button hub-button classic p10-mx"
          >
            <Trans i18nKey="lifeArea:go_ctf"></Trans>
          </Link>
        </>
      ),
    },
  ],
};

