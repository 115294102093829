import * as React from 'react';

function SvgScreen(props) {
  return (
    <svg
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        d="M17.709 3.894H2.29c-.712 0-1.29.579-1.29 1.291v9.344c0 .712.578 1.291 1.29 1.291h5.465v2.005H5.373a.663.663 0 00-.663.662v.958c0 .367.297.662.663.662h9.253a.662.662 0 00.663-.662v-.958a.663.663 0 00-.663-.662h-2.382V15.82h5.465c.712 0 1.291-.579 1.291-1.291V5.185c0-.712-.579-1.291-1.291-1.291zM2.256 14.529l.034-9.378 15.453.034v9.377l-15.487-.033zm16.115-.754a.324.324 0 110-.648.324.324 0 010 .648zm0-1.354a.324.324 0 110-.648.324.324 0 010 .648z"
        fill="currentColor"
      />
      <path
        d="M9.451 10.787c.065.02.137.001.187-.047l.503-.504 1.18 1.179a.185.185 0 00.263 0l.466-.465a.186.186 0 000-.263l-1.179-1.18.504-.504a.192.192 0 00.047-.186.189.189 0 00-.141-.128l-2.179-.44a.187.187 0 00-.169.05.189.189 0 00-.05.169l.44 2.177a.186.186 0 00.128.142z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgScreen;
