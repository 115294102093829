import * as React from 'react';

function SvgPlaylist(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.046 2.132c-1.241-.97-3.048-.083-3.048 1.495v16.745c0 1.578 1.807 2.464 3.048 1.498l6.121-4.784v3.286c0 1.578 1.808 2.464 3.047 1.498l9.378-7.326a2.69 2.69 0 00-3.784-.476l-5.942 4.642V5.289l9.96 7.78a1.345 1.345 0 001.892-.236 1.362 1.362 0 00-.237-1.903L12.214 2.132c-1.239-.97-3.047-.083-3.047 1.495v3.287L3.046 2.132zm-.351 3.157V18.71l6.472-5.056v-3.308L2.695 5.289z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgPlaylist;
