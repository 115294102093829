import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import ActionCable from 'actioncable';
import './i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';
import reducer from './reducers';
import 'semantic-ui-css/semantic.min.css';
import './styles/index.scss';
import { ToastContainer } from 'react-toastify';
import { ActionCableProvider } from '@thrash-industries/react-actioncable-provider';
import 'react-toastify/dist/ReactToastify.min.css';
import { getUser } from './actions/user';

export const store = createStore(reducer, applyMiddleware(thunk));
const token = localStorage.getItem('access-token'),
  expiry = localStorage.getItem('expiry');

const cable = ActionCable.createConsumer(
  'wss://api.priviahub.com/cable',
);

if (token) {
  if (new Date().getTime() < expiry * 1000) {
    store.dispatch({ type: 'VERIFY_TOKEN' });
    store.dispatch(getUser());
  }
} else {
  localStorage.removeItem('access-token');
  localStorage.removeItem('client');
  localStorage.removeItem('expiry');
  localStorage.removeItem('token-type');
  localStorage.removeItem('uid');
}

ReactDOM.render(
  <ActionCableProvider cable={cable}>
    <Provider store={store}>
      <App />
      <ToastContainer
        hideProgressBar
        autoClose={5000}
        closeButton={false}
      />
    </Provider>
  </ActionCableProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
