const INITIAL_STATE = {
  telegram_link: '',
  twitter_link: '',
  discord_link: '',
  youtube_link: '',
  web_site: '',
  copright: '',
  logo: '',
  logo_white: '',
};

const panelSettings = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_PANEL_SETTINGS':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default panelSettings;
