import * as React from 'react';

function SvgLogin(props) {
  return (
    <svg
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.857 8.19a.952.952 0 011.347 0l2.867 2.867a1.332 1.332 0 010 1.885l-2.867 2.867a.952.952 0 11-1.347-1.347l1.511-1.51H2.952a.952.952 0 010-1.905h7.416l-1.511-1.51a.952.952 0 010-1.347z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.143 5.905h-2.857A1.905 1.905 0 0110.381 4h4.762A2.857 2.857 0 0118 6.857v10.286A2.857 2.857 0 0115.143 20h-3.81a.953.953 0 010-1.905h3.81a.952.952 0 00.952-.952V6.857a.952.952 0 00-.952-.952z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgLogin;
