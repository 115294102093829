import * as React from 'react';

function SvgSend(props) {
  return (
    <svg
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.04 6.699a.576.576 0 00-.74-.739L4.295 10.027a.576.576 0 00-.037 1.065l1.353.609a1.918 1.918 0 01.962 2.537l-3.102-1.397c-2.036-.916-1.936-3.84.158-4.614L14.636 4.16c1.998-.738 3.942 1.206 3.204 3.204L13.772 18.37c-.774 2.094-3.698 2.195-4.614.159l-1.371-3.047a2.877 2.877 0 01.59-3.216l2.606-2.606a.959.959 0 111.357 1.357l-2.607 2.606a.96.96 0 00-.196 1.072l1.371 3.046a.575.575 0 001.064-.036L16.04 6.699z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgSend;
