import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from '.';
import { setMachine } from '../../actions';

const AddMachineModal = ({ newMachine, setNewMachine, ...props }) => {
  const { t } = useTranslation('addNewMachine');
  const dispatch = useDispatch();

  return (
    <Modal
      size="large"
      closeButton={false}
      open={newMachine}
      onClose={() => setNewMachine(false)}
      onOpen={() => setNewMachine(true)}
      {...props}
    >
      <div className="machine-modal-top">
        <div className="machine-add-name">{t('add_machine')}</div>
        <div className="machine-add-desc">
          {t('add_machine')} {t('select_machine_type')}
        </div>
      </div>
      <div className="machine-type">
        <Link
          onClick={() => {
            dispatch(
              setMachine({
                addType: 'ready',
              }),
            );
          }}
          to="/machines/ready-machine/new"
        >
          <img
            src="/img/machines/ready-machine.png"
            alt="add-machine"
          />
          <span>{t('machine_normal_add')}</span>
        </Link>
        <Link
          onClick={() => {
            dispatch(
              setMachine({
                addType: 'simulation',
              }),
            );
          }}
          to="/machines/simulation-machine/new"
        >
          <img
            src="/img/machines/ready-machine.png"
            alt="add-machine"
          />
          <span>{t('machine_simulation_add')}</span>
        </Link>
      </div>
    </Modal>
  );
};

export default AddMachineModal;
