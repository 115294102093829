import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';
import cn from 'classnames';
import { Trans } from 'react-i18next';
import TagLabel from '../TagLabel';
import './notificationArea.scss';
import { toast } from 'react-toastify';
import SvgBell from '../icons/Bell';
import { updateUser } from '../../actions/user';
import { useTranslation } from 'react-i18next';
function NotificationArea({ notificationDropdown, onClick }) {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState([]);
  const { t } = useTranslation('global');
  const [newNotification, setNewNotification] = useState({
    status: false,
    count: 0,
  });

  useEffect(() => {
    setNotifications(user.notifications);
  }, []);

  const onReceived = (message) => {
    if (message.team_name) {
      dispatch(updateUser({ team: message.team_name }));
    }
    setNotifications((prevState) => [message, ...prevState]);
    setNewNotification((prevState) => ({
      status: true,
      count: prevState.count + 1,
    }));
    toast.info(message.content, {
      autoClose: 10000,
      position: 'bottom-right',
      onOpen: () => {
        new Audio('/sounds/notification.wav').play();
      },
    });
  };

  return (
    <div className="notification-area">
      <ActionCableConsumer
        channel={{
          channel: 'NotificationsChannel',
          username: user.username,
        }}
        onReceived={onReceived}
      ></ActionCableConsumer>
      {notificationDropdown && (
        <div className="overlay" onClick={() => onClick(false)}></div>
      )}
      <div
        className="notification-box"
        onClick={() => {
          onClick(!notificationDropdown);
          newNotification.status &&
            setNewNotification({ status: false, count: 0 });
        }}
      >
        <SvgBell />
        <div
          className={cn('count', newNotification.status && 'active')}
        >
          {newNotification.count}
        </div>
      </div>

      <div
        className={cn('dropdown-area', {
          active: notificationDropdown,
        })}
      >
        <div className="head-area">
          <div className="title">
            {t("layout.notification.title")}
          </div>
          <Link
            to="/notifications"
            className="all"
            onClick={() => onClick(!notificationDropdown)}
          >
            {t("global.all")}
          </Link>
        </div>
        <div className="head-content">
          {notifications.length === 0 ? (
            <div className={`notification-detail item no-content`}>
                 {t("global.no_notification")}
            </div>
          ) : (
            notifications.map((item, index) => (
              <div
                key={index}
                className={`notification-detail item ${item.color}`}
              >
                <div className="title">
                  <TagLabel
                    color={
                      item.color === 'danger'
                        ? 'red'
                        : item.color === 'notiviahub'
                        ? 'purple'
                        : 'water-green'
                    }
                  >
                    {item.title}
                  </TagLabel>
                </div>
                <div className="desc">{item.content}</div>
                <div className="date">
                  {new Date(item.created_at).toLocaleString()}
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default React.memo(NotificationArea);
