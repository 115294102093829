import * as React from 'react';

function SvgHome(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.422 6.142a.835.835 0 011.156 0l3.668 3.419v7.574c0 .528-.396.955-.884.955H6.638c-.488 0-.884-.427-.884-.955v-2.864c0-1.055-.792-1.91-1.77-1.91v4.774C3.984 18.717 5.173 20 6.638 20h6.724c1.465 0 2.654-1.283 2.654-2.865V11.21l.521.486a.84.84 0 001.248-.099 1.008 1.008 0 00-.091-1.346l-5.959-5.555a2.51 2.51 0 00-3.47 0l-5.959 5.555c-.369.344-.41.947-.091 1.346a.84.84 0 001.248.099l5.959-5.554z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgHome;
