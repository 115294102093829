import React, {
  useState,
  useEffect,
  useLayoutEffect,
  Suspense,
} from 'react';
import cn from 'classnames';
import Header from '../Header';
import { _fetchJson } from '../../libs/fetch';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../Spinner';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Trans } from 'react-i18next';

function Layout({ children, layout }) {
  const { user } = useSelector((state) => state.auth);
  const { theme } = useSelector((state) => state.siteConfig);
  const panelSettings = useSelector((state) => state.panelSettings);
  const [collapsed, setCollapsed] = useState(false);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (user) {
      dispatch({
        type: 'SET_THEME',
        payload: user?.theme,
      });
    } else {
      dispatch({ type: 'SET_THEME', payload: 'dark' });
    }
  }, [user]);

  useLayoutEffect(() => {
    _fetchJson('/panel_configs/1')
      .then(
        (res) =>
          res.status &&
          dispatch({
            type: 'SET_PANEL_SETTINGS',
            payload: res,
          }),
      )
      .catch((err) => toast.error(err.message || err.statusText));
  }, []);

  useEffect(() => {
    const $body = document.querySelector('body');
    if (!theme) {
      $body.classList.remove(...$body.classList);
      $body.classList.add('dark');
      return;
    }
    $body.classList.remove(...$body.classList);
    $body.classList.add(theme.toString());
  }, [theme]);

  return (
    <>
      {layout === 'step-form' ? null : (
        <Header
          layout={layout}
          machine={layout === 'machine'}
          challenge={layout === 'challenge'}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
      )}
      <main
        className={cn(
          !user && 'hidden',
          collapsed && 'collapsed',
          layout === 'quiz' && 'status-exam',
          layout === 'machine' && 'machine-detail',
          layout === 'challenge' && 'machine-detail',
          layout === 'step-form' && 'full-width',
          layout === 'exercise' && 'battleground-area',
        )}
      >
        {layout === 'step-form' ? (
          <div className="container">
            <div className="step-top">
              <Link to="/machines" className="back">
                <i className="left arrow icon"></i>{' '}
                <Trans i18nKey="addNewMachine:back_machines"></Trans>
              </Link>
              <div className="logo-area">
                <div className="logo">
                  {theme === 'dark' || theme === 'green' ? (
                    <img src={panelSettings.logo} alt="logo" />
                  ) : (
                    <img src={panelSettings.logo_white} alt="logo" />
                  )}
                </div>
              </div>
            </div>
            <Suspense fallback={<Spinner fullscreen />}>
              {children}
            </Suspense>
          </div>
        ) : (
          <Suspense fallback={null}>{children}</Suspense>
        )}
      </main>
    </>
  );
}

export default Layout;
