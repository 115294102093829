import { useSelector } from 'react-redux';

export default function IsAdmin({ children }) {
  const { user } = useSelector((state) => state.auth);
  if (user.role === 'system_admin') {
    return children;
  }
  return null;
}

export const IsUser = ({ children }) => {
  const { user } = useSelector((state) => state.auth);
  if (user.role === 'user') {
    return children;
  }
  return null;
};
