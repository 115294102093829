import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Form, Image } from 'semantic-ui-react';
import { Button } from '../components/Button';
import cn from 'classnames';
import styles from '../styles/pages/login.module.scss';
import { inviteComplete } from '../actions/user';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

function Invitation() {
  const width = useRef(0);
  const counter = useRef(0);
  const [, setRefresh] = useState(0);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password_confirmation, setPasswordConfirm] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(
    false,
  );

  const query = new URLSearchParams(useLocation().search);

  useEffect(() => {
    const interval = setInterval(() => {
      width.current += 1;
      setRefresh(width.current);
      if (width.current === 100) {
        width.current = 1;
        counter.current = (counter.current + 1) % 3;
      }
    }, 60);
    return () => clearInterval(interval);
  }, []);

  const submit = (e) => {
    e.preventDefault();

    inviteComplete(
      username,
      password,
      password_confirmation,
      query.get('invitation_token'),
      setLoading,
    )
      .then((res) => {
        res.success.map((message) => toast.success(message));
        setLoading(false);
        history.push('/auth/signin');
      })
      .catch((err) => {
        setLoading(false);
        err.errors.map((message) => toast.error(message));
      });
  };

  return (
    <>
      <div className={cn(styles['loginPage'])}>
        <div className={cn(styles['left-area'])}>
          <div className={cn(styles['text-area'])}>
            <div className={cn(styles['logo-area'])}>
              <Image src="/img/logo-white.svg"></Image>
            </div>
            <h1>Your Opening Door to the Cyber World</h1>
            <div className={cn(styles['progress-area'])}>
              <div className={cn(styles['progress-bar'])}>
                <div
                  className={cn(styles['active'])}
                  style={{ width: width.current + '%' }}
                ></div>
              </div>
              <div className={cn(styles['progress-text'])}>
                0<span>{counter.current + 1}</span> - 03
              </div>
            </div>
          </div>
          <div className={cn(styles['img-area'])}>
            <Image
              src="/img/login/8.png"
              alt="Cyber Range Platform | Cyber Range Portal"
              className={cn(
                'img-1',
                styles[
                  ('img', counter.current === 0 ? 'active' : '')
                ],
              )}
            />
            <Image
              src="/img/login/9.png"
              alt="Cyber Range Platform | Cyber Range Portal"
              className={cn(
                'img-2',
                styles[
                  ('img', counter.current === 1 ? 'active' : '')
                ],
              )}
            />
            <Image
              src="/img/login/10.png"
              alt="Cyber Range Platform | Cyber Range Portal"
              className={cn(
                'img-3',
                styles[
                  ('img', counter.current === 2 ? 'active' : '')
                ],
              )}
            />
          </div>
        </div>
        <div className={cn(styles['right-area'])}>
          <div className={cn(styles['logo-area'])}>
            <Image src="/img/logo.svg"></Image>
            <p>Cyber Range Platform</p>
          </div>
          <div className={cn(styles['content-area'])}>
            <h2>Enter password</h2>
            <Form
              className={cn(styles.form)}
              autoComplete="off"
              onSubmit={submit}
            >
              <Form.Field>
                <Form.Input
                  icon="lock"
                  iconPosition="left"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                ></Form.Input>
              </Form.Field>
              <Form.Field>
                <Form.Input
                  icon="lock"
                  iconPosition="left"
                  placeholder="Password"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                ></Form.Input>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    setShowPassword(!showPassword);
                  }}
                >
                  <i
                    className={cn(
                      showPassword ? 'eye icon' : 'eye slash icon',
                      styles.eyeIcon,
                    )}
                  ></i>
                </a>
              </Form.Field>
              <Form.Field>
                <Form.Input
                  icon="lock"
                  iconPosition="left"
                  placeholder="Password Confirm"
                  value={password_confirmation}
                  type={showConfirmPassword ? 'text' : 'password'}
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                ></Form.Input>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    setShowConfirmPassword(!showConfirmPassword);
                  }}
                >
                  <i
                    className={cn(
                      showConfirmPassword
                        ? 'eye icon'
                        : 'eye slash icon',
                      styles.eyeIcon,
                    )}
                  ></i>
                </a>
              </Form.Field>
              <Button loading={loading} type="submit">
                Send
              </Button>
            </Form>
            <div
              className={cn('divider-1', styles['divider-1'])}
            ></div>
            <div className={cn(styles['sub-link'])}>
              <Link to="/auth/signup">Sign up for new user</Link>
              <Link to="/auth/signin">Already have an account?</Link>
            </div>
            {/* <div class={cn(styles['right-footer'])}>
              <a
                href="https://priviahub.com/privacy_policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              <a
                href="https://priviahub.com/privacy_policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Use
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Invitation;
