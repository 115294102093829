import React from 'react';
import cn from 'classnames';
import styles from './message.module.scss';
import { Link } from 'react-router-dom';

const Message = ({
  content = '',
  date = '',
  username = '',
  color = '',
  self,
}) => {
  return (
    <div className={cn(styles.message, self && styles.self)}>
      <div className={styles.info}>
        {!self && (
          <div className={styles.username}>
            <Link
              style={{ color: `#${color}` }}
              to={`/profile/${username}`}
            >
              {username}
            </Link>
          </div>
        )}
        <div className={styles.date}>{date}</div>
      </div>
      <div className={styles.content}>{content}</div>
    </div>
  );
};

export default Message;
