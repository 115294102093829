import * as React from 'react';

function SvgSecurityalternate(props) {
  return (
    <svg
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.792 4.367a2.196 2.196 0 012.43 0c.605.414 1.49.765 2.45 1.04.943.273 1.888.455 2.563.566.976.159 1.808.998 1.763 2.104-.056 1.419-.302 4.15-1.355 6.636-1.061 2.504-3.022 4.917-6.538 5.277l-.098.01-.097-.01c-4.181-.429-6.149-3.649-7.055-6.525-.908-2.887-.86-5.724-.852-6.032v-.205a.953.953 0 111.906 0v.179l-.001.074c-.006.244-.047 2.828.765 5.412.807 2.563 2.347 4.839 5.334 5.191 2.503-.296 3.98-1.99 4.881-4.115.919-2.17 1.153-4.629 1.207-5.968a.11.11 0 00-.029-.076.235.235 0 00-.14-.071 24.78 24.78 0 01-2.782-.615c-1.026-.296-2.143-.713-2.999-1.3-.065-.044-.211-.044-.275 0-.879.602-2.055 1.038-3.162 1.347-1.015.283-2.018-.407-2.217-1.44 1.433-.276 3.251-.759 4.301-1.479z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgSecurityalternate;
