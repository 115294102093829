import * as React from 'react';

function SvgFlagAlternate(props) {
  return (
    <svg
      viewBox="0 0 20 24"
      width="1em"
      height="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.468 9.902v2.162c.829.188 1.566.496 2.337.708v-2.165c-.825-.184-1.569-.492-2.337-.705zm7.09-3.906c-1.089.505-2.429 1.013-3.715 1.013-1.698 0-3.105-1.105-5.245-1.105-.794 0-1.502.14-2.159.381.089-.232.13-.482.114-.749a1.787 1.787 0 00-1.702-1.664 1.779 1.779 0 00-1.089 3.236v12.259c0 .423.34.762.762.762h.508a.76.76 0 00.762-.762V16.37c.899-.384 2.02-.702 3.633-.702 1.701 0 3.105 1.105 5.245 1.105 1.531 0 2.753-.517 3.89-1.299.276-.19.438-.501.438-.838V6.917a1.015 1.015 0 00-1.442-.921zm-9.427 8.212a9.95 9.95 0 00-2.337.527v-2.239c.832-.295 1.508-.476 2.337-.552v2.264zm9.348-4.271c-.75.311-1.47.619-2.337.759v2.257c.787-.108 1.632-.374 2.337-.825v2.238c-.797.512-1.54.785-2.337.861v-2.274c-.857.118-1.521.048-2.337-.178v2.141c-.759-.235-1.502-.531-2.337-.676v-2.176c-.625-.139-1.295-.216-2.337-.12V9.721c-.711.098-1.416.324-2.337.664V8.146c1.055-.387 1.591-.628 2.337-.698v2.273c.857-.117 1.537-.042 2.337.181v-2.14c.753.235 1.499.53 2.337.676v2.172a6.062 6.062 0 002.337.086V8.413c.857-.153 1.661-.432 2.337-.714v2.238z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgFlagAlternate;
