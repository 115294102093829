import * as React from 'react';

function SvgUserAlternate(props) {
  return (
    <svg
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        d="M15.71 14.006l.854-2.401c.143-.403-.132-.832-.536-.832h-2.089a5.136 5.136 0 00.636-2.455v-.011c1.4-.3 2.285-.733 2.285-1.216 0-.51-.975-.963-2.503-1.266-.329-1.258-.964-2.524-1.45-3.176-.339-.456-.925-.598-1.411-.337l-.986.529a1.07 1.07 0 01-1.021 0l-.986-.529c-.485-.261-1.071-.119-1.41.337-.483.652-1.122 1.918-1.45 3.176-1.525.303-2.5.756-2.5 1.266 0 .483.885.916 2.285 1.216v.011c0 .898.243 1.73.636 2.455H4.01c-.41 0-.685.448-.525.855l.922 2.309C2.975 14.83 2 16.476 2 18.382V20.1c0 .948.718 1.718 1.6 1.718h12.8c.882 0 1.6-.77 1.6-1.718v-1.718c0-1.856-.922-3.467-2.29-4.376zm-7.425 6.585L6.8 13.227l1.771 1.228.857 1.534-1.143 4.602zm3.429 0l-1.143-4.602.857-1.534 1.772-1.228-1.486 7.364zm1.489-11.448c-.139.456-.25.943-.589 1.281-.361.356-1.714.859-2.286-.959a.341.341 0 00-.653 0c-.607 1.925-2 1.242-2.286.959-.339-.338-.454-.825-.589-1.281-.029-.096-.225-.219-.225-.223v-.414c1.01.138 2.178.222 3.428.222s2.418-.08 3.429-.222v.414c-.004.004-.2.123-.229.223z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgUserAlternate;
