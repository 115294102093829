import React from 'react';
import { Modal as SemanticModal } from 'semantic-ui-react';
import TagLabel from '../TagLabel';
import { Button } from '../Button';
import { useTranslation } from 'react-i18next';
import './Modal.scss';

function DefaultModal({
  title,
  tag,
  desc,
  button,
  closeButton = true,
  children,
  ...props
}) {
  const { t } = useTranslation('global');
  return (
    <SemanticModal {...props}>
      {title && (
        <div className="modal-title-area">
          <div className="modal-name">{title}</div>
          {tag && (
            <TagLabel className="modal-root-page" color="primary">
              {tag}
            </TagLabel>
          )}
        </div>
      )}
      {desc && <span className="modal-desc">{desc}</span>}
      {(title || desc) && <div className="modal-divider"></div>}
      <div className="modal-content-area">{children}</div>
      {closeButton && (
        <div className="actions hub-action">
          <Button
            is="div"
            color="privia-grey"
            onClick={() => props.onClose()}
          >
            {t('modal.close_button')}
          </Button>
          {button}
        </div>
      )}
    </SemanticModal>
  );
}
export default React.memo(DefaultModal);
