import * as React from 'react';

function SvgCategory(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.548 5.08a.468.468 0 00-.467.468v3.927c0 .258.209.467.467.467h3.927a.468.468 0 00.468-.467V5.548a.469.469 0 00-.468-.468H3.548zm-1.403.468c0-.776.628-1.403 1.403-1.403h3.927c.776 0 1.403.627 1.403 1.403v3.927c0 .775-.627 1.403-1.403 1.403H3.548a1.403 1.403 0 01-1.403-1.403V5.548zM3.548 14.057a.468.468 0 00-.467.468v3.927c0 .259.209.467.467.467h3.927a.467.467 0 00.468-.467V14.525a.469.469 0 00-.468-.468H3.548zm-1.403.468c0-.776.628-1.403 1.403-1.403h3.927c.776 0 1.403.627 1.403 1.403v3.927c0 .775-.627 1.403-1.403 1.403H3.548a1.403 1.403 0 01-1.403-1.403v-3.927zM12.525 5.08a.468.468 0 00-.467.468v3.927c0 .258.209.467.467.467h3.927a.467.467 0 00.468-.467V5.548a.468.468 0 00-.468-.468h-3.927zm-1.403.468c0-.776.629-1.403 1.403-1.403h3.927c.776 0 1.403.627 1.403 1.403v3.927c0 .775-.627 1.403-1.403 1.403h-3.927a1.403 1.403 0 01-1.403-1.403V5.548zM12.525 14.057a.468.468 0 00-.467.468v3.927c0 .259.209.467.467.467h3.927a.466.466 0 00.468-.467V14.525a.468.468 0 00-.468-.468h-3.927zm-1.403.468c0-.776.629-1.403 1.403-1.403h3.927c.776 0 1.403.627 1.403 1.403v3.927c0 .775-.627 1.403-1.403 1.403h-3.927a1.403 1.403 0 01-1.403-1.403v-3.927z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCategory;
