import * as React from 'react';

function SvgPieAlternate(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.958 10.112a8.605 8.605 0 00-6.07-6.071c-.691-.181-1.345.255-1.527.909l-1.927 7.053a1.278 1.278 0 001.563 1.563l5.09-1.382v.364a5.63 5.63 0 01-5.889 5.634c-2.872-.145-5.235-2.508-5.38-5.38a5.66 5.66 0 014.107-5.671 2.104 2.104 0 001.527-2.035C5.344 5.096 2 8.44 2 12.548 2 16.656 5.344 20 9.452 20c4.108 0 7.452-3.344 7.452-7.452 0-.291 0-.582-.036-.873l.218-.072a1.21 1.21 0 00.872-1.491zm-7.524 1.454l1.526-5.598a6.833 6.833 0 014.072 4.071l-5.598 1.527z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgPieAlternate;
