const INITIAL_STATE = {
  loading: true,
  tactic: {
    name_en: '',
    name_tr: '',
    description_en: '',
    description_tr: '',
  },
  technique: {
    name_en: '',
    name_tr: '',
    description_en: '',
    description_tr: '',
    tactic_ids: [],
  },
  sub_technique: {
    name_en: '',
    name_tr: '',
    description_en: '',
    description_tr: '',
    technique_id: '',
  },
};

const mitre = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_TACTIC':
      return {
        ...state,
        tactic: { ...state.tactic, ...action.payload },
        loading: false,
      };
    case 'SET_TECHNIQUE':
      return {
        ...state,
        technique: { ...state.technique, ...action.payload },
        loading: false,
      };
    case 'SET_SUB_TECHNIQUE':
      return {
        ...state,
        sub_technique: { ...state.sub_technique, ...action.payload },
        loading: false,
      };
    case 'FETCH_MITRE':
      return { ...state, loading: true };
    case 'RESET_MITRE_STATE':
      return {
        tactic: {
          name_en: '',
          name_tr: '',
          description_en: '',
          description_tr: '',
        },
        technique: {
          name_en: '',
          name_tr: '',
          description_en: '',
          description_tr: '',
          tactic_ids: [],
        },
        sub_technique: {
          name_en: '',
          name_tr: '',
          description_en: '',
          description_tr: '',
          technique_id: '',
        },
      };
    default:
      return state;
  }
};

export default mitre;
