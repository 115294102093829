import * as React from 'react';

function SvgMachine(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.727 16.632h1.515v1.684h1.516v-1.684h1.516v1.684h.758c.418 0 .758.377.758.842 0 .465-.34.842-.758.842H6.969c-.419 0-.758-.377-.758-.842 0-.465.339-.842.758-.842h.758v-1.684z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.6 7.158v6.316a1.59 1.59 0 001.6 1.579h9.6c.884 0 1.6-.707 1.6-1.579V7.158a1.59 1.59 0 00-1.6-1.579H5.2c-.883 0-1.6.707-1.6 1.579zM2 13.474c0 1.744 1.433 3.158 3.2 3.158h9.6c1.767 0 3.2-1.414 3.2-3.158V7.158C18 5.414 16.567 4 14.8 4H5.2C3.433 4 2 5.414 2 7.158v6.316z"
        fill="currentColor"
      />
      <path
        d="M6.117 7.369c-.413 0-.748.377-.748.842 0 .465.335.842.748.842h5.24c.413 0 .748-.377.748-.842 0-.465-.335-.842-.748-.842h-5.24zM6.211 10.737a.842.842 0 000 1.685h1.684a.842.842 0 000-1.685H6.211z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgMachine;
