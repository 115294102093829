import * as React from 'react';

function SvgDot(props) {
  return (
    <svg
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      {...props}
    >
      <circle cx={10} cy={12} r={8} fill="currentColor" />
    </svg>
  );
}

export default SvgDot;
