import React from 'react';
import './TagLabel.scss';

import { Icon } from 'semantic-ui-react';

function TagLabel({
  color = 'color-3',
  className = '',
  icon = '',
  desc = '',
  children,
}) {
  return (
    <div className={`${className} tag-label ${color}`}>
      {icon && <Icon name={icon} />}
      {children}
      {desc && <span className="desc">{desc}</span>}
    </div>
  );
}

export function UserPlaceholder({
  noImage = false,
  color = 'color-3',
  className = '',
  children,
}) {
  return (
    <div
      className={`user-placeholder ${
        noImage ? color : ''
      } ${className}`}
    >
      {noImage ? <div className="no-img">{children}</div> : children}
    </div>
  );
}

export default React.memo(TagLabel);
