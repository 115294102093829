import { nanoid } from 'nanoid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import * as SvgIcon from '../../../icons';
import Modal from '../../../Modal';
import ProgressBar from '../../../ProgressBar';
import styles from './onboardingArea.module.scss';

const MainModal = ({
  titles,
  data,
  isOpen,
  setOpen,
  setActiveModal,
}) => {
  const panelSettings = useSelector((state) => state.panelSettings);
  const { t } = useTranslation('study');

  return (
    <Modal
      size="tiny"
      className="right-modal"
      open={isOpen}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={
        <div className={styles['onboarding-area']}>
          <SvgIcon.EducationAlternate style={{fontSize: "38px"}}/>
        </div>
      }
      closeButton={false}
    >
      <div className="cancel" onClick={() => setOpen(false)}>
        <Icon name="times" />
      </div>

      <div className={styles['top-area']}>
        <img src="/img/onboarding/graduation.svg" alt="graduation" />
        <span className={styles.title}>{t('study_title')}</span>
        <span className={styles.desc}>{t('study_title2')}</span>
      </div>
      <div className={styles['list-area']}>
        {titles.map((title, index) => (
          <div
            key={nanoid()}
            onClick={() => {
              setActiveModal(index);
              setOpen(false);
            }}
            className={styles['list-item']}
          >
            <div className={styles['img-area']}>
              <img alt={title.name} src={title.image} />
            </div>
            <div className={styles['content']}>
              <div className={styles['item-name']}>{title.name}</div>
              <div className={styles['item-desc']}>{title.desc}</div>
              <div className={styles['progress-area']}>
                <ProgressBar
                  size="tiny"
                  percent={data[title.key].complation_rate}
                  indicating={false}
                  colorized
                />
              </div>
              <div className={styles['progress-item']}>
                <span>{data[title.key].success_count}</span>/
                {data[title.key].tab_count} {t('study_complate')}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className={styles['copyright-area']}>
        <a
          href={panelSettings.web_site}
          target="_blank"
          rel="noopener noreferrer"
        >
          © {panelSettings.copright}
        </a>
      </div>
    </Modal>
  );
};

export default MainModal;
